import { useAppContext } from '../components/AppContext'
import { cloneDeep } from 'lodash'
import {
	siteConfigurationUserPreviewData,
	commonPreviewData,
	documentTypePreviewData,
} from '../services/templatePreviewData'

const useHTMLTemplatePreview = () => {
	const { state } = useAppContext()
	const { siteConfig } = state

	const getPreviewData = (template) => {
		const templateTypeData = documentTypePreviewData[template.type]
		let previewSiteConfig = {
			...cloneDeep(siteConfig),
			...siteConfigurationUserPreviewData,
		}

		const pascalCaseSiteConfig = {}
		Object.keys(previewSiteConfig).reduce((result, key) => {
			const capitalizedKey =
				key.charAt(0).toUpperCase() + key.slice(1)
			pascalCaseSiteConfig[capitalizedKey] = siteConfig[key]
		})

		return {
			// resolve site config case sensitivity issues with legacy templates by passing the pascal case site config and camelCase site config
			...previewSiteConfig,
			...pascalCaseSiteConfig,
			...templateTypeData,
			...commonPreviewData,
		}
	}

	return {
		getPreviewData,
	}
}

export default useHTMLTemplatePreview
