import React from 'react'
import * as yup from 'yup'
import useForm from '../../../../hooks/useForm'
import { Button, LoadingBtn } from '../../../../components/Button'
import {
	TextField,
	Form,
	FormRow,
} from '../../../../components/formControls'

export const DeleteMeForm = ({ onSubmit, onClose }) => {
	const schema = yup.object().shape({
		feedback: yup.string().required('Feedback is required'),
	})

	const form = useForm({
		schema,
		resetOptions: {
			keepValues: false,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		control,
		getValues,
		reset,
	} = form

	const submitForm = async () => {
		await onSubmit(getValues())
		handleCancel()
	}

	const handleCancel = () => {
		onClose()
		reset({ feedback: '' })
	}

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="w-full">
			<FormRow className="mb-5">
				<TextField
					name="feedback"
					label="Feedback"
					className="w-full"
					placeholder="Provide the reason for requesting your account deletion"
					multiline
					rows={3}
					control={control}
					errors={errors}
					required
				/>
			</FormRow>
			<div className="mt-5 flex justify-between space-x-4">
				<Button
					text="Cancel"
					onClick={handleCancel}
					className="flex-grow w-full"
					variant="outlined"
				/>
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					color="error"
					type="submit"
					text="Delete"
				/>
			</div>
		</Form>
	)
}
