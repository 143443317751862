import React from 'react'
import { IconButton } from '@mui/material'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { useTheme } from '@mui/material/styles'

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

const RefreshButton = (props) => {
	const themeMUI = useTheme()
	const REFRESH = 'Refresh'

	return (
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label={REFRESH}
			{...props}
			sx={bgColor}
		>
			<img
				className="w-14 mt-1"
				src={
					themeMUI.palette.mode === 'light'
						? RefreshPNG
						: RefreshDarkPNG
				}
				onMouseOver={(e) =>
					(e.currentTarget.src =
						themeMUI.palette.mode === 'light'
							? RefreshGIF
							: RefreshDarkGIF)
				}
				onMouseOut={(e) =>
					(e.currentTarget.src =
						themeMUI.palette.mode === 'light'
							? RefreshPNG
							: RefreshDarkPNG)
				}
				alt={REFRESH}
			/>
		</IconButton>
	)
}

export default RefreshButton
