import React from 'react'
import ExistingTaskForm from '../../../../forms/ExistingTaskForm'
import Dialog from '../../../../components/modals/Dialog'

const ModalExistingTaskForm = ({
	onSubmit,
	onClose,
	excludedIds = [],
	...rest
}) => {
	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			title="Add Task"
			onClose={onClose}
			{...rest}
		>
			<ExistingTaskForm
				excludedIds={excludedIds}
				onSubmit={onSubmit}
				onCancel={onClose}
			/>
		</Dialog>
	)
}

export default ModalExistingTaskForm
