import React, { useMemo } from 'react'
import TextField from './TextField'
import { IconButton, InputAdornment } from '@mui/material'
import { merge } from 'lodash'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const PasswordField = ({ InputProps = undefined, ...rest }) => {
	const [showPassword, setShowPassword] = React.useState(false)

	const onTogglePasswordClick = () => {
		setShowPassword(!showPassword)
	}

	const mergedInputProps = useMemo(() => {
		const pwInputProps = {
			endAdornment: (
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={onTogglePasswordClick}
					>
						{showPassword ? (
							<VisibilityIcon />
						) : (
							<VisibilityOffIcon />
						)}
					</IconButton>
				</InputAdornment>
			),
		}

		return InputProps ? merge(InputProps, pwInputProps) : pwInputProps
	}, [showPassword, InputProps, onTogglePasswordClick])

	return (
		<TextField
			{...rest}
			type={showPassword ? 'text' : 'password'}
			InputProps={mergedInputProps}
		/>
	)
}

export default PasswordField
