import React from 'react'
import LoanDetailsContent from './LoanDetailsContent'
import { LoanSignalRContextProvider } from './contexts/LoanContext'

const LoanDetails = () => {
	return (
		<LoanSignalRContextProvider>
			{/* @ts-ignore */}
			<LoanDetailsContent />
		</LoanSignalRContextProvider>
	)
}

export default LoanDetails
