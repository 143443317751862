import React from 'react'
import {
	CheckCircle as CheckCircleIcon,
	Drafts as DraftsIcon,
} from '@mui/icons-material'

const StatusIcon = ({ status }) => {
	return (
		<div className="font-bold mr-5">
			{status === 'Published' ? (
				<CheckCircleIcon color="success" />
			) : (
				<DraftsIcon color="warning" />
			)}{' '}
			{status}
		</div>
	)
}

export default StatusIcon
