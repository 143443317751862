import React, { useMemo } from 'react'
import ErrorList from '../../../../components/ErrorList'
import Form from '../../../../components/formControls/Form'
import { TextField } from '../../../../components/formControls'
import { LoadingBtn } from '../../../../components/Button'
import * as yup from 'yup'
import { getTheme } from '../../../../config'
import useForm from '../../../../hooks/useForm'
import { FieldValues } from 'react-hook-form'
import { Dialog } from '@mui/material'

const theme = getTheme()

// type ImportLoanModalProps = {
// 	className?: string | undefined | null
// 	onSubmit: (field: FieldValues) => void
//     apiErrors: any[]
// 	loanId?: string
// }

const ImportLoanForm = (
	{ onSubmit, apiErrors = [] } /*: ImportLoanModalProps */
) => {
	const defaultValues = {
		loanId: '',
	}

	const schema = yup.object().shape({
		loanId: yup.mixed().guid().required('Loan ID is required'),
	})

	const form = useForm({
		schema,
		defaultValues,
	})

	const {
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting },
		control,
	} = form

	const onFormSubmit = async () => {
		const data = getValues()
		await onSubmit(data)
	}

	return (
		<Form onSubmit={handleSubmit(onFormSubmit)} className="w-full">
			<ErrorList errors={apiErrors} className="mb-3" title="" />
			<TextField
				name="loanId"
				label="Loan ID"
				fullWidth
				control={control}
				errors={errors}
			/>
			<div className="w-full mt-6">
				<LoadingBtn
					type="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					text="Import"
				/>
			</div>
		</Form>
	)
}

export default ImportLoanForm
