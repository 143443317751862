import React from 'react'
import {
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
} from '@mui/material'
import InheritedTextField from '../formControls/InheritedTextField'

export const Integrations = ({
	formData,
	setFormData,
	entityType,
	inheritedSiteConfiguration,
	width,
	isTopLevelSite = false,
}) => {
	const updatePOSSiteConfiguration = (event) => {
		const { name: field, value } = event.target

		setFormData((profileFormData) => ({
			...profileFormData,
			[field]: value,
		}))
	}

	const handleCheckboxChange = (id) => {
		setFormData((formData) => ({ ...formData, [id]: null }))
	}

	return (
		<div className="flex flex-col">
			<p className="mt-5 mb-2.5 text-sm font-rubik">Integrations</p>
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				{entityType !== 0 ? (
					<>
						<div
							className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
						>
							<TextField
								onChange={(e) =>
									setFormData((current) => ({
										...current,
										losUserID: e.target.value,
									}))
								}
								value={formData?.losUserID}
								label="LOS User ID"
								placeholder="LOS User ID"
								id={`IntegrationsLOSUserIDInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
							/>
							<TextField
								onChange={(e) =>
									setFormData((current) => ({
										...current,
										eppsUserName: e.target.value,
									}))
								}
								value={formData?.eppsUserName}
								label="EPPS Username"
								placeholder="EPPS Username"
								id={`IntegrationsEPPSUserNameInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
						{entityType === 1 && (
							<div
								className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
							>
								<FormControl
									key={'IntegrationsLoanChannelPicker'}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
								>
									<InputLabel>Loan Channel</InputLabel>
									<Select
										value={formData?.loanChannel}
										onChange={(e) =>
											setFormData((current) => ({
												...current,
												loanChannel: e.target.value,
											}))
										}
										label="Loan Channel"
									>
										<MenuItem value="">
											Choose a Loan Channel
										</MenuItem>
										<MenuItem value="Correspondent">
											Correspondent
										</MenuItem>
										<MenuItem value="Banked - Wholesale">
											Banked - Wholesale
										</MenuItem>
										<MenuItem value="Brokered">Brokered</MenuItem>
										<MenuItem value="Banked - Retail">
											Banked - Retail
										</MenuItem>
									</Select>
								</FormControl>
								<TextField
									onChange={(e) =>
										setFormData((current) => ({
											...current,
											loanFolder: e.target.value,
										}))
									}
									value={formData?.loanFolder}
									label="Loan Folder"
									placeholder="Loan Folder"
									id={`IntegrationsLoanFolderInput`}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
								/>
								<TextField
									onChange={(e) =>
										setFormData((current) => ({
											...current,
											loanTemplate: e.target.value,
										}))
									}
									value={formData?.loanTemplate}
									label="Loan Template"
									placeholder="Loan Template"
									id={`IntegrationsLoanTemplateInput`}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2 }}
								/>
							</div>
						)}
					</>
				) : (
					<div className="w-full items-end">
						<TextField
							onChange={(e) =>
								setFormData((current) => ({
									...current,
									servicerLenderId: e.target.value,
								}))
							}
							value={formData?.servicerLenderId}
							label="Finigree Lender ID"
							placeholder="Finigree Lender ID"
							id={`IntegrationsFinigreeLenderIDInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 3 }}
						/>
					</div>
				)}
				{entityType === 1 && (
					<>
						<div
							className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
						>
							<InheritedTextField
								id="IntegrationsDisclosuresSSOSiteIDInput"
								placeholder="Disclosures SSO Site ID"
								label="Disclosures SSO Site ID"
								onChange={updatePOSSiteConfiguration}
								onCheckboxChange={() =>
									handleCheckboxChange('disclosuresSSOSiteID')
								}
								showLabel={false}
								name="disclosuresSSOSiteID"
								variant="standard"
								className="w-full sm:px-12"
								value={formData?.disclosuresSSOSiteID || ''}
								inheritedValue={
									inheritedSiteConfiguration?.disclosuresSSOSiteID ||
									''
								}
								sx={{ mb: 2, mr: 0 }}
								isTopLevelSite={isTopLevelSite}
							/>
						</div>
					</>
				)}
			</div>
			<div className="flex w-full md:w-1/2 md:mb-0 md:max-w-screen-sm"></div>
		</div>
	)
}
