import React from 'react'
import Page from '../../components/Page'
import backgroundImage from 'assets/images/loanAppBG.jpg'
import Heading from '../../components/Heading'

const SiteNotFound = () => {
	return (
		<div
			className="bg-cover min-h-screen"
			style={{ backgroundImage: `url(${backgroundImage})` }}
		>
			<Page page="app-landing" title="Page not found" noDarkMode>
				<section className="py-6 bg-slate-100 relative top-[6rem] lg:rounded-3xl md:py-12 px-2 md:px-12 mb-12 dark:bg-slate-800 md:rounded-none">
					<Heading size="2xl">Site not found</Heading>
					<p className="dark:text-slate-200">
						Sorry, the site you requested was not found.
					</p>
				</section>
			</Page>
		</div>
	)
}

export default SiteNotFound
