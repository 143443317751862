import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import { useAppContext } from '../../../components/AppContext'
import { Button } from '../../../components/Button'
import { PricingTable } from '../../../components/pricing/PricingTable'
import { HeaderOpen } from '../../../components/HeaderOpen'
import { Footer } from '../../../components/Footer'
import Page from '../../../components/Page'
import { navigationLinking } from '../../../services/navigation'
import { getTheme } from '../../../config'

const theme = getTheme()

export default function PricingResults() {
	const { state } = useAppContext()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const rates = ventiState.get(theme.storageKeys.publicRates) || []

	const { siteConfig, user, socialLinks, legalLinks } = state

	const [tableState, tableSetState] = useState({
		tableHeader: '',
		marginTop: 12.5,
	})

	useEffect(() => {
		if (siteConfig !== null) {
			if (siteConfig.entityType === 0)
				navigate(`/${navigationLinking.Portal}`)
		}
	}, [siteConfig])

	return (
		<Page page="app-pricing-results">
			<HeaderOpen siteConfig={siteConfig} user={user} />

			<div className="flex flex-col items-center justify-center pl-5 pr-5 pb-10">
				<Button
					text="Price Another Loan"
					variant="outlined"
					onClick={() => {
						navigate(`/${navigationLinking.Pricing}`)
					}}
					bstyle={{ width: 280 }}
				/>
				<PricingTable rates={rates} tableState={tableState} />
			</div>

			<Footer
				siteConfig={siteConfig}
				socialLinks={socialLinks}
				legalLinks={legalLinks}
			/>
		</Page>
	)
}
