import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
} from '@mui/material'
import NewVersionForm from '../../forms/NewVersionForm'
import VersionList from '../versions/VersionList'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'

const ModalVersionList = ({
	open,
	versions,
	onDialogClose,
	...rest
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog
			open={open}
			onClose={onDialogClose}
			fullScreen={fullScreen}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle className="flex flex-row items-center justify-between">
				Versions
				{onDialogClose && (
					<IconButton onClick={onDialogClose}>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<Divider />
			<DialogContent>
				<VersionList versions={versions} {...rest} />
			</DialogContent>
		</Dialog>
	)
}

export default ModalVersionList
