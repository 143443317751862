import { Divider } from '@mui/material'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getTheme } from '../../config'
import { ModalByPhone } from '../modals/ModalByPhone'
import { useNavigate } from 'react-router-dom'
import { isDarkMode } from '../../services/helper'

const siteTheme = getTheme()

export default function CondensedAppLinks({
	links,
	siteConfig,
	variant = 'standard',
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [hoveredId, setHoveredId] = useState(null)
	const [modalByPhoneVisible, setModalByPhoneVisible] =
		useState(false)

	const navigate = useNavigate()

	const handleAppLink = async (appLink) => {
		if (appLink.external) window.open(appLink.link)
		if (appLink.fieldName.toLowerCase() === 'ringcentral') {
			if (fullScreen) setModalByPhoneVisible(true)
			else await window.open(`tel:${siteConfig.phone}`)
		} else {
			navigate(`/${appLink.link}`)
		}
	}

	return (
		<div className="w-full">
			{links.map((item, index) => {
				if (
					siteConfig?.enabledServices[item.fieldName] !== false &&
					item.entityTypes.includes(siteConfig.entityType)
				)
					return (
						<div
							className={`cursor-pointer flex items-center align-center justify-between m-3.5 border ${variant === 'shadow' ? 'rounded-2xl shadow-xl px-6' : 'p-4'}`}
							key={index}
							onMouseOver={(e) => setHoveredId(e.currentTarget.id)}
							onMouseOut={(e) => setHoveredId(null)}
							id={index}
							onClick={() => handleAppLink(item)}
						>
							{!!item.staticIcon && (
								<img
									className="h-12 sm:h-16 mr-2.5 my-1"
									alt={item.id}
									src={
										parseInt(hoveredId?.toString()) === index
											? item.animatedIcon
											: item.staticIcon
									}
								/>
							)}
							{variant === 'standard' && (
								<Divider
									sx={{
										marginRight: 3,
										backgroundColor:
											siteTheme.color.border.bottom_divider,
									}}
									orientation="vertical"
									flexItem
								/>
							)}
							<div className={`w-full`}>
								<p
									style={{
										color:
											variant === 'standard'
												? siteTheme.siteConfig.color.primary
												: siteTheme.siteConfig.color.hover_black,
									}}
									className="sm:text-lg font-bold font-rubik"
								>
									{item.name}
								</p>
								<p
									style={{
										color:
											variant === 'standard' || isDarkMode()
												? siteTheme.siteConfig.color.hover_black
												: siteTheme.siteConfig.color.primary,
									}}
									className="text-sm: sm:text mt-1 text font-rubik"
								>
									{item.description}
								</p>
							</div>
						</div>
					)
			})}
			<ModalByPhone
				siteConfig={siteConfig}
				visible={modalByPhoneVisible}
				setVisible={setModalByPhoneVisible}
			/>
		</div>
	)
}
