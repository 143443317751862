import { v4 as uuidV4 } from 'uuid'

export const alertsActions = {
	add: 'add',
	remove: 'remove',
	clear: 'clear',
}

export const reducer = (state = {}, action) => {
	switch (action.type) {
		case alertsActions.add:
			const id = uuidV4()
			const { text, severity = 'success' } = action.payload

			const alerts = [...state.alerts]
			alerts.unshift({ text, severity, id })

			return {
				...state,
				alerts,
			}
		case alertsActions.remove:
			return {
				...state,
				alerts: state.alerts.filter(
					(alert) => alert.id !== action.payload
				),
			}
		case alertsActions.clear:
			return {
				...state,
				alerts: [],
			}
		default:
			console.info({ action })
			throw new Error('Unknown Action')
	}
}
