import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'

const CorporationExpandedRow = ({ data: corporation }) => {
	const { name, nmlsid, email, url } = corporation
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="NMLSID" value={nmlsid} />
			<RowExpandedProperty label="Email" value={email} />
			<RowExpandedProperty label="URL" value={url} />
		</div>
	)
}

export default CorporationExpandedRow
