import React, { useEffect, useMemo, useState } from 'react'
import { Check } from '@mui/icons-material'
import { Alert, ToggleButtonGroup, ToggleButton } from '@mui/material'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'

import {
	getAccount,
	getMilestoneConfigurations,
	deleteMilestoneConfiguration,
} from '../../services/client'
import DataTable from '../DataTable'
import { useAlert } from '../../hooks'
import queryKeys from '../../services/queryKeys'
import { eventTypes } from '../../services/constants'
import { useMixpanel } from '../../hooks/useMixpanel'
import { getErrorMessage } from '../../services/helper'
import ModalMilestoneConfiguration from '../modals/ModalMilestoneConfiguration'
import { ModalRemoveRestoreRecordConfirm } from '../modals/ModalRemoveRestoreRecordConfirm'

const MilestoneConfigurations = () => {
	const { alert } = useAlert()
	const queryClient = useQueryClient()
	const mixpanel = useMixpanel()
	const [
		selectedMilestoneConfiguration,
		setSelectedMilestoneConfiguration,
	] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [selectedLoanType, setSelectedLoanType] = useState('Purchase')

	const {
		isPending: isAccountResultPending,
		isError,
		data: account,
	} = useQuery({
		queryKey: [queryKeys.account],
		queryFn: getAccount,
	})

	const {
		isPending: isMilestoneConfigurationsResultPending,
		isError: isFetchingMilestoneConfigurationsError,
		data: milestoneConfigurationsResult,
		refetch: refetchMilestones,
	} = useQuery({
		queryKey: [queryKeys.milestoneConfigurations],
		queryFn: getMilestoneConfigurations,
	})

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading milestones', {
				severity: 'error',
			})
		}
	}, [isError])

	useEffect(() => {
		if (isFetchingMilestoneConfigurationsError) {
			alert('There was a problem loading milestone configurations', {
				severity: 'error',
			})
		}
	}, [isFetchingMilestoneConfigurationsError])

	const columns = useMemo(
		() => [
			{
				name: '#',
				selector: (row, index) => index + 1,
				width: '50px',
			},
			{
				name: 'Name',
				selector: (row) => row.name,
			},
			{
				name: 'Field ID',
				selector: (row) => row.fieldID,
			},
			{
				name: 'Weight',
				selector: (row) => row.weight,
			},
			{
				name: 'Notifications',
				selector: (row) => row.notificationsEnabled,
				cell: (row) => (row.notificationsEnabled ? <Check /> : null),
				center: 'true',
				omit: account
					? !account.losSettings.loanMilestoneNotificationsEnabled
					: true,
			},
		],
		[account]
	)

	const filteredMilestoneConfigurations = useMemo(() => {
		if (milestoneConfigurationsResult) {
			return milestoneConfigurationsResult.filter(
				(m) => m.loanType === selectedLoanType
			)
		}
		return []
	}, [milestoneConfigurationsResult, selectedLoanType])

	const removeMilestoneConfigurationMutation = useMutation({
		mutationFn: (id) => deleteMilestoneConfiguration(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.milestoneConfigurations],
			})
			await refetchMilestones()
		},
	})

	const onDeleteConfirm = async () => {
		try {
			const { id, name, notificationsEnabled } =
				selectedMilestoneConfiguration

			await removeMilestoneConfigurationMutation.mutateAsync(id)
			mixpanel.trackEvent(
				eventTypes.MILESTONE_CONFIGURATION_DELETED,
				{
					name,
					id,
					notificationsEnabled,
				}
			)
			setDeleteConfirmOpen(false)
			setSelectedMilestoneConfiguration(null)
			alert(`Milestone Configuration ${name} successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const onModalClose = () => {
		setSelectedMilestoneConfiguration(null)
		setModalOpen(false)
	}

	const onSave = async () => {
		setSelectedMilestoneConfiguration(null)
		setModalOpen(false)
		alert('Milestone configuration saved!')
		await refetchMilestones()
	}

	const handleLoanTypeChange = (e, value) => {
		if (value !== null) {
			setSelectedLoanType(value)
		}
	}

	const onAddClick = () => {
		setModalOpen(true)
	}

	const onEditClick = (row) => {
		setSelectedMilestoneConfiguration(row)
		setModalOpen(true)
	}

	const onDeleteClick = (row) => {
		setSelectedMilestoneConfiguration(row)
		setDeleteConfirmOpen(true)
	}

	return (
		<div>
			<ModalMilestoneConfiguration
				open={modalOpen}
				onClose={onModalClose}
				onSave={onSave}
				milestoneConfiguration={selectedMilestoneConfiguration}
				loanType={selectedLoanType}
				showNotificationsField={
					account
						? account.losSettings.loanMilestoneNotificationsEnabled
						: false
				}
			/>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={deleteConfirmOpen}
				setRemoveModalVisible={setDeleteConfirmOpen}
				remove={onDeleteConfirm}
				row={selectedMilestoneConfiguration}
				loading={removeMilestoneConfigurationMutation.isPending}
			/>

			{filteredMilestoneConfigurations.length >= 10 && (
				<Alert severity="warning" className="mb-3">
					Max (10) configurations reached
				</Alert>
			)}

			<DataTable
				data={filteredMilestoneConfigurations}
				titleActions={
					<ToggleButtonGroup
						color="primary"
						value={selectedLoanType}
						exclusive
						onChange={handleLoanTypeChange}
						aria-label="Loan Purpose"
						className="mb-2"
					>
						<ToggleButton value="Purchase">Purchase</ToggleButton>
						<ToggleButton value="Refinance">Refinance</ToggleButton>
					</ToggleButtonGroup>
				}
				columns={columns}
				progressPending={
					isMilestoneConfigurationsResultPending ||
					isAccountResultPending
				}
				actionItems={[
					{ name: 'Edit', onClick: (e, row) => onEditClick(row) },
					{ name: 'Delete', onClick: (e, row) => onDeleteClick(row) },
				]}
				addNewBtn={{
					text: 'Add',
					onClick: onAddClick,
					disabled: filteredMilestoneConfigurations.length >= 10,
				}}
			/>
		</div>
	)
}

export default MilestoneConfigurations
