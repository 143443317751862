import React, { useState, useEffect } from 'react'
import { LoanDetailsCard } from './LoanDetailsCard'

const title = 'Personal Information'
const fields = [
	{ key: 'BorrowerFirstName', title: 'First Name', value: null },
	{ key: 'BorrowerLastName', title: 'Last Name', value: null },
	{
		key: 'BorrowerMiddleInitial',
		title: 'Middle Initial',
		value: null,
	},
	{ key: 'BorrowerSuffix', title: 'Suffix', value: null },
	{ key: 'BorrowerContactPhone', title: 'Mobile Phone', value: null },
	{ key: 'BorrowerDOB', title: 'Date of Birth', value: null },
	{
		key: 'BorrowerMaritalStatus',
		title: 'Marital Status',
		value: null,
	},
	// { key: 'BorrowerSSN', title: 'Social Security Number', value: null },
	{
		key: 'BorrowerNumDependents',
		title: 'Number of Dependents',
		value: null,
	},
	{
		key: 'BorrowerHomeAddressStreet',
		title: 'Street Address',
		value: null,
	},
	{
		key: 'BorrowerHomeAddressUnitType',
		title: 'Unit Type',
		value: null,
	},
	{
		key: 'BorrowerHomeAddressUnitNumber',
		title: 'Unit / Apt #',
		value: null,
	},
	{ key: 'BorrowerHomeAddressCity', title: 'City', value: null },
	{ key: 'BorrowerHomeAddressState', title: 'State', value: null },
	{ key: 'BorrowerHomeAddressZip', title: 'Zip', value: null },
	{
		key: 'BorrowerHomeOwnership',
		title: 'Own Current Home',
		value: null,
	},
	{
		key: 'BorrowerHomeAddressTerm',
		title: 'How long have you lived here?',
		value: null,
	},
]
export const PersonalInfo = ({ data, loading }) => {
	const [personalInfo, setPersonalInfo] = useState([])

	useEffect(() => {
		setPersonalInfo(
			fields.map((p) => {
				if (p.key === 'BorrowerHomeAddressTerm') {
					let year = data[p.key]
					let month = data.BorrowerMonthsAtHomeAddress
					return {
						key: 'BorrowerHomeAddressTerm',
						title: 'How long have you lived here?',
						value: `${year ? year : 0} years, ${month ? month : 0} months`,
					}
				} else if (p.key === 'BorrowerNumDependents') {
					p.value = data[p.key] || '0'
					return p
				}
				p.value = data[p.key]
				return p
			})
		)
	}, [data])

	return (
		<>
			<LoanDetailsCard
				title={title}
				data={personalInfo}
				loading={loading}
				style={{ marginBottom: 0 }}
			/>
		</>
	)
}
