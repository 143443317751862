import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
} from '@mui/material'
import * as yup from 'yup'
import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMutation } from '@tanstack/react-query'
import DialogActions from '@mui/material/DialogActions'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
	createMilestoneConfiguration,
	updateMilestoneConfiguration,
} from '../../services/client'
import { useAlert } from '../../hooks'
import { LoadingBtn } from '../Button'
import useForm from '../../hooks/useForm'
import { Checkbox, TextField } from '../formControls'
import { eventTypes } from '../../services/constants'
import { useMixpanel } from '../../hooks/useMixpanel'
import { getErrorMessage } from '../../services/helper'

const ModalMilestoneConfiguration = ({
	open,
	onClose,
	onSave,
	loanType,
	milestoneConfiguration = null,
	showNotificationsField = false,
}) => {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const schema = yup.object().shape({
		name: yup.string().required('Name is required'),
		fieldID: yup.string().required('Field ID is required'),
	})

	const {
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
		control,
		setValue,
		getValues,
	} = useForm({
		schema,
		defaultValues: {
			name: '',
			description: '',
			fieldID: '',
			weight: null,
			notificationsEnabled: false,
		},
	})

	useEffect(() => {
		if (milestoneConfiguration) {
			const {
				name,
				description,
				fieldID,
				weight,
				notificationsEnabled,
			} = milestoneConfiguration
			setValue('name', name)
			setValue('description', description)
			setValue('fieldID', fieldID)
			setValue('weight', weight)
			setValue('notificationsEnabled', notificationsEnabled)
		} else {
			reset()
		}
	}, [milestoneConfiguration, reset, setValue])

	const updateMilestoneConfigurationMutation = useMutation({
		mutationFn: ({ id, data }) =>
			updateMilestoneConfiguration(id, data),
	})

	const createMilestoneConfigurationMutation = useMutation({
		mutationFn: (data) => createMilestoneConfiguration(data),
	})

	const save = async () => {
		const data = { ...getValues(), loanType }

		try {
			if (milestoneConfiguration) {
				const { id, name, notificationsEnabled } =
					milestoneConfiguration
				await updateMilestoneConfigurationMutation.mutateAsync({
					id,
					data,
				})

				mixpanel.trackEvent(
					eventTypes.MILESTONE_CONFIGURATION_UPDATED,
					{
						name,
						id,
						notificationsEnabled,
					}
				)
			} else {
				await createMilestoneConfigurationMutation.mutateAsync(data)
				const { id, name, notificationsEnabled } = data
				mixpanel.trackEvent(
					eventTypes.MILESTONE_CONFIGURATION_CREATED,
					{
						name,
						id,
						notificationsEnabled,
					}
				)
			}
			reset()
			onSave()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			onDialogClose()
		}
	}

	const onDialogClose = () => {
		reset()
		onClose()
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={onDialogClose}
				aria-labelledby="responsive-dialog-title"
				fullScreen={fullScreen}
			>
				<DialogTitle>
					{milestoneConfiguration
						? 'Edit Milestone Configuration'
						: 'Add Milestone Configuration'}
				</DialogTitle>
				<form noValidate>
					<DialogContent dividers>
						<div className="mb-3">
							<TextField
								name="name"
								label="Name"
								className="w-full"
								placeholder="Enter name"
								control={control}
								errors={errors}
								fullWidth
							/>
						</div>
						<div className="mb-2">
							<TextField
								name="description"
								label="Description"
								className="w-full"
								placeholder="Enter description"
								multiline
								rows={3}
								control={control}
								errors={errors}
								fullWidth
							/>
						</div>
						<div className="mb-3">
							<TextField
								name="fieldID"
								label="Field ID"
								className="w-full"
								placeholder="Enter field ID"
								control={control}
								errors={errors}
								fullWidth
							/>
						</div>
						{milestoneConfiguration && (
							<div className="mb-2">
								<TextField
									name="weight"
									label="Weight"
									type="number"
									className="w-full"
									placeholder="Enter weight"
									helperText="Weight is used for sorting. The lower the number, the higher it will display in the list."
									control={control}
									errors={errors}
									fullWidth
								/>
							</div>
						)}
						{showNotificationsField && (
							<div className="mb-2">
								<Checkbox
									name="notificationsEnabled"
									label="Send Notifications"
									control={control}
									errors={errors}
								/>
							</div>
						)}
					</DialogContent>
					<DialogActions className="mt-3">
						<div className="flex justify-center items-center">
							{onClose && (
								<div className="mr-2">
									<Button
										id="Cancel"
										onClick={onDialogClose}
										variant="outlined"
									>
										Cancel
									</Button>
								</div>
							)}

							<LoadingBtn
								text="Save"
								onClick={handleSubmit(save)}
								loading={isSubmitting}
							/>
						</div>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default ModalMilestoneConfiguration
