import React from 'react'
import TextField from './TextField'
import { applyMask } from '../../services/utils'

const PhoneNumberField = (props) => {
	const onChange = (event) => {
		event.target.value = applyMask(event.target.value, 'phone')
	}

	return <TextField onChange={onChange} {...props} />
}

export default PhoneNumberField
