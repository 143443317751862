import React from 'react'
import {
	Form,
	FormRow,
	TextField,
} from '../../../../components/formControls'
import * as yup from 'yup'
import useForm from '../../../../hooks/useForm'
import { Button } from '@mui/material'
import { LoadingBtn } from '../../../../components/Button'

const LoanTaskFieldForm = ({
	onSubmit,
	loanTask,
	onCancel = undefined,
}) => {
	const { task } = loanTask
	const schema = yup.object().shape({
		value: yup.string().required(`${task.name} is required`),
	})

	const form = useForm({
		schema,
		values: {
			value: '',
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
	} = form

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<FormRow>
				<TextField
					name="value"
					label={task.name}
					className="w-full"
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow className="flex flex-row justify-between items-center mt-3">
				{onCancel && (
					<div className="mr-2">
						<Button id="Cancel" onClick={onCancel} variant="outlined">
							Cancel
						</Button>
					</div>
				)}
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
					fullWidth={false}
				/>
			</FormRow>
		</Form>
	)
}

export default LoanTaskFieldForm
