import React from 'react'
import Dialog from '../../../../components/modals/Dialog'
import BusinessRuleForm from '../forms/BusinessRuleForm'

const ModalModalBusinessRuleForm = ({
	businessRule,
	onSubmit,
	errors,
	...rest
}) => {
	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			title={
				businessRule ? 'Edit Business Rule' : 'Add Business Rule'
			}
			{...rest}
		>
			<BusinessRuleForm
				apiErrors={errors}
				onSubmit={onSubmit}
				businessRule={businessRule}
			/>
		</Dialog>
	)
}

export default ModalModalBusinessRuleForm
