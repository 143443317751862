import React from 'react'
import {
	Tooltip,
	TooltipProps as MuiToolTipProps,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

type TooltipProps = Omit<MuiToolTipProps, 'children'>

const InfoTooltip = (props: TooltipProps) => {
	return (
		<Tooltip {...props}>
			<InfoIcon style={{ fontSize: 24 }} />
		</Tooltip>
	)
}

export default InfoTooltip
