import { DialogActions, Dialog, DialogContent } from '@mui/material'
import { Button } from '../Button'
import { getTheme } from '../../config'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

export const ModalUploadMimeType = ({
	visible = false,
	closeModal,
}) => {
	const theme = getTheme()

	return (
		<Dialog open={visible} aria-labelledby="responsive-dialog-title">
			<DialogContent>
				<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
					<WarningRoundedIcon
						sx={{ fontSize: 90 }}
						className="mb-2"
					/>
					<p className="text-lg">
						You have selected an invalid file format. Please upload a
						document in one of the following formats...{' '}
						{theme.validation.allowedDocumentTypes
							.map((type) => type.split('/')[1])
							.join(', ')}
					</p>
				</div>
			</DialogContent>
			<DialogActions className="ml-5 mr-5">
				<div className="flex justify-center items-center">
					<Button className="mt-5" text="OK" onClick={closeModal} />
				</div>
			</DialogActions>
		</Dialog>
	)
}
