export const currencyToFloat = (currency) => {
	const numbersOnly = currency.replace(/[^0-9.-]+/g, '')
	return parseFloat(numbersOnly)
}

export const percentToDecimal = (percent) => {
	if (percent) {
		return parseFloat(percent) / 100
	}
	return percent
}

export const decimalToPercent = (decimal, suffix = null) => {
	if (!decimal) {
		return null
	}

	return (decimal * 100).toFixed(2) + (suffix ? suffix : '')
}

export const addressToString = (address) => {
	let addressStr = ''
	if (address.street) {
		addressStr += address.street
		if (address.unit) {
			addressStr += ' ' + address.unit
		}
	}

	if (address.city) {
		addressStr += ' ' + address.city
	}

	if (address.state) {
		addressStr += ' ' + address.state
		if (address.state && address.postalCode) {
			addressStr += ','
		}
	}

	if (address.postalCode) {
		addressStr += ' ' + address.postalCode
	}

	return addressStr.trim()
}

export const leadAddressToString = (lead) => {
	const {
		subjectPropertyAddressStreet,
		subjectPropertyAddressCity,
		subjectPropertyAddressState,
		subjectPropertyAddressZip,
	} = lead
	return `${subjectPropertyAddressStreet || ''} ${subjectPropertyAddressCity || ''} ${subjectPropertyAddressState || ''} ${subjectPropertyAddressZip || ''}`
}

export const getLeadBorrowerName = (lead) => {
	const { firstName, lastName, data } = lead
	if (data && (data.BorrowerFirstName || data.BorrowerLastName)) {
		return `${data.BorrowerFirstName || ''} ${data.BorrowerLastName || ''}`
	}
	return `${firstName || ''} ${lastName || ''}`.trim()
}

export const formatPhoneWithCountryCode = (phone) => {
	return `+1${phone.replace(/\D/g, '')}`
}
