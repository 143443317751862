import React from 'react'
import { isDarkMode } from '../../services/helper'
import { useAppContext } from '../../components/AppContext'
import AuthWrapper from './AuthWrapper'

const AuthPage = ({ title, children }) => {
	const { state } = useAppContext()
	const { siteConfig } = state

	return (
		<AuthWrapper siteConfig={siteConfig} title={title}>
			<img
				className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
				src={
					isDarkMode() && siteConfig?.darkModePortalLogoUrl
						? siteConfig.darkModePortalLogoUrl
						: siteConfig?.portalLogoUrl || siteConfig?.logoUrl
				}
				alt="Logo"
			/>
			{children}
		</AuthWrapper>
	)
}

export default AuthPage
