import React from 'react'
import {
	Dialog as MuiDialog,
	DialogContent,
	DialogTitle,
} from '@mui/material'
import type { DialogProps as MuiDialogProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

export type DialogProps = {
	title?: string
	onClose?: () => void
} & MuiDialogProps

const Dialog = ({
	title,
	onClose,
	children,
	...rest
}: DialogProps) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<MuiDialog fullScreen={fullScreen} onClose={onClose} {...rest}>
			{title && (
				<DialogTitle className="flex justify-between items-center">
					{title}{' '}
					{onClose && (
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					)}
				</DialogTitle>
			)}
			<DialogContent dividers>{children}</DialogContent>
		</MuiDialog>
	)
}

Dialog.defaultProps = {
	title: undefined,
	onClose: undefined,
}

export default Dialog
