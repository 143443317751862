import React from 'react'
import { Button } from '../../../../components/Button'
import { getTheme } from '../../../../config'
import { useWindowSize } from '../../../../hooks'

const theme = getTheme()

const TaskActionButton = ({ task, ...rest }) => {
	const { mobile: breakpoint } = theme.breakpoints
	const [width] = useWindowSize()
	const isMobile = width < breakpoint

	const VERIFY = 'Verify'
	const textMap = {
		Document: 'Upload',
		Field: 'Answer',
		VerificationOfAssets: VERIFY,
		VerificationOfIncome: VERIFY,
		VerificationOfEmployment: VERIFY,
		VerificationOfIncomeAndEmployment: VERIFY, // verify income and employment done together, might not be separate
	}

	if (!textMap[task.type]) {
		// enum may exist but feature is incomplete
		return null
	}

	return (
		<Button
			text={textMap[task.type]}
			size={isMobile ? 'small' : undefined}
			style={!isMobile ? { width: 160 } : undefined}
			{...rest}
		/>
	)
}

export default TaskActionButton
