import loading from 'assets/loading.gif'

export function LoadingPage() {
	return (
		<div className="max-w-screen-xl flex items-center h-screen mx-auto">
			<img
				className="max-w-xs mx-auto"
				src={loading}
				alt="Loading..."
			/>
		</div>
	)
}
