import React from 'react'
import clsx from 'clsx'
import {
	CheckCircleOutlined,
	ErrorOutlineOutlined,
	InfoOutlined,
	WarningOutlined,
} from '@mui/icons-material'

const AlertBox = ({
	severity = 'info',
	children,
	className = undefined,
}) => {
	const iconMap = {
		info: <InfoOutlined />,
		success: <CheckCircleOutlined />,
		warning: <WarningOutlined />,
		error: <ErrorOutlineOutlined />,
	}

	const classMap = {
		info: 'bg-blue-100 text-blue-800 border-blue-200',
		success: 'bg-green-100 text-green-700 border-green-200',
		warning: 'bg-orange-100 text-orange-700 border-orange-200',
		error: 'bg-red-100 text-red-700 border-red-200',
	}

	return (
		<div
			className={clsx(
				'font-bold border rounded px-3 py-2',
				classMap[severity],
				className
			)}
		>
			<div className="flex flex-row items-center">
				<div className="mr-2">{iconMap[severity]}</div>
				<div className="flex-grow">{children}</div>
			</div>
		</div>
	)
}

export default AlertBox
