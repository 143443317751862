import React, { useEffect, useState } from 'react'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'

import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { ModalRawData } from '../../../components/modals/ModalRawData'
import withAuth from '../../../components/withAuth.js'
import RequestQueueExpandedRow from './RequestQueueExpandedRow.js'

import {
	deleteRequestQueue,
	getRequestsQueue,
	runQueuedTask,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import queryKeys from '../../../services/queryKeys.js'
import { useAlert } from '../../../hooks'
import { getTheme } from '../../../config.js'

const theme = getTheme()

const AdminRequestQueue = () => {
	const { alert } = useAlert()
	const queryClient = useQueryClient()
	const [filteredData, setFilteredData] = useState([])
	const [activeRow, setActiveRow] = useState()
	const [searchText, setSearchText] = useState('')
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: 'Are you sure you want to send this request?',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [showRowDataModal, setShowRowDataModal] = useState(false)
	const columns = [
		{
			name: 'Endpoint',
			selector: (row) => row.endpoint,
			sortable: true,
		},
		{
			name: 'Error Message',
			selector: (row) => row.errorMessage,
			sortable: true,
			hide: theme.breakpoints.dataTable,
			maxWidth: '300px',
		},
		{
			name: 'User',
			selector: (row) => row.userEmail,
			sortable: true,
		},
		{
			name: 'Created On',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const actionItems = [
		{
			name: 'View Details',
			onClick: (e, row) => handleViewDetailsModal(e, row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Send',
			onClick: (e, row) => handleOnSend(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Remove',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error,
		data: requestQueuesResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.requestQueues],
		queryFn: () => getRequestsQueue(),
		select: (data) => {
			return data.map((item) => ({ ...item, ID: item.id }))
		},
		retry: 2,
	})

	useEffect(() => {
		if (error) {
			alert('There was a problem loading the request queues', {
				severity: 'error',
			})
		}
	}, [error])

	useEffect(() => {
		if (searchText !== '') {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? requestQueuesResult
				: requestQueuesResult.filter(
						(d) =>
							d.userEmail
								?.toLowerCase()
								.includes(lowercasedSearchText) ||
							d.endpoint
								?.toLowerCase()
								.includes(lowercasedSearchText) ||
							d.errorMessage
								?.toLowerCase()
								.includes(lowercasedSearchText) ||
							d.status
								?.toLowerCase()
								.toString()
								.includes(lowercasedSearchText)
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(requestQueuesResult)
		}
	}, [requestQueuesResult, searchText])

	const createTaskMutation = useMutation({
		mutationFn: (id) => runQueuedTask(id),
		onSuccess: () => {
			setModalConfirmation((current) => ({ ...current, open: false }))
		},
	})

	const removeRequestMutation = useMutation({
		mutationFn: (id) => deleteRequestQueue(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.requestQueues])

			setRemoveModalVisible(false)
			alert(`Queued request successfully removed`)
			setRowToRemove(null)
		},
	})

	const removeRequest = async () => {
		try {
			if (rowToRemove && rowToRemove?.id)
				await removeRequestMutation.mutateAsync(rowToRemove?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const sendTask = async () => {
		try {
			if (activeRow && activeRow?.id)
				await createTaskMutation.mutateAsync(activeRow?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleViewDetailsModal = (e, row) => {
		setActiveRow(row)
		setShowRowDataModal(true)
	}

	const handleCloseDetailsModal = (e, row) => {
		setActiveRow(null)
		setShowRowDataModal(false)
	}

	const handleOnSend = (row) => {
		setActiveRow(row)
		setModalConfirmation((current) => ({ ...current, open: true }))
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalYesNo
					yesButtonName="Send"
					noButtonName="Cancel"
					modalConfirmation={modalConfirmation}
					setModalConfirmation={setModalConfirmation}
					modalConfirm={sendTask}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeRequest}
					row={rowToRemove}
					loading={removeRequestMutation.isPending}
				/>
				{showRowDataModal && (
					<ModalRawData
						data={activeRow}
						visible={Object.entries(activeRow).length !== 0}
						closeModal={handleCloseDetailsModal}
					/>
				)}
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title={'Queued Requests'}
					onRefreshClick={refetch}
					keyField="id"
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					fixedHeader={true}
					expandableRowsComponent={RequestQueueExpandedRow}
					actionItems={actionItems}
					onSearchChange={onSearchChange}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminRequestQueue)
