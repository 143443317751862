import type { UseFormProps, UseFormReturn } from 'react-hook-form'
import { useForm as reactHookUseForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export type ExtendedUseFormProps = UseFormProps & {
	schema?: yup.AnyObjectSchema
}

export type ExtendedUseFormReturn = UseFormReturn & {
	schema?: yup.AnyObjectSchema
}

const useForm = ({
	schema,
	mode = 'onBlur',
	reValidateMode = 'onChange',
	...rest
}: ExtendedUseFormProps): ExtendedUseFormReturn => {
	return reactHookUseForm({
		resolver: schema ? yupResolver(schema) : undefined,
		mode,
		reValidateMode,
		...rest,
	})
}

useForm.defaultProps = {
	schema: undefined,
	mode: 'onBlur',
	reValidateMode: 'onChange',
}

export default useForm
