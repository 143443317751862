import React from 'react'
import clsx from 'clsx'

const FieldLabel = ({
	label,
	required = false,
	className = undefined,
}) => {
	return (
		<label className={clsx('text-xs font-rubik', className)}>
			{label}
			{required ? '*' : ''}
		</label>
	)
}

export default FieldLabel
