import React from 'react'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'

const PipelineExpandedRow = ({ data: pipeline }) => {
	const {
		loanAmount,
		loanNumber,
		loanOfficer,
		subjectPropertyAddress,
		firstName,
		lastName,
		loanStatus,
		role,
	} = pipeline
	return (
		<div className="p-5 dark:bg-[#121212]">
			{firstName && (
				<RowExpandedProperty label="First Name" value={firstName} />
			)}
			{lastName && (
				<RowExpandedProperty label="Last Name" value={lastName} />
			)}
			{loanStatus && (
				<RowExpandedProperty label="Loan Status" value={loanStatus} />
			)}
			{role && <RowExpandedProperty label="Role" value={role} />}
			{loanAmount && (
				<RowExpandedProperty label="Loan Amount" value={loanAmount} />
			)}
			{loanNumber && (
				<RowExpandedProperty label="Loan Number" value={loanNumber} />
			)}
			{loanOfficer && (
				<RowExpandedProperty
					label="Loan Officer"
					value={loanOfficer}
				/>
			)}
			{subjectPropertyAddress && (
				<RowExpandedProperty
					label="Address"
					value={subjectPropertyAddress}
				/>
			)}
		</div>
	)
}

export default PipelineExpandedRow
