import React, { useRef, useState } from 'react'
import { Button } from '../../../../components/Button'
import {
	Button as MuiButton,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const AddRuleTaskButtonGroup = ({ onNewClick, onExistingClick }) => {
	const addBtnGroupRef = useRef()
	const [popoverOpen, setPopoverOpen] = useState(false)

	const handleAddPopoverClose = () => {
		setPopoverOpen(false)
	}

	const handleAddPopoverToggle = () => {
		setPopoverOpen(!popoverOpen)
	}

	return (
		<>
			<ButtonGroup variant="contained" ref={addBtnGroupRef}>
				<Button text="Add Task" onClick={onNewClick} />
				<MuiButton size="small" onClick={handleAddPopoverToggle}>
					<ArrowDropDownIcon />
				</MuiButton>
			</ButtonGroup>
			<Popper
				open={popoverOpen}
				anchorEl={addBtnGroupRef.current}
				role={undefined}
				placement="bottom-end"
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleAddPopoverClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									<MenuItem selected onClick={onNewClick}>
										Add Task
									</MenuItem>
									<MenuItem onClick={onExistingClick}>
										Add from Template
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
}

export default AddRuleTaskButtonGroup
