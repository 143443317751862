import React, { useState, useEffect, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material'
import { Check } from '@mui/icons-material'

import Page from '../../../components/Page'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import DataTable from '../../../components/DataTable'
import withAuth from '../../../components/withAuth'
import TemplateExpandedRow from './TemplateExpandedRow'

import {
	getDocumentTemplates,
	deleteDocumentTemplate,
	undeleteDocumentTemplate,
} from '../../../services/client'
import {
	documentTemplateTypes,
	formatDate,
	getErrorMessage,
} from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'

import { useAlert, useWindowSize } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'

const theme = getTheme()

const AdminDocumentTemplates = () => {
	const [width] = useWindowSize()
	const mixpanel = useMixpanel()
	const { alert } = useAlert()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [filteredData, setFilteredData] = useState([])
	const [searchText, setSearchText] = useState('')
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [activeRow, setActiveRow] = useState()
	const [filters, setFilters] = useState({
		type: 'all',
		borrowerType: 'all',
		active: 'active',
		status: 'all',
		hideDefaults: false,
	})

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Description',
			selector: (row) => row.description,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Default',
			selector: (row) => row.isDefault,
			cell: (row) => (row.isDefault ? <Check /> : '-'),
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => row.deletedAt,
			cell: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null || row.isDefault,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error,
		data: documentTemplatesResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.documentTemplates],
		queryFn: () => getDocumentTemplates(true),
		retry: 2,
	})

	const populatedTableData = useMemo(() => {
		return (filteredData || []).map((row) => {
			const templateType = documentTemplateTypes.find(
				(t) => t.value === row.type
			)
			return { ...row, type: templateType ? templateType.label : '' }
		})
	}, [filteredData])

	useEffect(() => {
		if (error) {
			alert('There was a problem loading the document templates', {
				severity: 'error',
			})
		}
	}, [error])

	useEffect(() => {
		if (
			documentTemplatesResult &&
			(searchText !== '' ||
				filters.active !== 'all' ||
				filters.status !== 'all' ||
				filters.hideDefaults)
		) {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? documentTemplatesResult
				: documentTemplatesResult.filter(
						(d) =>
							d.name?.toLowerCase().includes(lowercasedSearchText) ||
							d.description
								?.toLowerCase()
								.includes(lowercasedSearchText)
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				if (filters.status !== 'all' && d.status !== filters.status)
					return false
				return !(filters.hideDefaults && d.isDefault)
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(documentTemplatesResult)
		}
	}, [documentTemplatesResult, searchText, filters])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.documentTemplates,
				queryKeys.documentTemplate,
			],
		})
		await refetch()
	}

	const handleMutationSuccess = (
		eventType,
		modalSetter,
		successMessage
	) => {
		const { id, name } = activeRow
		mixpanel.trackEvent(eventType, {
			name,
			id,
		})
		modalSetter(false)
		alert(`Document "${name}" successfully ${successMessage}`)
		setActiveRow(null)
	}

	const restoreDocumentMutation = useMutation({
		mutationFn: (id) => undeleteDocumentTemplate(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.DOCUMENT_TEMPLATE_RESTORED,
				setRestoreModalVisible,
				'restored'
			)
		},
	})

	const removeDocumentMutation = useMutation({
		mutationFn: (id) => deleteDocumentTemplate(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.DOCUMENT_TEMPLATE_DELETED,
				setRemoveModalVisible,
				'removed'
			)
		},
	})

	const removeDocumentTemplate = async () => {
		try {
			if (activeRow?.id)
				await removeDocumentMutation.mutateAsync(activeRow?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const restoreDocumentTemplate = async () => {
		try {
			if (activeRow?.id)
				await restoreDocumentMutation.mutateAsync(activeRow?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const onAddClick = () => {
		navigate(
			generatePath(
				`/${navigationLinking.AdminDocumentTemplateEdit}`,
				{ id: 'new' }
			)
		)
	}

	const onRowClicked = (row) => {
		navigate(
			generatePath(
				`/${navigationLinking.AdminDocumentTemplateEdit}`,
				{ id: row.id }
			)
		)
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setActiveRow(row)
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setActiveRow(row)
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const handleCheckBoxChange = (field) => {
		setFilters((f) => ({ ...f, [field]: !f[field] }))
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	const onClearSearchClick = () => {
		setSearchText('')
	}
	return (
		<Page
			page="app-document-templates"
			title="Document Templates"
			isFullWidth
		>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={removeDocumentTemplate}
				row={activeRow}
				loading={removeDocumentMutation.isPending}
			/>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={restoreModalVisible}
				setRemoveModalVisible={setRestoreModalVisible}
				restore={restoreDocumentTemplate}
				row={activeRow}
				loading={restoreDocumentMutation.isPending}
			/>
			<div className="pl-5 pr-5 pb-10 pt-5 h-full overflow-auto">
				<DataTable
					data={populatedTableData}
					columns={columns}
					fixedHeader
					pagination
					defaultSortAsc={false}
					defaultSortFieldId="type"
					progressPending={isFetching || isRefetching}
					title="Document Templates"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					onClearSearchClick={onClearSearchClick}
					expandableRowsComponent={TemplateExpandedRow}
					searchText={searchText}
					searchFilters={
						<>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
							<FormControl id="filterStatus" variant="standard">
								<InputLabel>Published / Draft</InputLabel>
								<Select
									value={filters.status}
									onChange={(e) => handleFilterChange(e, 'status')}
								>
									<MenuItem value="Published">
										Show Published Only
									</MenuItem>
									<MenuItem value="Draft">Show Draft Only</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
							<FormControlLabel
								label={
									<Typography className="dark:text-white">
										Hide Defaults
									</Typography>
								}
								className={`select-none`}
								control={
									<Checkbox
										id={`hideDefaults`}
										checked={filters.hideDefaults}
										onChange={(e) =>
											handleCheckBoxChange('hideDefaults')
										}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
							/>
						</>
					}
					actionItems={actionItems}
					addNewBtn={{
						text: 'Add Document Template',
						onClick: onAddClick,
					}}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminDocumentTemplates)
