import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Button } from '../Button'
import { isDarkMode } from '../../services/helper'
import { useAppContext } from '../AppContext'

export const ModalYesNo = ({
	modalConfirmation,
	setModalConfirmation,
	modalConfirm,
	modalDismiss,
	saveForm,
	yesButtonName,
	noButtonName,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
	const { state } = useAppContext()
	const { siteConfig } = state

	const handleYesClick = () => {
		if (modalConfirmation.isSave) {
			modalConfirm({ ...modalConfirmation, canBeSaved: true })
			saveForm()
		} else {
			modalConfirm(modalConfirmation)
		}
	}

	const handleNoClick = () => {
		if (typeof modalDismiss === 'function') modalDismiss()
		else
			setModalConfirmation({
				...modalConfirmation,
				canBeSaved: false,
				open: false,
			})
	}

	return (
		<Dialog
			open={modalConfirmation.open}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<img
					src={
						isDarkMode() && siteConfig?.darkModeLogoUrl
							? siteConfig.darkModeLogoUrl
							: siteConfig?.logoUrl
					}
					alt="Company Logo"
					className="w-64 sm:w-96 py-5"
				/>
				<p className="pb-5 text-xl text-center font-bold">
					{modalConfirmation.text}
				</p>
				{modalConfirmation.isOkButton ? (
					<Button
						id="ModalYesNoOKButton"
						text="Ok"
						onClick={() =>
							setModalConfirmation({
								...modalConfirmation,
								canBeSaved: false,
								open: false,
							})
						}
						style={fullScreen ? { width: '90%' } : { width: 290 }}
						variant="outlined"
					/>
				) : (
					<>
						<Button
							id="ModalYesNoOKButton"
							text={yesButtonName || 'Yes'}
							onClick={handleYesClick}
							style={
								fullScreen
									? { width: '90%', marginBottom: 10 }
									: { width: 290, marginBottom: 10 }
							}
							variant="contained"
						/>
						<Button
							id="ModalYesNoYesButton"
							text={noButtonName || 'No'}
							onClick={handleNoClick}
							style={fullScreen ? { width: '90%' } : { width: 290 }}
							variant="outlined"
						/>
					</>
				)}
			</div>
		</Dialog>
	)
}
