import Page from '../../components/Page'
import { loanDisclosureText } from '../../services/helper'

export default function Fees() {
	const title = 'Closing costs/fees'

	const data = [
		{
			title: 'Lender Charges',
			subTitle: 'Cost',
			subData: [
				{
					title: 'Loan Discount Points',
					price: '$10,595',
					warning: true,
				},
			],
		},
		{
			title: 'Third-party Services (you cannot shop for)',
			subData: [
				{ title: 'Appraisal', price: '$470', warning: true },
				{ title: 'Credit Report', price: '$65', warning: true },
				{ title: 'Flood Certification', price: '$13', warning: true },
				{ title: 'Tax Services', price: '$64', warning: true },
				{ title: 'Verification Fee', price: '$75', warning: false },
			],
		},
		{
			title: 'Third-party Services (you can shop for)',
			subData: [
				{
					title: 'Title - Closing Full Escrow 1st Mortgage',
					price: '$425',
					warning: true,
				},
				{
					title: 'Title - Mortgage Title Premium',
					price: '$325',
					warning: true,
				},
			],
		},
		{
			title: 'Government Fees',
			subData: [
				{ title: 'Recording Mortgage', price: '$187', warning: true },
			],
		},
	]

	return (
		<Page page="app-loan-officer-left" title="FeesView">
			<div className="pt-5 flex justify-center">
				<div className="md:p-5 w-full self-center md:max-w-screen-md">
					<div className="w-full mb-2.5">
						<p className="text-2xl font-bold font-rubik text-slate-500">
							{title.toUpperCase()}
						</p>
					</div>
					{data.map((record, index) => (
						<div className="w-full" key={index}>
							<div
								className="w-full flex flex-row justify-between pb-1.5 border-b border-b-slate-500"
								key={index}
							>
								<p className="text-lg font-bold text-slate-500">
									{' '}
									{record.title}{' '}
								</p>
								<p className="text-lg font-bold ml-2.5 text-slate-500">
									{record.subTitle}
								</p>
							</div>
							{record.subData.map((subRecord, subIndex) => (
								<div
									className="w-full flex flex-row justify-between"
									key={subIndex}
								>
									<p
										className={`${subRecord.warning ? 'text-red-500' : 'text-slate-300'} text-lg font-bold `}
									>
										{subRecord.title}{' '}
									</p>
									<p className="text-lg font-bold ml-2.5 text-slate-300">
										{subRecord.price}
									</p>
								</div>
							))}
						</div>
					))}
					<div className="w-full flex flex-row justify-between items-center bg-lime-600">
						<p className="text-lg font-bold font-rubik text-slate-600">
							Total Closing Costs/Fees
						</p>
						<p className="font-lg font-bold ml-2.5 text-slate-600">
							$12,219
						</p>
					</div>
					<div className="w-full mt-5">
						<span className="font-bold text-slate-300">
							<span className="font-bold text-slate-500">
								{' '}
								Disclosure:{' '}
							</span>
							{loanDisclosureText}
						</span>
					</div>
				</div>
			</div>
		</Page>
	)
}
