import { DialogActions, Dialog, DialogContent } from '@mui/material'
import { Button } from '../Button'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

export const ModalUploadSizeLimit = ({
	visible = false,
	fileSize = '20',
	closeModal,
}) => {
	return (
		<Dialog open={visible} aria-labelledby="responsive-dialog-title">
			<DialogContent>
				<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
					<WarningRoundedIcon
						sx={{ fontSize: 90 }}
						className="mb-2"
					/>
					<p className="text-lg">
						The file is too large to upload. The size limit is{' '}
						{fileSize} MB. Please reduce the file size and try again.
					</p>
				</div>
			</DialogContent>
			<DialogActions className="ml-5 mr-5">
				<div className="flex justify-center items-center">
					<Button className="mt-5" text="OK" onClick={closeModal} />
				</div>
			</DialogActions>
		</Dialog>
	)
}
