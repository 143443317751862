import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import Page from '../../../components/Page'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import DataTable from '../../../components/DataTable'
import {
	getBusinessRules,
	removeBusinessRule,
	createBusinessRule,
	updateBusinessRule,
	undeleteBusinessRule,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { getTheme } from '../../../config'
import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import withAuth from '../../../components/withAuth'
import ModalBusinessRuleForm from './components/ModalBusinessRuleForm'
import { cloneDeep } from 'lodash'
import ActiveDeletedSelect from '../../../components/formControls/ActiveDeletedSelect'
import RuleRowExpanded from './components/RuleRowExpanded'
import InfoTooltip from '../../../components/InfoTooltip'

const theme = getTheme()

function AdminRules() {
	const { alert } = useAlert()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const mixpanel = useMixpanel()

	const [filteredBusinessRules, setFilteredBusinessRules] = useState(
		[]
	)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [selectedRule, setSelectedRule] = useState()
	const [searchText, setSearchText] = useState('')
	const [ruleModalOpen, setRuleModalOpen] = useState(false)
	const [apiErrors, setApiErrors] = useState([])
	const [activeDeletedFilter, setActiveDeletedFilter] =
		useState('active')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			cell: (row) => (
				<>
					{row.name}
					{row.description && (
						<InfoTooltip
							className="pl-2"
							title={row.description}
						></InfoTooltip>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: '# of Tasks',
			selector: (row) => row.tasks.length,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => row.deletedAt,
			cell: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const {
		isFetching,
		isRefetching,
		isError,
		data: businessRules,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.businessRules],
		queryFn: () => getBusinessRules(true),
	})

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading the business rules', {
				severity: 'error',
			})
		}
	}, [isError, alert])

	const refetchAndInvalidateRules = async () => {
		await refetch()
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.businessRules],
		})
	}

	const createRuleMutation = useMutation({
		mutationFn: async (data) => createBusinessRule(data),
	})

	const updateRuleMutation = useMutation({
		mutationFn: async (data) => updateBusinessRule(data.id, data),
	})

	const removeRuleMutation = useMutation({
		mutationFn: async (id) => removeBusinessRule(id),
	})

	const restoreRuleMutation = useMutation({
		mutationFn: async (id) => undeleteBusinessRule(id),
	})

	useEffect(() => {
		let filteredData = cloneDeep(businessRules || [])
		if (searchText) {
			filteredData = businessRules.filter((r) =>
				r.name.toLowerCase().includes(searchText.toLowerCase())
			)
			setFilteredBusinessRules(filteredData)
		}

		if (activeDeletedFilter !== 'all') {
			filteredData = filteredData.filter((r) =>
				activeDeletedFilter === 'active' ? !r.deletedAt : r.deletedAt
			)
		}

		setFilteredBusinessRules(filteredData)
	}, [businessRules, searchText, activeDeletedFilter])

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setSelectedRule(row)
	}

	const getMixpanelProperties = (rule) => {
		if (!rule) {
			return null
		}
		const { id, name } = rule
		return { id, name }
	}

	const saveRule = async (data) => {
		const formData = cloneDeep(data)
		formData.filter = data.filter.filter((f) => f.targetFieldValue)
		try {
			if (formData.id) {
				await updateRuleMutation.mutateAsync(formData)
				await refetchAndInvalidateRules()
				mixpanel.trackEvent(
					eventTypes.BUSINESS_RULE_UPDATED,
					getMixpanelProperties(formData)
				)
				alert(`Rule "${formData.name}" successfully updated`)
			} else {
				const result = await createRuleMutation.mutateAsync(formData)
				await refetchAndInvalidateRules()
				alert(`Rule "${formData.name}" successfully created`)
				mixpanel.trackEvent(
					eventTypes.BUSINESS_RULE_CREATED,
					getMixpanelProperties(result)
				)

				const editPath = generatePath(
					`/${navigationLinking.AdminRuleTasks}`,
					{ id: result.id }
				)
				await navigate(editPath)
			}
		} catch (e) {
			const errorMessage = getErrorMessage(e)
			setApiErrors([errorMessage])
			alert(errorMessage, { severity: 'error' })
		} finally {
			setRuleModalOpen(false)
		}
	}

	useEffect(() => {
		if (!ruleModalOpen) {
			setApiErrors([])
		}
	}, [ruleModalOpen])

	const removeRule = async () => {
		setRemoveModalVisible(false)

		try {
			const { id, name } = selectedRule
			await removeRuleMutation.mutateAsync(id)
			await refetchAndInvalidateRules()
			mixpanel.trackEvent(
				eventTypes.BUSINESS_RULE_DELETED,
				getMixpanelProperties(selectedRule)
			)
			alert(`Rule "${name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const showRestoreModal = (row) => {
		setRemoveModalVisible(true)
		setSelectedRule(row)
	}

	const handleRestoreRule = async () => {
		setRemoveModalVisible(false)

		try {
			const { id, name } = selectedRule
			await restoreRuleMutation.mutateAsync(id)
			await refetchAndInvalidateRules()
			alert(`Business rule ${name} successfully restored`)
			setSelectedRule(null)
			mixpanel.trackEvent(
				eventTypes.BUSINESS_RULE_RESTORED,
				getMixpanelProperties(selectedRule)
			)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleAddClick = () => {
		setRuleModalOpen(true)
	}

	const handleEditClick = (row) => {
		setSelectedRule(row)
		setRuleModalOpen(true)
	}

	const handleModalClose = () => {
		setRuleModalOpen(false)
		setSelectedRule(null)
	}

	const handleSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	const handleSearchClear = () => {
		setSearchText('')
	}

	const handleRowClick = (row) => {
		const detailPath = generatePath(
			`/${navigationLinking.AdminRuleTasks}`,
			{ id: row.id }
		)
		navigate(detailPath)
	}

	const handleActiveDeletedFilterChange = (e) => {
		setActiveDeletedFilter(e.target.value)
	}

	return (
		<Page title="Business Rules" isFullWidth={true}>
			<ModalBusinessRuleForm
				businessRule={selectedRule}
				open={ruleModalOpen}
				errors={apiErrors}
				onSubmit={saveRule}
				onClose={handleModalClose}
			/>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={!selectedRule?.deletedAt ? removeRule : undefined}
					restore={
						selectedRule?.deletedAt ? handleRestoreRule : undefined
					}
					row={selectedRule}
				/>
				<DataTable
					data={filteredBusinessRules || []}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching}
					refreshing={isRefetching}
					title="Rules"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={handleRowClick}
					expandableRowsComponent={RuleRowExpanded}
					searchText={searchText}
					onSearchChange={handleSearchChange}
					onClearSearchClick={handleSearchClear}
					searchFilters={
						<ActiveDeletedSelect
							fullWidth
							sx={{ width: 200 }}
							onChange={handleActiveDeletedFilterChange}
							value={activeDeletedFilter}
						/>
					}
					actionItems={[
						{
							name: 'View/Manage Tasks',
							onClick: (e, row) => handleRowClick(row),
							hideIf: (row) => row.deletedAt !== null,
						},
						{
							name: 'Edit/Manage Conditions',
							onClick: (e, row) => handleEditClick(row),
							hideIf: (row) => row.deletedAt !== null,
						},
						{
							name: 'Delete',
							onClick: (e, row) => showRemoveModal(row),
							hideIf: (row) => row.deletedAt !== null,
						},
						{
							name: 'Restore',
							onClick: (e, row) => showRestoreModal(row),
							hideIf: (row) => row.deletedAt === null,
						},
					]}
					addNewBtn={{ text: 'Add Rule', onClick: handleAddClick }}
				/>
			</div>
		</Page>
	)
}

export default withAuth(AdminRules)
