import React from 'react'
import Dialog from './Dialog'
import type { DialogProps } from './Dialog'
import { DeleteMeForm } from '../../pages/protected/userProfile/forms/DeleteMeForm'

export type ModalDeleteMeProps = {
	onSubmit: () => void
} & DialogProps

export const ModalDeleteMe = ({
	onSubmit,
	onClose,
	...rest
}: ModalDeleteMeProps) => {
	return (
		<Dialog
			title="Submit a request to delete my user account"
			onClose={onClose}
			{...rest}
		>
			<div className="flex flex-col items-center px-6 md:max-w-xl py-8 md:px-10">
				<p className="pb-5 text-md">
					The process of fulfilling your deletion request may take
					several days to process. You will receive an email
					notification from our support team once your request has
					been completed.
				</p>

				<DeleteMeForm onSubmit={onSubmit} onClose={onClose} />
			</div>
		</Dialog>
	)
}
