import React, { createContext, useEffect, useMemo } from 'react'
import mixpanel from 'mixpanel-browser'

export const MixpanelContext = createContext(null)

export const MixpanelProvider = ({ children }) => {
	const mixPanelKey = process.env.REACT_APP_MIXPANEL_API_KEY
	const MIXPANEL_DEBUG =
		process.env.REACT_APP_MIXPANEL_DEBUG === 'true'

	const mixpanelInstance = useMemo(() => {
		mixpanel.init(mixPanelKey, {
			debug: MIXPANEL_DEBUG,
		})
		return mixpanel
	}, [mixPanelKey])

	return (
		<MixpanelContext.Provider value={mixpanelInstance}>
			{children}
		</MixpanelContext.Provider>
	)
}
