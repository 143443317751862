import React from 'react'
import { navigationLinking } from '../services/navigation'
import { useAppContext } from './AppContext'
import { useNavigate } from 'react-router-dom'

const withAuth = (Component) => {
	return function (props) {
		const { state } = useAppContext()
		const navigate = useNavigate()
		const { user } = state

		if (!user?.isLoggedIn) {
			return navigate(`/${navigationLinking.SignIn}`)
		}

		return <Component {...props} />
	}
}

export default withAuth
