import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MobilePhoneVerification from '../MobilePhoneVerification'

const ModalMobilePhoneVerificationModal = ({
	onClose,
	onComplete,
	...rest
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog
			{...rest}
			aria-labelledby="responsive-dialog-title"
			fullScreen={fullScreen}
		>
			<DialogTitle className="flex justify-between items-center">
				Confirm your mobile phone number
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<MobilePhoneVerification
					onComplete={onComplete}
					className="pt-5"
				/>
			</DialogContent>
		</Dialog>
	)
}

export default ModalMobilePhoneVerificationModal
