export const eventTypes = {
	FORGOT_PASSWORD_SUBMITTED: 'Forgot Password Submitted',
	BUSINESS_RULE_CREATED: 'Business Rule Created',
	BUSINESS_RULE_UPDATED: 'Business Rule Updated',
	BUSINESS_RULE_DELETED: 'Business Rule Deleted',
	BUSINESS_RULE_RESTORED: 'Business Rule Restored',
	BORROWER_CREATED: 'Borrower Created',
	USER_CREATED: 'User Created',
	USER_DELETED: 'User Deleted',
	USER_NUKED: 'User Nuked',
	USER_RESTORED: 'User Restored',
	IMPERSONATION_REQUESTED: 'Impersonation Requested',
	IMPERSONATION_FORCED: 'Impersonation Forced',
	IMPERSONATION_STARTED: 'Impersonation Started',
	IMPERSONATION_STOPPED: 'Impersonation Stopped',
	IMPERSONATION_AUTHORIZED: 'Impersonation Authorized',
	ACCOUNT_SETTINGS_UPDATED: 'Account Settings Updated',
	MILESTONE_CONFIGURATION_CREATED: 'Milestone Configuration Created',
	MILESTONE_CONFIGURATION_UPDATED: 'Milestone Configuration Updated',
	MILESTONE_CONFIGURATION_DELETED: 'Milestone Configuration Deleted',
	TASK_CREATED: 'Task Created',
	TASK_UPDATED: 'Task Updated',
	LOAN_TASK_REMOVED: 'Loan Task Deleted',
	LOAN_TASK_CREATED: 'Loan Task Created',
	LOAN_TASK_UPDATED: 'Loan Task Updated',
	LOAN_TASKS_IMPORTED: 'Loan Tasks Imported',
	LOAN_TASK_COMPLETED: 'Loan Task Completed',
	LOAN_TASK_SUBMITTED: 'Loan Task Submitted',
	LOAN_TASK_REMINDER_SENT: 'Loan Tasks Reminder Sent',
	BUSINESS_RULE_TASK_REMOVED: 'Business Rule Task Deleted',
	BUSINESS_RULE_TASK_CREATED: 'Business Rule Task Created',
	BUSINESS_RULE_TASK_UPDATED: 'Business Rule Task Updated',
	//Loan Officer
	LOAN_OFFICER_UPDATED: 'Loan Officer Updated',
	LOAN_OFFICER_CREATED: 'Loan Officer Created',
	LOAN_OFFICER_DELETED: 'Loan Officer Deleted',
	LOAN_OFFICER_EXPORTED: 'Loan Officers Exported',
	LOAN_OFFICER_RESTORED: 'Loan Officers Restored',
	LEAD_CREATED: 'Lead Created',
	LEAD_UPDATED: 'Lead Updated',
	LEAD_DELETED: 'Lead Deleted',
	LOAN_CREATED: 'Loan Created',
	LISTING_CREATED: 'Listing Created',
	LISTING_UPDATED: 'Listing Updated',
	LISTING_DELETED: 'Listing Deleted',
	BRAND_CREATED: 'Brand Created',
	BRAND_UPDATED: 'Brand Updated',
	BRAND_RESTORED: 'Brand Restored',
	BRAND_DELETED: 'Brand Deleted',
	BRAND_EXPORTED: 'Brands Exported',
	BRANCH_CREATED: 'Branch Created',
	BRANCH_UPDATED: 'Branch Updated',
	BRANCH_DELETED: 'Branch Deleted',
	BRANCH_EXPORTED: 'Branches Exported',
	BRANCH_RESTORED: 'Branch Restored',
	PARTNER_CREATED: 'Partner Created',
	PARTNER_UPDATED: 'Partner Updated',
	PARTNER_DELETED: 'Partner Deleted',
	PARTNER_EXPORTED: 'Partners Exported',
	PARTNER_RESTORED: 'Partner Restored',
	NOTIFICATION_TEMPLATE_CREATED: 'Notification Template Created',
	NOTIFICATION_TEMPLATE_UPDATED: 'Notification Template Updated',
	NOTIFICATION_TEMPLATE_DELETED: 'Notification Template Deleted',
	NOTIFICATION_TEMPLATE_RESTORED: 'Notification Template Restored',
	NOTIFICATION_TEMPLATE_PREVIEWED: 'Notification Template Previewed',
	NOTIFICATION_TEMPLATE_TEST_SENT: 'Notification Template Test Sent',
	DOCUMENT_TEMPLATE_CREATED: 'Document Template Created',
	DOCUMENT_TEMPLATE_UPDATED: 'Document Template Updated',
	DOCUMENT_TEMPLATE_DELETED: 'Document Template Deleted',
	DOCUMENT_TEMPLATE_RESTORED: 'Document Template Restored',
	DOCUMENT_TEMPLATE_VERSION_UPDATED:
		'Document Template Version Updated',
	DOCUMENT_TEMPLATE_VERSION_DELETED:
		'Document Template Version Deleted',
	USER_PROFILE_DARK_MODE_TOGGLED: 'User Profile Dark Mode Toggled',
	USER_PROFILE_MFA_TOGGLED: 'User Profile MFA Toggled',
	ACCOUNT_PROFILE_MFA_PREFERENCE_CHANGED:
		'Account Profile MFA Preference Changed',
	ACCOUNT_PROFILE_MILESTONE_CONFIGURATION_CHANGED:
		'Account Profile Milestone Configuration Changed',
	ACCOUNT_PROFILE_ASO_SETTINGS_TOGGLED:
		'Account Profile ASO Settings Toggled',
	USER_PASSWORD_UPDATED: 'User Password Updated',
	WORKFLOW_CREATED: 'Workflow Created',
	WORKFLOW_UPDATED: 'Workflow Updated',
	WORKFLOW_DELETED: 'Workflow Deleted',
	WORKFLOW_PREVIEW: 'Workflow Preview',
	WORKFLOW_RESTORED: 'Workflow Restored',
	// WORKFLOW_PREVIEWED: 'Workflow Previewed', // not used
	ACCOUNT_MFA_PREFERENCE_CHANGED: 'Account MFA Preference Changed',
	SEND_TEST_NOTIFICATION: 'Send Test Notification',
	PREVIEW_AFTER_LOAN_OFFICER_SELECTED:
		'Preview After Loan Officer Selected',
	GET_RATE: 'Get Rate',
	ADD_BORROWER_MANUALLY: 'Add Borrower Manually',
	ADD_BORROWER_BY_INVITATION: 'Add Borrower By Invitation',
	CALCULATE_LOAN_PAYMENT: 'Calculate Loan Payment',
	CREDIT_SOFT_PULL_REQUEST: 'Credit Soft Pull Request',
	CREDIT_AUS_PRICING: 'Credit AUS Pricing',
	GENERATE_PREQUAL_LETTER: 'Generate Prequal Letter',
	GENERATE_PREAPPROVAL_LETTER: 'Generate Preapproval Letter',
	SAVE_TO_S2_S3: 'Save to S2/S3',
	GENERATE_LOAN_COMPARISON_PDF: 'Generate Loan Comparison PDF',
	DOCUMENT_TEMPLATE_DOWNLOADED: 'Document Template Downloaded',
	DOCUMENT_TEMPLATE_PREVIEWED: 'Document Template Previewed',
	ENCOMPASS_WEB: 'Encompass Web',
	NEED_HELP: 'Need Help',
	TASK_VERIFICATION_REQUEST: 'Task Verification Request',
	DOCUMENT_UPLOADED: 'Document Uploaded',
	WORKFLOW_COMPLETED: 'Workflow Completed',
	WORKFLOW_STARTED: 'Workflow Started',
}
