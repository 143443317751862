import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { useVenti } from 'venti'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'

import DataTable from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import Page from '../../../components/Page'
import withAuth from '../../../components/withAuth'
import BranchExpandedRow from './BranchExpandedRow'

import {
	getBranches,
	deleteBranch,
	undeleteBranch,
	getCorporations,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { eventTypes } from '../../../services/constants'
import queryKeys from '../../../services/queryKeys'

import { useMixpanel } from '../../../hooks/useMixpanel'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'

const theme = getTheme()

const AdminBranches = () => {
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [filteredData, setFilteredData] = useState([])
	const [corporations, setCorporations] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [activeRow, setActiveRow] = useState()
	const [filters, setFilters] = useState({
		type: 'all',
		brand: 'all',
		active: 'active',
	})
	const [searchText, setSearchText] = useState('')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'NMLSID',
			selector: (row) => row.nmlsid,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Brand',
			selector: (row) => row.corporateName,
			sortable: true,
		},
		{
			name: 'Domain',
			selector: (row) => row.url,
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => row.deletedAt,
			cell: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]
	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error: errorFetchingBranches,
		data: branchesResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.branches],
		queryFn: () => getBranches(true),
		select: (data) => {
			const branches = []
			data?.rows.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					// id is being removed, so replaced with ID
					// will figure out later why
					branches.push({
						...site,
						ID: site.id,
						corporateID: item.corporateID,
						corporateName: item.corporateName,
						branchId: item.id,
						createdAt: item.createdAt,
						deletedAt: item.deletedAt,
						type: item.type,
					})
				})
			})
			return branches
		},
	})

	const {
		error: errorFetchingCorporations,
		data: corporationsResult,
	} = useQuery({
		queryKey: [queryKeys.corporations],
		queryFn: () => getCorporations(),
		enabled: !!branchesResult,
		retry: 2,
	})

	useEffect(() => {
		if (errorFetchingBranches) {
			alert('There was a problem loading the branches', {
				severity: 'error',
			})
		}
	}, [errorFetchingBranches])

	useEffect(() => {
		if (errorFetchingCorporations) {
			alert('There was a problem loading the corporations', {
				severity: 'error',
			})
		}
	}, [errorFetchingCorporations])

	useEffect(() => {
		if (!corporationsResult) return
		setCorporations(
			corporationsResult?.rows.sort((a, b) =>
				a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
			)
		)
	}, [corporationsResult])

	useEffect(() => {
		if (
			branchesResult &&
			(searchText !== '' ||
				filters.type !== 'all' ||
				filters.brand !== 'all' ||
				filters.active !== 'all')
		) {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? branchesResult
				: branchesResult.filter(
						(d) =>
							d.name?.toLowerCase().includes(lowercasedSearchText) ||
							d.email?.toLowerCase().includes(lowercasedSearchText) ||
							d.url?.toLowerCase().includes(lowercasedSearchText) ||
							d.nmlsid?.toString().includes(lowercasedSearchText)
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (filters.type !== 'all' && d.type !== filters.type)
					return false
				if (
					filters.brand !== 'all' &&
					d.corporateID !== filters.brand
				)
					return false
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return true
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(branchesResult)
		}
	}, [branchesResult, searchText, filters])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.branches, queryKeys.branch],
		})
		await refetch()
	}

	const handleMutationSuccess = (
		eventType,
		modalSetter,
		successMessage
	) => {
		const { branchId, name } = activeRow
		mixpanel.trackEvent(eventType, {
			name,
			id: branchId,
		})
		modalSetter(false)
		alert(`Branch "${name}" successfully ${successMessage}`)
		setActiveRow(null)
	}

	const restoreBranchMutation = useMutation({
		mutationFn: (id) => undeleteBranch(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.BRANCH_RESTORED,
				setRestoreModalVisible,
				'restored'
			)
		},
	})

	const removeBranchMutation = useMutation({
		mutationFn: (id) => deleteBranch(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.BRANCH_DELETED,
				setRemoveModalVisible,
				'removed'
			)
		},
	})

	const removeBranch = async () => {
		try {
			if (activeRow?.branchId)
				await removeBranchMutation.mutateAsync(activeRow?.branchId)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const restoreBranch = async () => {
		try {
			if (activeRow?.branchId)
				await restoreBranchMutation.mutateAsync(activeRow?.branchId)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setActiveRow(row)
	}
	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setActiveRow(row)
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const onRowClicked = async (row) => {
		await ventiState.set(
			theme.storageKeys.editingBranchSiteConfigurationId,
			row.id
		)
		navigate(
			generatePath(`/${navigationLinking.AdminBranchEdit}`, {
				id: row.branchId,
			})
		)
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminBranchEdit}`, {
				id: 'new',
			})
		)
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeBranch}
					row={activeRow}
					loading={removeBranchMutation.isPending}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreBranch}
					row={activeRow}
					loading={restoreBranchMutation.isPending}
				/>
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="type"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title="Branches"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					searchFilters={
						<>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Type</InputLabel>
								<Select
									value={filters.type}
									onChange={(e) => handleFilterChange(e, 'type')}
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="Mortgage">Mortgage</MenuItem>
									<MenuItem value="RealEstate">Real Estate</MenuItem>
								</Select>
							</FormControl>
							<FormControl id="filterBrand" variant="standard">
								<InputLabel>Brand</InputLabel>
								<Select
									value={filters.brand}
									onChange={(e) => handleFilterChange(e, 'brand')}
								>
									<MenuItem value="all">All</MenuItem>
									{corporations?.map((corp, index) => (
										<MenuItem key={corp.id} value={corp.id}>
											{corp.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
						</>
					}
					exportEnabled={true}
					fixedHeader={true}
					expandableRowsComponent={BranchExpandedRow}
					actionItems={actionItems}
					addNewBtn={{ text: 'Add Branch', onClick: onAddClick }}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminBranches)
