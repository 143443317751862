import { useCallback } from 'react'

const useImageValidator = () => {
	const isImage = useCallback((file) => {
		const imageExtensions = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'webp',
			'heic',
			'svg',
		]
		const filePath = file?.file
			? file?.file?.url
			: file?.path || file?.name
		const extension = filePath.split('.').pop().toLowerCase()
		return imageExtensions.includes(extension)
	}, [])

	return isImage
}

export default useImageValidator
