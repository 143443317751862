import React from 'react'
import TaskForm from '../../forms/TaskForm'
import Dialog from './Dialog'

const ModalTaskForm = ({
	task,
	onSubmit,
	onClose = undefined,
	loanId = undefined,
	borrowers = [],
	...rest
}) => {
	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			title={task ? 'Edit Task' : 'Add Task'}
			onClose={onClose}
			{...rest}
		>
			<TaskForm
				task={task}
				onSubmit={onSubmit}
				onCancel={onClose}
				loanId={loanId}
				borrowers={borrowers}
			/>
		</Dialog>
	)
}

export default ModalTaskForm
