import React from 'react'
import { Form, Select } from '../components/formControls'
import { Button, LoadingBtn } from '../components/Button'
import useForm from '../hooks/useForm'
import TaskAutocompleteField from '../components/formControls/taskAutocomplete/TaskAutocompleteField'
import * as yup from 'yup'
import { MenuItem } from '@mui/material'

const ExistingTaskForm = ({ onSubmit, excludedIds = [] }) => {
	const schema = yup.object().shape({
		task: yup.object().required('Task is required'),
	})

	const form = useForm({
		schema,
		values: {
			task: null,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		getValues,
	} = form

	const submitForm = async () => {
		const { task } = getValues()
		await onSubmit(task)
	}

	return (
		<Form onSubmit={handleSubmit(submitForm)}>
			<TaskAutocompleteField
				name="task"
				label="Search tasks"
				valueField={null}
				excludedIds={excludedIds}
				control={control}
				errors={errors}
			/>
			<div className="mt-3">
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
					fullWidth={false}
				/>
			</div>
		</Form>
	)
}

export default ExistingTaskForm
