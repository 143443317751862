import React, { useEffect, useMemo, useState } from 'react'
import {
	generatePath,
	useNavigate,
	useParams,
} from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import SwipeableViews from 'react-swipeable-views'
import { cloneDeep } from 'lodash'
import { useTheme } from '@mui/material/styles'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	Button,
	FormControl,
	InputLabel,
	TextField,
	Select,
	MenuItem,
	Autocomplete,
	FormControlLabel,
	Typography,
	Switch,
} from '@mui/material'

import Page from '../../../components/Page'
import { useAppContext } from '../../../components/AppContext'
import { LoadingBtn } from '../../../components/Button'
import MailMergeSelect from '../../../components/MailMergeSelect'
import CodeEditor from '../../../components/CodeEditor'
import HandlebarsPreview from '../../../components/HandlebarsPreview'
import ModalNewVersion from '../../../components/modals/ModalNewVersion'
import ModalVersionList from '../../../components/modals/ModalVersionList'
import StatusButton from '../../../components/versions/StatusButton'
import StatusIcon from '../../../components/versions/StatusIcon'
import VersionSelector from '../../../components/versions/VersionSelector'
import ActiveVersionAlert from '../../../components/versions/ActiveVersionAlert'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'

import sampleLoanData from '../../../services/sampleLoanData.json'
import { replaceVars } from '../../../services/utils'
import { navigationLinking } from '../../../services/navigation'
import {
	getLoanOfficers,
	getDocumentTemplate,
	saveDocumentTemplate,
	getSiteConfigurationByUrl,
	saveDocumentTemplateVersion,
	deleteDocumentTemplateVersion,
	getDocumentTemplates,
	Roles,
} from '../../../services/client'
import {
	a11yProps,
	getErrorMessage,
	documentTemplateTypes,
} from '../../../services/helper'
import queryKeys from '../../../services/queryKeys'
import { useAlert } from '../../../hooks'
import useHTMLTemplatePreview from '../../../hooks/useHTMLTemplatePreview'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function AdminDocumentTemplateEdit() {
	const DRAFT_STATUS = 'Draft'
	const PUBLISHED_STATUS = 'Published'

	const { id } = useParams()
	const navigate = useNavigate()
	const mixpanel = useMixpanel()
	const themeMUI = useTheme()
	const queryClient = useQueryClient()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user } = state
	const { getPreviewData } = useHTMLTemplatePreview()

	const [tab, setTab] = useState(0)
	const [loanOfficer, setLoanOfficer] = useState(null)
	const [loanOfficers, setLoanOfficers] = useState([])
	const [selectedSiteConfig, setSelectedSiteConfig] = useState({})
	const [todaysDate, setTodaysDate] = useState('')
	const [hidePreview, setHidePreview] = useState(false)
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [selectedVersionActive, setSelectedVersionActive] =
		useState(false)
	const [htmlBody, setHtmlBody] = useState('')
	const [newVersionModalOpen, setNewVersionModalOpen] =
		useState(false)
	const [versionsModalOpen, setVersionsModalOpen] = useState(false)
	const [statusModal, setStatusModal] = useState({
		open: false,
		text: '',
		isSave: false,
	})
	const [idToFetchTemplate, setIdToFetchTemplate] = useState(null)
	const [
		fetchSiteConfigurationByUrl,
		setFetchSiteConfigurationByUrl,
	] = useState(null)
	const [template, setTemplate] = useState({
		id: '',
		name: '',
		htmlBody: '',
		type: '',
		description: '',
		versions: [],
		status: DRAFT_STATUS,
	})

	const {
		error: errorFetchingDocumentTemplate,
		data: documentTemplateResult,
		isFetching: isFetchingDocumentTemplate,
	} = useQuery({
		queryKey: [queryKeys.documentTemplate, idToFetchTemplate],
		queryFn: () => getDocumentTemplate(idToFetchTemplate),
		enabled: !!idToFetchTemplate && idToFetchTemplate !== 'new',
		retry: 2,
	})

	const {
		error: errorFetchingLoanOfficers,
		data: loanOfficersResult,
		isFetching: isFetchingLoanOfficers,
	} = useQuery({
		queryKey: [queryKeys.loanOfficers],
		queryFn: () => getLoanOfficers(true),
		retry: 2,
	})

	const {
		error: errorFetchingDocumentTemplates,
		data: documentTemplatesResult,
	} = useQuery({
		queryKey: [queryKeys.documentTemplates],
		queryFn: () => getDocumentTemplates(true),
		retry: 2,
	})

	const {
		error: errorFetchingSiteConfiguration,
		data: siteConfigurationResult,
		refetch: refetchSiteConfiguration,
	} = useQuery({
		queryKey: [
			queryKeys.documentTemplateSiteConfiguration,
			fetchSiteConfigurationByUrl,
		],
		queryFn: () =>
			getSiteConfigurationByUrl(fetchSiteConfigurationByUrl),
		enabled: !!fetchSiteConfigurationByUrl,
		retry: 2,
	})

	const isTemplateOverridden = useMemo(() => {
		const overrideTemplate = documentTemplatesResult?.filter(
			(activeTemplate) => activeTemplate?.type === template?.type
		)
		return (
			template?.isDefault &&
			!template?.multipleCustomTemplates &&
			overrideTemplate?.length > 1
		)
	}, [documentTemplatesResult])

	const currentActiveVersion = useMemo(() => {
		return template?.versions.find((v) => v.isActive)
	}, [template])

	const previewData = useMemo(
		() => getPreviewData(template),
		[template]
	)

	useEffect(() => {
		if (errorFetchingDocumentTemplates) {
			alert('There was a problem loading the document templates', {
				severity: 'error',
			})
		}
	}, [errorFetchingDocumentTemplates])

	useEffect(() => {
		if (errorFetchingDocumentTemplate) {
			alert('There was a problem loading the document template', {
				severity: 'error',
			})
		}
	}, [errorFetchingDocumentTemplate])

	useEffect(() => {
		if (errorFetchingLoanOfficers) {
			alert('There was a problem loading the loan officers', {
				severity: 'error',
			})
		}
	}, [errorFetchingLoanOfficers])

	useEffect(() => {
		if (errorFetchingSiteConfiguration) {
			alert('There was a problem loading the site configuration', {
				severity: 'error',
			})
		}
	}, [errorFetchingSiteConfiguration])

	useEffect(() => {
		if (fetchSiteConfigurationByUrl) {
			if (siteConfigurationResult)
				setSelectedSiteConfig(siteConfigurationResult)
			else refetchSiteConfiguration()
		}
	}, [fetchSiteConfigurationByUrl, siteConfigurationResult])

	useEffect(() => {
		if (loanOfficersResult) {
			const loanOfficersData = []
			loanOfficersResult?.rows.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					loanOfficersData.push({
						...item,
						label: `${item.firstName} ${item.lastName}`,
						url: site.url,
						siteConfigurationId: site.id,
					})
				})
			})
			setLoanOfficers(loanOfficersData)
		}
	}, [isFetchingLoanOfficers])

	useEffect(() => {
		const now = new Date()
		setTodaysDate(
			`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
		)
	}, [])

	useEffect(() => {
		if (id !== 'new') setIdToFetchTemplate(id)
	}, [id])

	useEffect(() => {
		if (documentTemplateResult && id !== 'new') {
			setTemplate(documentTemplateResult)
			const activeVersion = documentTemplateResult?.versions?.find(
				(v) => v.isActive
			)
			setSelectedVersion(activeVersion)
		}
	}, [isFetchingDocumentTemplate])

	useEffect(() => {
		if (!selectedVersion) {
			setSelectedVersion(currentActiveVersion)
		}
	}, [template?.versions, selectedVersion, currentActiveVersion])

	useEffect(() => {
		setHtmlBody(selectedVersion?.htmlBody || '')
	}, [selectedVersion])

	const canSubmit = template?.name.length > 0 && htmlBody.length > 0

	let saveButtonText = 'Create'
	if (template?.id) {
		if (template.isDefault) {
			saveButtonText = 'Override Default'
		} else {
			saveButtonText = 'Update'
		}
	}

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.documentTemplates,
				queryKeys.documentTemplate,
			],
		})
	}

	const createVersionMutation = useMutation({
		mutationFn: (data) => saveDocumentTemplateVersion(data),
		onSuccess: invalidateQueries,
	})

	const deleteVersionMutation = useMutation({
		mutationFn: (data) => deleteDocumentTemplateVersion(data),
		onSuccess: invalidateQueries,
	})

	const createTemplateMutation = useMutation({
		mutationFn: (data) => saveDocumentTemplate(data),
		onSuccess: invalidateQueries,
	})

	const getUpdates = () => {
		const updatedTemplate = {
			...template,
		}

		const updatedVersion = {
			...selectedVersion,
			htmlBody,
			isActive:
				selectedVersionActive ||
				currentActiveVersion?.id === selectedVersion?.id,
		}

		const templateVersionsCopy = template.versions
		const versionIndex = templateVersionsCopy.indexOf(selectedVersion)

		if (
			selectedVersionActive ||
			currentActiveVersion?.id === selectedVersion?.id
		) {
			templateVersionsCopy.forEach((version) => {
				version.isActive = false
			})
		}

		if (versionIndex >= 0) {
			templateVersionsCopy[versionIndex] = updatedVersion
		}

		if (currentActiveVersion?.id !== selectedVersion?.id) {
			updatedTemplate.versions = templateVersionsCopy
		} else {
			updatedTemplate.htmlBody = htmlBody
		}

		return { updatedTemplate, updatedVersion }
	}

	const saveTemplate = async () => {
		try {
			if (template.isDefault && user.role !== Roles.superAdmin) {
				delete template.id
				delete template.isDefault
				template.accountID = user.accountID
			}

			const { updatedTemplate, updatedVersion } = getUpdates()

			if (
				template?.id &&
				currentActiveVersion?.id !== selectedVersion?.id
			) {
				await createVersionMutation.mutateAsync({
					documentId: template.id,
					data: {
						...selectedVersion,
						htmlBody,
						isActive: selectedVersionActive,
					},
				})
			}

			// @todo add logic to check for changes in fields before sending this request
			const result = await createTemplateMutation.mutateAsync({
				...updatedTemplate,
			})

			setSelectedVersion(updatedVersion)
			setTemplate(updatedTemplate)

			const { name } = template
			const eventType = template.id
				? eventTypes.DOCUMENT_TEMPLATE_UPDATED
				: eventTypes.DOCUMENT_TEMPLATE_CREATED
			mixpanel.trackEvent(eventType, {
				name,
				...(id && { id }),
			})

			setIdToFetchTemplate(result?.id)
			alert(
				`Document Template ${name} successfully ${template.id ? 'updated' : 'created'}`
			)
			if (result.id !== template.id) {
				// if this is a new template or default override, redirect to the appropriate page
				navigate(
					generatePath(
						`/${navigationLinking.AdminDocumentTemplateEdit}`,
						{ id: result.id }
					)
				)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleVersionDelete = async (version) => {
		try {
			await deleteVersionMutation.mutateAsync({
				documentId: template.id,
				versionId: version.id,
			})
			setVersionsModalOpen(false)
			const updatedTemplate = cloneDeep(template)
			updatedTemplate.versions = updatedTemplate.versions.filter(
				(v) => v.id !== version.id
			)
			setTemplate(updatedTemplate)
			alert(`Version ${version.name} successfully deleted`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleNewVersionSubmit = async (data) => {
		try {
			const version = await createVersionMutation.mutateAsync({
				documentId: template.id,
				data: {
					htmlBody,
					...data,
				},
			})

			const updatedTemplate = cloneDeep(template)
			if (data.isActive) {
				const prevActiveVersionIndex =
					updatedTemplate.versions.findIndex((v) => v.isActive)
				updatedTemplate.versions[prevActiveVersionIndex].isActive =
					false
			}
			updatedTemplate.versions.unshift(version)
			setTemplate(updatedTemplate)
			setSelectedVersion(version)
			setSelectedVersionActive(false)
			setNewVersionModalOpen(false)
			alert(`Version ${version.name} successfully created`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleStatusConfirm = async () => {
		try {
			const requestData = {
				...template,
				status:
					template.status === DRAFT_STATUS
						? PUBLISHED_STATUS
						: DRAFT_STATUS,
			}
			await createTemplateMutation.mutateAsync(requestData)
			setTemplate(requestData)
			closeStatusModal()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleLoanOfficerSelect = async (event, value) => {
		if (value)
			setFetchSiteConfigurationByUrl(value.siteConfigurations[0].url)
		else setSelectedSiteConfig({})
		setLoanOfficer(value)
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleNewVersionClick = () => {
		setNewVersionModalOpen(true)
	}

	const handleNewVersionDialogClose = () => {
		setNewVersionModalOpen(false)
	}

	const handleViewVersionsClick = () => {
		setVersionsModalOpen(true)
	}

	const handleViewVersionsClose = () => {
		setVersionsModalOpen(false)
	}

	const closeStatusModal = () => {
		setStatusModal({
			open: false,
			text: '',
			isSave: false,
		})
	}

	const handleVersionClick = (version) => {
		setSelectedVersion(version)
		setVersionsModalOpen(false)
	}

	const handleVersionChange = (e) => {
		const version = template.versions.find(
			(v) => v.id === e.target.value
		)
		setSelectedVersion(version)
		setSelectedVersionActive(false)
	}

	const handleStatusClick = () => {
		const statusChangeText = `Are you sure you want to ${
			template.status === 'Draft' ? 'publish' : 'unpublish'
		} this document template?`

		setStatusModal({
			open: true,
			text: statusChangeText,
			isSave: false,
		})
	}

	return (
		<Page isFullWidth title="Edit Document Template">
			<ModalYesNo
				modalConfirmation={statusModal}
				modalConfirm={handleStatusConfirm}
				setModalConfirmation={setStatusModal}
			/>
			<ModalNewVersion
				open={newVersionModalOpen}
				onDialogClose={handleNewVersionDialogClose}
				onSubmit={handleNewVersionSubmit}
				loading={createVersionMutation.isPending}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
					Document Templates -{' '}
					{id === 'new' ? 'Create New Template' : template.name}
				</p>
				<div className="flex flex-wrap justify-end my-5">
					{!template?.isDefault && id !== 'new' && (
						<div className="mr-3">
							<LoadingBtn
								disabled={!canSubmit || tab !== 0}
								className="dark:text-white"
								text="Save as new version"
								onClick={handleNewVersionClick}
								fullWidth={false}
								variant="outlined"
								loading={createVersionMutation.isPending}
							/>
						</div>
					)}
					<LoadingBtn
						id="AdminCorporateEditSaveButton"
						disabled={isTemplateOverridden || !canSubmit || tab !== 0}
						loading={createTemplateMutation.isPending}
						text={saveButtonText}
						onClick={saveTemplate}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit" {...a11yProps(0)} />
					<Tab label="Full Preview" {...a11yProps(1)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<div className="flex w-full">
							<div className="w-full">
								<MailMergeSelect combinedOptions className="flex" />
							</div>
							<FormControl className="w-1/3 flex">
								{!template.isDefault && (
									<div className="flex flex-row items-center justify-end my-2">
										<StatusIcon status={template.status} />
										{template.id && (
											<StatusButton
												className="dark:text-white"
												status={template.status}
												onClick={handleStatusClick}
											>
												{template.status === DRAFT_STATUS
													? 'Publish'
													: 'Unpublish'}
											</StatusButton>
										)}
									</div>
								)}

								<FormControlLabel
									control={
										<Switch
											className="mr-2"
											checked={!hidePreview}
											onChange={() =>
												setHidePreview((current) => !current)
											}
											color="primary"
										/>
									}
									label={`HTML Preview is ${!hidePreview ? 'on' : 'off'}`}
									labelPlacement="start"
								/>
							</FormControl>
						</div>

						<Card className="pt-1% pb-5% flex w-full">
							<CardContent
								className={`w-${!hidePreview ? '1/2' : 'full'}`}
							>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<InputLabel>Document Type</InputLabel>
									<Select
										variant="standard"
										value={template.type}
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												type: e.target.value,
											}))
										}
										label="Document Type"
										placeholder="Document Type"
									>
										<MenuItem value="" key="none">
											Select a Document Type
										</MenuItem>
										{documentTemplateTypes
											.filter((t) => t.active)
											.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Name *"
										value={template.name}
										label="Template Name"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												name: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										required
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Description"
										value={template.description}
										label="Description"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												description: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
									/>
								</FormControl>
								{!template.isDefault &&
									template.versions.length > 1 &&
									selectedVersion && (
										<>
											<div className="mb-3 md:flex md:flex-row md:items-center md:justify-between">
												<div className="md:flex md:flex-row md:items-center md:justify-between md:w-3/4">
													<VersionSelector
														value={selectedVersion?.id}
														onChange={handleVersionChange}
														versions={template?.versions}
													/>
													{!selectedVersion.isActive && (
														<FormControl className="mb-3 md:w-1/4">
															<FormControlLabel
																control={
																	<Switch
																		className="mr-2"
																		checked={selectedVersionActive}
																		onChange={() =>
																			setSelectedVersionActive(
																				(current) => !current
																			)
																		}
																		color="primary"
																	/>
																}
																label="Activate"
																labelPlacement="start"
															/>
														</FormControl>
													)}
												</div>
												<div className="mt-3 md:ml-3">
													<Button
														variant="outlined"
														color="primary"
														onClick={handleViewVersionsClick}
													>
														View Versions
													</Button>
												</div>
											</div>
											{currentActiveVersion && (
												<ActiveVersionAlert
													status={
														currentActiveVersion?.id ===
														selectedVersion?.id
															? 'active'
															: 'inactive'
													}
												/>
											)}
										</>
									)}
								<ModalVersionList
									open={versionsModalOpen}
									versions={template.versions}
									onItemClick={handleVersionClick}
									onDeleteClick={handleVersionDelete}
									onDialogClose={handleViewVersionsClose}
								/>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<CodeEditor
										className="w-full sm:px-1"
										height="60vh"
										defaultLanguage="handlebars"
										value={htmlBody}
										loading={isFetchingDocumentTemplate}
										onChange={(value) => setHtmlBody(value)}
									/>
								</FormControl>
							</CardContent>
							{!hidePreview && (
								<CardContent className="w-1/2 border">
									<HandlebarsPreview
										className="w-full h-full min-h-200 dark:bg-white"
										template={htmlBody}
										data={previewData}
									/>
								</CardContent>
							)}
						</Card>
					</TabPanel>

					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="pt-1% pb-5% px-5%">
							<Autocomplete
								disablePortal
								id="loan-officer-autocomplete"
								value={loanOfficer}
								options={loanOfficers.sort(
									(a, b) => -b.firstName.localeCompare(a.firstName)
								)}
								onChange={(event, value) =>
									handleLoanOfficerSelect(event, value)
								}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)
								}}
								renderInput={(params) => (
									<TextField {...params} label="Loan Officer" />
								)}
							/>
							{selectedSiteConfig?.id ? (
								<iframe
									className="w-full h-full min-h-200 mt-5 dark:bg-white"
									title="Template"
									srcDoc={replaceVars(template.htmlBody, {
										...selectedSiteConfig,
										...sampleLoanData,
										Date: todaysDate,
									})}
								/>
							) : (
								<Typography className="pt-5 text-center" variant="h5">
									Select a site to preview
								</Typography>
							)}
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
