import { useState } from 'react'
import NotificationBell from 'assets/images/notificationBell.png'
import { isMobileDevice } from '../services/helper'

export const CardItem = ({
	item,
	index,
	onClick,
	source,
	notificationCount,
}) => {
	const [hoveredId, setHoveredId] = useState(null)

	const isMobile = isMobileDevice()
	let right = 23
	let rightMobile = 18.5

	if (notificationCount === 1) {
		right = 27
		rightMobile = 21.5
	} else if (notificationCount < 10) {
		right = 25
		rightMobile = 20
	}

	return (
		<div
			onClick={onClick}
			className={`${source !== 'ModalTutorial' ? 'cursor-pointer' : 'cursor-default'} flex flex-col items-center align-center justify-center w-32 h-36 m-3.5 sm:w-52 sm:h-52 sm:p-4 shadow-[0px_0px_10px_rgba(48,48,48,0.2)] shadow-gray-700/30 rounded-3xl dark:shadow-gray-400/40 bg-white dark:bg-[#121212] relative`}
			key={`${item.name + index}`}
			onMouseOver={(e) => setHoveredId(e.currentTarget.id)}
			onMouseOut={() => setHoveredId(null)}
			id={index}
		>
			{notificationCount > 0 && (
				<div
					style={
						isMobile
							? { position: 'absolute', top: -8, right: -10 }
							: { position: 'absolute', top: -11, right: -16 }
					}
				>
					<img
						src={NotificationBell}
						style={
							isMobile
								? { width: 60, height: 60 }
								: { width: 75, height: 75 }
						}
					/>
					<div
						style={
							isMobile
								? {
										position: 'absolute',
										top: 12,
										right: rightMobile,
									}
								: { position: 'absolute', top: 16, right }
						}
					>
						<p
							style={{
								color: '#ffffff',
								fontSize: isMobile ? 12 : 14,
								fontWeight: 'bold',
								textAlign: 'center',
							}}
						>
							{notificationCount}
						</p>
					</div>
				</div>
			)}
			<img
				className="w-16 h-16 font-bold sm:w-24 sm:h-24"
				src={
					parseInt(hoveredId?.toString()) === index &&
					!!item.animatedIcon
						? item.animatedIcon
						: item.staticIcon
				}
				alt={item.description}
			/>
			<p className="font-bold font-rubik text-center text-xs sm:text-lg dark:text-slate-200">
				{item.name}
			</p>
			{item.description && (
				<p className="text-center font-rubik text-xs text-slate-500 sm:text-base dark:text-slate-300">
					{item.description}
				</p>
			)}
		</div>
	)
}
