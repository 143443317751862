import React from 'react'
import TaskActionButton from './TaskActionButton'
import ModalLoanTask from './ModalLoanTask'

const LoanTaskActionButton = ({ loanTask, onChange = undefined }) => {
	const { task } = loanTask
	const [modalOpen, setModalOpen] = React.useState(false)

	const handleClick = () => {
		setModalOpen(true)
	}

	const handleSubmit = async () => {
		setModalOpen(false)
		if (onChange) {
			await onChange()
		}
	}

	const handleClose = () => {
		setModalOpen(false)
	}

	return (
		<>
			<ModalLoanTask
				open={modalOpen}
				loanTask={loanTask}
				onSubmit={handleSubmit}
				onCancel={handleClose}
			/>
			<TaskActionButton task={task} onClick={handleClick} />
		</>
	)
}

export default LoanTaskActionButton
