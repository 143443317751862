import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import { useAppContext } from '../../components/AppContext'
import { PricingTable } from '../../components/pricing/PricingTable'
import Page from '../../components/Page'
import { navigationLinking } from '../../services/navigation'
import { getTheme } from '../../config'

const theme = getTheme()

export default function LoanPricingResults() {
	const { state } = useAppContext()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const rates = ventiState.get(theme.storageKeys.publicRates) || []

	const { siteConfig } = state

	const [tableState, tableSetState] = useState({
		tableHeader: '',
		marginTop: 12.5,
	})

	useEffect(() => {
		if (siteConfig !== null) {
			if (siteConfig.entityType === 0)
				navigate(`/${navigationLinking.Portal}`)
		}
	}, [siteConfig])

	return (
		<Page
			page="loan-calculator"
			title="Loan Calculator"
			isFullWidth={true}
		>
			<div className="pl-5 pr-5 pb-10 h-screen">
				<p className="text-2xl font-rubik font-bold mr-4 mb-10 mt-2 dark:text-white">
					Loan Pricing
				</p>
				<PricingTable rates={rates} tableState={tableState} />
			</div>
		</Page>
	)
}
