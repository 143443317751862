import React from 'react'
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material'

const VersionSelector = ({
	versions,
	fullWidth = false,
	...rest
}) => {
	return (
		<div
			className={`mb-3 ${fullWidth ? 'w-full' : 'md:w-3/4 md:mr-3'}`}
		>
			<FormControl fullWidth>
				<InputLabel>Version</InputLabel>
				<Select variant="standard" label="Version" {...rest}>
					{versions?.length > 0 &&
						versions.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
								{option.isActive ? ' (Active)' : ''}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	)
}

export default VersionSelector
