import React from 'react'
import { states } from '../services/helper'
import { chunk } from 'lodash'

const StateLicensesList = ({ licenses, grid }) => {
	const licensesChunked = grid ? chunk(licenses, 20) : licenses
	return (
		<div className="sm:flex sm:flex-wrap gap-10">
			{licensesChunked.map((licences, index) => (
				<ul>
					{licences.map((abbr) => {
						const state = states.find((s) => s.value === abbr)
						return (
							<li key={abbr} className="my-1">
								{state?.label || abbr}
							</li>
						)
					})}
				</ul>
			))}
		</div>
	)
}

export default StateLicensesList
