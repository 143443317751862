import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'

const DeviceRowExpanded = ({ data: device }) => {
	const { name, serialNumber, type, status } = device
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Serial #" value={serialNumber} />
			<RowExpandedProperty label="Device Type" value={type} />
			<RowExpandedProperty label="Status" value={status} />
		</div>
	)
}

export default DeviceRowExpanded
