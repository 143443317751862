import React from 'react'

const TaskTypeLabel = ({ type, ...rest }) => {
	const labelMap = {
		Document: 'Document',
		Field: 'Field',
		Signature: 'E-Signature',
		EDisclosure: 'Disclosure',
		EConsent: 'Electronic Consent',
		VerificationOfAssets: 'VOA',
		VerificationOfIncome: 'VOI',
		VerificationOfEmployment: 'VOE',
		VerificationOfCredit: 'VOC',
		Payment: 'Payment',
		VerificationOfIncomeAndEmployment: 'VOIE',
		VerificationOfTaxes: 'IRS ID.ME',
	}
	return <span {...rest}>{labelMap[type] || 'Unknown'}</span>
}

export default TaskTypeLabel
