import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { getTheme } from '../../config'
import { isDarkMode } from '../../services/helper'
import { InputLabel } from '@mui/material'

const theme = getTheme()

export const Place = ({
	id,
	onPress,
	onChangeText,
	inputValue,
	placeholder,
	width,
}) => {
	return (
		<div>
			{inputValue !== '' && typeof inputValue !== 'undefined' && (
				<InputLabel>Address Lookup</InputLabel>
			)}
			<GooglePlacesAutocomplete
				id={id}
				apiKey={theme.google.apiKey}
				apiOptions={{ language: 'en', region: 'us' }}
				autoFocus={true}
				debounce={200}
				selectProps={{
					placeholder:
						placeholder || 'Address lookup. Start typing address...',
					onChange: onPress,
					onInputChange: onChangeText,
					value: inputValue,
					styles: {
						input: (provided) => ({
							...provided,
							width: width < 640 ? 216 : 344,
							borderTopWidth: 0,
							color: isDarkMode() ? 'white' : 'black',
							backgroundColor: isDarkMode() ? '#1e293b' : '#fff',
						}),
						option: (provided) => ({
							...provided,
							color: isDarkMode() ? '#000' : '#000',
						}),
						singleValue: (provided) => ({
							...provided,
							color: isDarkMode() ? 'white' : 'black',
						}),
						control: (provided) => ({
							...provided,
							borderTopWidth: 0,
							borderLeftWidth: 0,
							borderRightWidth: 0,
							borderRadius: 0,
							boxShadow: 'none',
							color: 'blue',
							backgroundColor: isDarkMode() ? '#1e293b' : '#fff',
						}),
						loadingIndicator: (provided) => ({
							...provided,
							display: 'none',
						}),
						indicatorSeparator: (provided) => ({
							...provided,
							display: 'none',
						}),
						valueContainer: (provided) => ({
							...provided,
							padding: '2px 0',
							color: 'blue',
							backgroundColor: isDarkMode() ? '#1e293b' : '#fff',
						}),
						placeholder: (base) => ({
							...base,
							zIndex: 1000,
							color: !isDarkMode() ? '#1e293b' : '#fff',
						}),
					},
				}}
			/>
		</div>
	)
}
