import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'

const LoanOfficerExpandedRow = ({ data: loanOfficer }) => {
	const {
		firstName,
		lastName,
		email,
		title,
		corporateName,
		branchName,
		url,
		role,
	} = loanOfficer
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="First Name" value={firstName} />
			<RowExpandedProperty label="Last Name" value={lastName} />
			<RowExpandedProperty label="Email" value={email} />
			<RowExpandedProperty label="Title" value={title} />
			<RowExpandedProperty label="Brand" value={corporateName} />
			<RowExpandedProperty label="Branch" value={branchName} />
			<RowExpandedProperty label="URL" value={url} />
			<RowExpandedProperty label="Role" value={role} />
		</div>
	)
}
export default LoanOfficerExpandedRow
