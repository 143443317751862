import React, { useState } from 'react'
import ImageIcon from '@mui/icons-material/Image'

const ImagePlaceholderPreview = ({ file }) => {
	const [isUrlError, setIsUrlError] = useState(false)

	const handleError = () => {
		setIsUrlError(true)
	}

	return (
		<>
			{!isUrlError ? (
				<a href={file.file.url} target="_blank" rel="noreferrer">
					<img
						src={file.file.url}
						alt={file.name || file.file.fileName}
						className="max-w-7 max-h-7 border border-slate-800 mr-3"
						onError={handleError}
					/>
				</a>
			) : (
				<ImageIcon />
			)}
		</>
	)
}

export default ImagePlaceholderPreview
