import React, { useState } from 'react'
import { DialogContent, Dialog } from '@mui/material'
import { isDarkMode } from '../../services/helper'
import { CardItem } from '../CardItem'
import { useAppContext } from '../AppContext'
import { useNavigate } from 'react-router-dom'

export const ModalWorkflowSelector = ({
	actions = [],
	setActions,
}) => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const navigate = useNavigate()

	const handleAppSelection = (action) => {
		setActions([])
		setTimeout(() => {
			navigate(`/${action.route}`, { state: action } || {})
		}, 500)
	}

	return (
		<Dialog
			open={!!actions.length}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				<div>
					<img
						src={
							isDarkMode() && siteConfig?.darkModeLogoUrl
								? siteConfig.darkModeLogoUrl
								: siteConfig?.logoUrl
						}
						alt={`${siteConfig?.companyName} Logo`}
					/>
				</div>
				<div className="text-center mt-5 mb-4 font-bold text-lg">
					Please select your preferred application...
				</div>
				<div className="w-full flex">
					{actions.map((action, index) => {
						return (
							<CardItem
								item={action}
								key={index}
								index={index}
								onClick={() => handleAppSelection(action)}
								source={'Modal'}
							/>
						)
					})}
				</div>
			</DialogContent>
		</Dialog>
	)
}
