import Page from '../../../components/Page'

export default function Amortization() {
	return (
		<Page page="app-loan-officer-left" title="AmortizationView">
			<h1 className="text-3xl font-bold underline h-96">
				Amortization
			</h1>
		</Page>
	)
}
