import React from 'react'

const RowExpandedProperty = ({
	label,
	value,
	className = undefined,
}) => {
	return (
		<div className={className}>
			<p className="font-rubik text-sm text-slate-400 font-bold mb-1">
				{label}
			</p>
			<p className="whitespace-normal font-rubik text-slate-800 font-bold mb-3 text-ellipsis overflow-hidden dark:text-white">
				{value}
			</p>
		</div>
	)
}

export default RowExpandedProperty
