import React, { useMemo } from 'react'
import DocumentBucketAutocompleteField from './documentBucketAutocomplete/DocumentBucketAutocompleteField'
import { TextField } from './index'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { Tooltip } from '@mui/material'
import useUser from '../../hooks/useUser'

const TaskLosTargetField = ({
	type,
	loanId = undefined,
	disabled,
	...rest
}) => {
	const { isAdmin } = useUser()

	const requiredDocumentBucketTypes = [
		'Document',
		'VerificationOfAssets',
		'VerificationOfIncome',
		'VerificationOfEmployment',
		'VerificationOfIncomeAndEmployment',
	]

	const showDocumentBuckets =
		requiredDocumentBucketTypes.includes(type)

	const helperText = showDocumentBuckets
		? 'What bucket would you like to store the documents in?'
		: 'What field do you want this answer mapped to? (text fields only)'

	const [manualEntry, setManualEntry] = React.useState(false)

	const handleAddToggleClick = () => {
		setManualEntry(!manualEntry)
	}

	const showAutocomplete = useMemo(() => {
		return showDocumentBuckets && !manualEntry
	}, [type, manualEntry])

	return (
		<div className="flex flex-row items-start">
			{showAutocomplete ? (
				<DocumentBucketAutocompleteField
					loanId={loanId}
					helperText={helperText}
					{...rest}
				/>
			) : (
				<TextField
					label={
						type === 'Document' ? 'LOS Bucket Name' : 'LOS Field ID'
					}
					helperText={
						type === 'Field'
							? '*Not available for questions'
							: helperText
					}
					disabled={type === 'Field' ? true : disabled}
					{...rest}
				/>
			)}
			{type === 'Document' && isAdmin && (
				<div className="mt-2">
					<Tooltip title={manualEntry ? 'Search' : 'Add New'}>
						<IconButton onClick={handleAddToggleClick}>
							{manualEntry ? <SearchIcon /> : <AddIcon />}
						</IconButton>
					</Tooltip>
				</div>
			)}
		</div>
	)
}

export default TaskLosTargetField
