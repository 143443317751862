import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import userAvatar from 'assets/images/landingPages/user-avatar.png'
import { HeaderOpen } from '../../components/HeaderOpen'
import Page from '../../components/Page'
import Typography from '@mui/material/Typography'
import { getTheme } from '../../config'
import { Link } from '@mui/material'
import { navigationLinking } from '../../services/navigation'
import AppLinks from '../../components/landing/CondensedAppLinks'
import parse from 'html-react-parser'
import { Footer } from '../../components/Footer'
import { useAppContext } from '../../components/AppContext'

const siteTheme = getTheme()

export const LoanOfficerCondensedView = ({
	siteConfig = {},
	user = {},
	appLinks,
}) => {
	const navigate = useNavigate()
	const { state } = useAppContext()
	const { socialLinks, legalLinks } = state

	const [appLinksTitle, setAppLinksTitle] = useState('')

	useEffect(() => {
		if (siteConfig.entityType < 4)
			setAppLinksTitle(
				appLinks.length > 1
					? 'How would you like to apply?'
					: 'Apply Now'
			)
		else setAppLinksTitle('What would you like to do today?')
	}, [siteConfig])

	return (
		<Page page="lo-landing-condensed" noDarkMode isFullWidth>
			<HeaderOpen
				siteConfig={siteConfig}
				user={user}
				landing
				isFullWidth
			/>
			<div className="flex flex-col items-center w-full">
				<div className="flex flex-row items-center w-full relative">
					<img
						className="w-full"
						src={siteConfig?.bannerUrl}
						alt="Banner"
					/>
					<img
						className="w-20 h-20 md:w-48 md:h-48 bottom-2 md:bottom-6 left-1/2 -translate-x-1/2 absolute rounded-full object-cover"
						alt="Avatar"
						src={
							siteConfig?.profilePhotoUrl
								? siteConfig.profilePhotoUrl
								: userAvatar
						}
					/>
				</div>
				<p className="font-rubik text-center text-2xl sm:text-3xl mb-5 mt-5">
					{siteConfig?.name}
				</p>
				<div className="flex items-center">
					<div className="flex justify-center flex-wrap mt-5 mb-1 flex-col md:flex-row mb-12 md:mb-0">
						{!!siteConfig?.phone && (
							<div className="flex mr-0 md:mr-12 text-center">
								<p className="font-rubik text-lg">
									mobile:&nbsp;&nbsp;
								</p>
								<a
									target="_blank"
									href={`tel:${siteConfig?.phone}`}
									rel="noopener noreferrer"
									className="hover:underline"
								>
									<Typography
										color={'primary'}
										className="font-rubik font-light text-lg dark:text-white font-bold"
									>
										{siteConfig?.phone}
									</Typography>
								</a>
							</div>
						)}
						{!!siteConfig?.fax && (
							<div className="flex mr-0 md:mr-12 text-center">
								<p className="font-rubik text-lg">
									mobile:&nbsp;&nbsp;
								</p>
								<Typography
									color={'primary'}
									className="font-rubik font-light text-lg dark:text-white"
								>
									{siteConfig?.fax}
								</Typography>
							</div>
						)}
						{!!siteConfig?.tollFree && (
							<div className="flex mr-0 md:mr-12">
								<p className="font-rubik text-lg">
									office:&nbsp;&nbsp;
								</p>
								<a
									target="_blank"
									href={`tel:${siteConfig.tollFree}`}
									rel="noopener noreferrer"
									className="hover:underline"
								>
									<Typography
										color={'primary'}
										className="font-rubik font-light text-lg dark:text-white"
									>
										{siteConfig.tollFree}
									</Typography>
								</a>
							</div>
						)}
						{!!siteConfig?.email && (
							<div className="flex mr-0 md:ml-12 text-center">
								<p className="font-rubik text-lg">
									email:&nbsp;&nbsp;
								</p>
								<a
									target="_blank"
									href={`mailto:${siteConfig.email}`}
									rel="noopener noreferrer"
									className="hover:underline"
								>
									<Typography
										color={'primary'}
										className="font-rubik font-light text-lg dark:text-white"
									>
										{siteConfig.email}
									</Typography>
								</a>
							</div>
						)}
						{!!siteConfig?.nmlsid && (
							<div className="flex mr-0 md:ml-12 text-center">
								<p className="font-rubik text-lg">
									NMLS #:&nbsp;&nbsp;
								</p>
								<Typography
									color={'primary'}
									className="font-rubik font-light text-lg dark:text-white"
								>
									{siteConfig?.nmlsid}
								</Typography>
							</div>
						)}
					</div>
				</div>
				<div className={`flex flex-col mt-12`}>
					<p className="text-center text-lg sm:text-xl font-bold mb-4 dark:text-white">
						{appLinksTitle}
					</p>

					<AppLinks
						siteConfig={siteConfig}
						links={appLinks}
						variant={'shadow'}
					/>

					<div className="mt-4 text-center">
						<Typography>
							<span className="dark:text-white">
								Already applied? Click here to&nbsp;
							</span>
							<Link
								className="text-blue-500 cursor-pointer"
								onClick={() => {
									navigate(`/${navigationLinking.SignIn}`)
								}}
								rel="noopener noreferrer"
								underline="hover"
								style={{
									color: siteTheme.siteConfig.color.primary,
									marginTop: 16,
								}}
							>
								<span>sign in</span>
							</Link>
						</Typography>
					</div>
				</div>
				{siteConfig?.introduction?.length > 0 && (
					<div
						className={`flex flex-col justify-between items-center mt-20 px-12`}
					>
						<p className="text-xl md:text-2xl text-center font-rubik font-bold mt-6 pb-10">
							{siteConfig?.introductionTitle
								? siteConfig?.introductionTitle
								: `About Me`}
						</p>
						<div className="mx-auto text-sm md:text-base font-montserrat text-justify w-full md:w-2/3">
							{parse(
								siteConfig?.introduction?.replaceAll('\n', '<br/>') ||
									''
							)}
						</div>
					</div>
				)}
			</div>
			<Footer
				siteConfig={siteConfig}
				socialLinks={socialLinks}
				legalLinks={legalLinks}
				isFullWidth
			/>
		</Page>
	)
}
