import React from 'react'
import { FormHelperText, FormGroup } from '@mui/material'
import Checkbox from './Checkbox'
import FormLabel from '@mui/material/FormLabel'
import useFormField from '../../hooks/useFormField'

const CheckboxGroup = ({
	variant = 'standard',
	name,
	label,
	children,
	errors,
	control,
	defaultValue,
	radioGroupProps,
	className,
	checkboxesClassName,
	sx,
	items,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	return (
		<FormGroup
			variant={variant}
			className={className}
			sx={sx}
			error={!!error}
		>
			<FormLabel>{label}</FormLabel>
			<div className={checkboxesClassName}>
				{items.map((item, i) => (
					<Checkbox
						key={item.key || `${i}-checkbox-group`}
						name={item.value || item}
						label={item.label || item}
						control={control}
						{...rest}
					/>
				))}
			</div>
			{error && <FormHelperText>{error.message}</FormHelperText>}
		</FormGroup>
	)
}

export default CheckboxGroup
