import { Navigate, useLocation } from 'react-router-dom'
import { useAppContext } from '../components/AppContext'
import { useAppContextActions } from '../components/AppContext/AppHooks'
import { navigationLinking } from '../services/navigation'
import { createSignalRContext } from 'react-signalr/signalr'
import { host } from '../services/client'
import { useAlert } from '../hooks'
import useUser from '../hooks/useUser'
import { useEffect } from 'react'
import { LoadingPage } from '../components/LoadingPage'

const SignalRContext = createSignalRContext()

export default function PrivateRoute({ children, userRoles = [] }) {
	const { pathname, search } = useLocation()
	const { state } = useAppContext()
	const { authToken, redirectUrl } = state
	const { applyImpersonationRequest } = useAppContextActions()
	const { alert } = useAlert()
	const { initUser, user } = useUser()

	SignalRContext.useSignalREffect(
		'ReceiveImpersonationDetails',
		(message) => applyImpersonationRequest(message)
	)
	const ACCESS_TOKEN_PARAM = 'access_token'
	const searchParams = new URLSearchParams(search)
	const queryStringAuthToken = searchParams.get(ACCESS_TOKEN_PARAM)
	const queryStringAuthTokenExpiration =
		searchParams.get('expires_in')

	useEffect(() => {
		if (queryStringAuthToken) {
			const loadUserFromToken = async () => {
				await initUser(
					{
						[ACCESS_TOKEN_PARAM]: queryStringAuthToken,
						expires_in: queryStringAuthTokenExpiration || 3600,
					},
					false
				)
				searchParams.delete(ACCESS_TOKEN_PARAM)
			}

			loadUserFromToken()
		}
	}, [queryStringAuthToken])

	if (!authToken && !queryStringAuthToken && !user) {
		return (
			<Navigate
				to={`/${navigationLinking.SignIn}`}
				state={{ from: `${pathname}${search}` }}
			/>
		)
	}

	if (!user) {
		// wait for user to be set from url param
		return <LoadingPage />
	}

	const authorized =
		userRoles?.includes(user?.role) ||
		(Array.isArray(userRoles) && userRoles.length === 0)

	if (!authorized) {
		alert('You are not authorized to view this page.', {
			severity: 'error',
		})
	}

	if (queryStringAuthToken) {
		return (
			<Navigate
				to={pathname}
				state={{ search: searchParams.toString() }}
				replace
			/>
		)
	}

	return authorized ? (
		<SignalRContext.Provider
			connectEnabled={true}
			accessTokenFactory={() => state.authToken}
			dependencies={[state.authToken]}
			url={`${host}/hubs/impersonation`}
		>
			{children}
		</SignalRContext.Provider>
	) : (
		<Navigate
			to={`/${redirectUrl}`}
			state={{ from: `${pathname}${search}` }}
		/>
	)
}
