import React, { useState, useEffect } from 'react'
import {
	Dialog,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ModalError } from './ModalError'
import { ModalYesNo } from './ModalYesNo'
import { Loading } from '../Loading'
import { Button } from '../Button'

const errorMsg =
	'The file is currently locked by your loan officer or processor.'

export const ModalDocumentType = ({
	visible,
	uploadDocumentType,
	setUploadDocumentType,
	tasksToComplete = [],
	confirmModal,
	cancelModal,
	loadingDocument,
	errorModalVisible,
	setErrorModalVisible,
	uploadDocumentSuccess,
	setUploadDocumentSuccess,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: 'The document was uploaded successfully!',
		isOkButton: true,
	})

	useEffect(() => {
		uploadDocumentSuccess &&
			setModalConfirmation({ ...modalConfirmation, open: true })
	}, [uploadDocumentSuccess])

	const closeErrorModal = () => {
		setErrorModalVisible(false)
		cancelModal()
	}

	return (
		<Dialog open={visible} onClose={cancelModal}>
			<>
				<ModalError
					visible={errorModalVisible}
					setVisible={closeErrorModal}
					message={errorMsg}
				/>
				<ModalYesNo
					modalConfirmation={modalConfirmation}
					setModalConfirmation={() => {
						setModalConfirmation({
							...modalConfirmation,
							open: false,
						})
						setUploadDocumentSuccess(false)
						cancelModal()
					}}
				/>
				<IconButton
					aria-label="close"
					onClick={cancelModal}
					sx={{ position: 'absolute', right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>

				<div
					className={`${fullScreen ? 'flex-col items-center pt-10 pb-4 px-3' : 'flex-col py-8 px-10 justify-center'} flex`}
				>
					{loadingDocument ? (
						<>
							<p className="text-lg font-bold text-center">
								Please be patient while the document is processed
							</p>
							<div className="flex self-center justify-center w-64 md:w-96 mt-5">
								<Loading size="small" />
							</div>
						</>
					) : (
						<>
							<p className="text-lg text-slate-500 font-bold text-center">
								What type of document is this?
							</p>
							<p className="text-sm text-slate-500 px-3 py-3 text-center">
								Please choose from the following options...
							</p>
							<RadioGroup
								name="radio-buttons-group"
								className="mt-2"
								value={uploadDocumentType}
								defaultValue={'other'}
								sx={{ width: 220 }}
							>
								{tasksToComplete.map((group, index) => (
									<FormControlLabel
										key={`${group.taskName + index}`}
										value={group.taskName}
										control={
											<Radio
												size="small"
												onChange={(e) =>
													setUploadDocumentType(e.target.value)
												}
											/>
										}
										label={group.taskName}
									/>
								))}
							</RadioGroup>
							<RadioGroup
								name="radio-buttons-group"
								value={
									tasksToComplete.length === 0
										? 'other'
										: uploadDocumentType
								}
								defaultValue={'other'}
								sx={{ width: 220, marginBottom: 2 }}
							>
								<FormControlLabel
									value="other"
									control={
										<Radio
											size="small"
											onChange={(e) =>
												setUploadDocumentType(e.target.value)
											}
										/>
									}
									label="Other / Not Listed"
								/>
							</RadioGroup>
							<div
								className={`${fullScreen ? 'flex-col items-center' : 'flex-row'} flex`}
							>
								<Button
									id="Cancel"
									text={'Cancel'}
									onClick={cancelModal}
									style={
										fullScreen
											? { width: 220, marginBottom: 12 }
											: { width: 200, marginRight: 12 }
									}
									variant="outlined"
								/>
								<Button
									id="ModalHasIncompleteTasksNoButton"
									text={'Confirm'}
									onClick={confirmModal}
									style={
										fullScreen
											? { width: 220, marginBottom: 12 }
											: { width: 200 }
									}
								/>
							</div>
						</>
					)}
				</div>
			</>
		</Dialog>
	)
}
