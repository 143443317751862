import React, { useEffect, useState } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { useVenti } from 'venti'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'

import withAuth from '../../../components/withAuth'
import DataTable from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import PartnersExpandedRow from './PartnersExpandedRow'
import Page from '../../../components/Page'

import {
	deleteUser,
	getBranches,
	getCorporations,
	getPartners,
	undeleteUser,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'
import { sortByField } from '../../../services/utils'

import { useMixpanel } from '../../../hooks/useMixpanel'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'

const theme = getTheme()

const AdminPartners = () => {
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [partnersdata, setPartnersData] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()
	const [searchText, setSearchText] = useState('')
	const [filteredData, setFilteredData] = useState([])
	const [corporations, setCorporations] = useState([])
	const [branches, setBranches] = useState([])
	const [filters, setFilters] = useState({
		active: 'active',
		brand: 'all',
		branch: 'all',
	})

	const columns = [
		{
			name: 'First Name',
			selector: (row) => row.firstName,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: (row) => row.lastName,
			sortable: true,
		},
		{
			name: 'Title',
			selector: (row) => row.title,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Brand',
			selector: (row) => row.corporateName,
			sortable: true,
		},
		{
			name: 'Branch',
			selector: (row) => row.branchName,
			sortable: true,
		},
		{
			name: 'URL',
			selector: (row) => row.url,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Created On',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error: errorFetchingPartners,
		data: partnersResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.partners],
		queryFn: () => getPartners(true),
	})

	const {
		error: errorFetchingCorporations,
		data: corporationsResult,
	} = useQuery({
		queryKey: [queryKeys.corporations],
		queryFn: () => getCorporations(),
		retry: 2,
	})

	const { error: errorFetchingBranches, data: branchesResult } =
		useQuery({
			queryKey: [queryKeys.branches],
			queryFn: () => getBranches(),
			retry: 2,
		})

	useEffect(() => {
		if (errorFetchingCorporations) {
			alert('There was a problem loading the brands', {
				severity: 'error',
			})
		}
	}, [errorFetchingCorporations])

	useEffect(() => {
		if (errorFetchingBranches) {
			alert('There was a problem loading the branches', {
				severity: 'error',
			})
		}
	}, [errorFetchingBranches])

	useEffect(() => {
		if (errorFetchingPartners) {
			alert('There was a problem loading the partners', {
				severity: 'error',
			})
		}
	}, [errorFetchingPartners])

	useEffect(() => {
		if (!corporationsResult) return

		const sortedCorporations = sortByField(corporationsResult?.rows)
		setCorporations(sortedCorporations)
	}, [corporationsResult])

	useEffect(() => {
		if (!branchesResult) return

		const sortedBranches = sortByField(branchesResult?.rows)
		setBranches(sortedBranches)
	}, [branchesResult])

	useEffect(() => {
		if (partnersResult) {
			const partners = []
			partnersResult?.rows.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					partners.push({
						...item,
						id: item.id,
						url: site.url,
						siteConfigurationId: site.id,
					})
				})
			})
			setPartnersData(partners)
		}
	}, [partnersResult])

	useEffect(() => {
		if (
			partnersdata &&
			(searchText !== '' ||
				filters.brand !== 'all' ||
				filters.active !== 'all')
		) {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? partnersdata
				: partnersdata.filter(
						(d) =>
							d.firstName
								?.toLowerCase()
								.includes(lowercasedSearchText) ||
							d.lastName
								?.toLowerCase()
								.includes(lowercasedSearchText) ||
							`${d.firstName} ${d.lastName}`
								.toLowerCase()
								.includes(lowercasedSearchText) ||
							d.email?.toLowerCase().includes(lowercasedSearchText) ||
							d.title?.toLowerCase().includes(lowercasedSearchText)
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (
					filters.brand !== 'all' &&
					d.corporateID !== filters.brand
				)
					return false
				if (filters.branch !== 'all' && d.branchID !== filters.branch)
					return false
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return true
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(partnersdata)
		}
	}, [partnersdata, searchText, filters])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.partners, queryKeys.partner],
		})
		await refetch()
	}

	const handleMutationSuccess = (
		eventType,
		modalSetter,
		activeRow,
		successMessage
	) => {
		const { id, name } = activeRow
		mixpanel.trackEvent(eventType, {
			id,
			name,
		})
		modalSetter(false)
		alert(`Partner ${name} successfully ${successMessage}`)
	}

	const removePartnerMutation = useMutation({
		mutationFn: (id) => deleteUser(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.PARTNER_DELETED,
				setRemoveModalVisible,
				rowToRemove,
				'removed'
			)
			setRowToRemove(null)
		},
	})

	const restorePartnerMutation = useMutation({
		mutationFn: (id) => undeleteUser(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.PARTNER_RESTORED,
				setRestoreModalVisible,
				rowToRestore,
				'restored'
			)
			setRowToRemove(null)
		},
	})

	const removePartner = async () => {
		try {
			if (rowToRemove?.id)
				await removePartnerMutation.mutateAsync(rowToRemove?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const restorePartner = async () => {
		try {
			if (rowToRestore?.id)
				await restorePartnerMutation.mutateAsync(rowToRestore?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminPartnerEdit}`, {
				id: 'new',
			})
		)
	}

	const onRowClicked = async (row) => {
		await ventiState.set(
			theme.storageKeys.editingPartnerSiteConfigurationId,
			row.siteConfigurationId
		)
		navigate(
			generatePath(`/${navigationLinking.AdminPartnerEdit}`, {
				id: row.id,
			})
		)
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove({
			...row,
			name: `${row.firstName} ${row.lastName}`,
		})
	}
	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setRowToRestore({
			...row,
			name: `${row.firstName} ${row.lastName}`,
		})
	}
	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page title="Partners" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removePartner}
					row={rowToRemove}
					loading={removePartnerMutation.isPending}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restorePartner}
					row={rowToRestore}
					loading={restorePartnerMutation.isPending}
				/>
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title="Partners"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					searchFilters={
						<>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Brand</InputLabel>
								<Select
									value={filters.brand}
									onChange={(e) => handleFilterChange(e, 'brand')}
								>
									<MenuItem value="all">All</MenuItem>
									{corporations?.map((corp, index) => (
										<MenuItem key={corp.id} value={corp.id}>
											{corp.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Branch</InputLabel>
								<Select
									value={filters.branch}
									onChange={(e) => handleFilterChange(e, 'branch')}
								>
									<MenuItem value="all">All</MenuItem>
									{branches?.map((branch, index) => (
										<MenuItem key={branch.id} value={branch.id}>
											{branch.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
						</>
					}
					exportEnabled={true}
					fixedHeader={true}
					expandableRowsComponent={PartnersExpandedRow}
					actionItems={actionItems}
					addNewBtn={{
						text: 'Add Partner',
						onClick: onAddClick,
					}}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminPartners)
