import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'
import { Check } from '@mui/icons-material'

const TemplateExpandedRow = ({ data: documentTemplate }) => {
	const { name, type, description, status, isDefault } =
		documentTemplate

	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Type" value={type} />
			<RowExpandedProperty
				label="Description"
				value={description ? description : '-'}
			/>
			<RowExpandedProperty label="Status" value={status} />
			<RowExpandedProperty
				label="Default"
				value={isDefault ? <Check /> : '-'}
			/>
		</div>
	)
}

export default TemplateExpandedRow
