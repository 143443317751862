import moment from 'moment'

export const fillRecursiveQuestions = (questions) => {
	const recursiveQuestions = []
	let indexModifier = 0
	questions.forEach((q, index) => {
		if (q.type !== 'recursive') return

		let newIndex = parseInt(index + indexModifier)

		const newQuestions = {
			index: newIndex,
			questions: [],
		}

		const existingQuestions = q.questions

		for (let i = 0; i < q.maxIterations; i++) {
			newQuestions.questions.push(
				existingQuestions.map((eq, index) => {
					let target = `${eq.target}${!eq.incrementedTarget ? i : i + 1}`
					// its the last question in the list so use the target set in the recursive top level or the next iteration if needed
					if (existingQuestions.length - 1 === index) {
						if (q.maxIterations - 1 === i) target = q.target
						else target = `${eq.target}${i + 1}`
					}

					let heading = eq.heading || eq.label || ''

					const newQ = {
						...eq,
						heading: `${heading} ${i > 0 && heading.length ? `#${i + 1}` : ''}`,
						question:
							i > 0 && index === 0 ? q.altQuestion : eq.question,
						name: `${eq.name} ${i}`,
						qId: `${eq.qId}${i}`,
						target,
					}

					if (q.title) newQ.title = `${q.title} ${i + 1}`

					// Group questions
					if (eq.type === 'group') {
						newQ.items = eq.items.map((item) => {
							if (item.type === 'row')
								return {
									...item,
									items: item.items.map((child) => ({
										...child,
										name: `${child.name} ${i}`,
										qId: `${child.qId}${i}`,
										fieldId: `${child.fieldId}${i}`,
										target,
									})),
								}
							else
								return {
									...item,
									name: `${item.name} ${i}`,
									qId: `${item.qId}${i}`,
									fieldId: `${item.fieldId}${i}`,
									target,
								}
						})
					} else {
						if (eq.options) {
							newQ.options = eq.options.map((opt) => {
								let newTarget = opt.target
								if (opt.recursiveTarget)
									newTarget = `${newTarget}${i}`
								else if (opt.incrementedTarget) {
									if (q.maxIterations - 1 === i) newTarget = q.target
									else newTarget += i + 1
								}

								const newOpt = { ...opt }
								if (newTarget) {
									newOpt.target = newTarget
								}
								return newOpt
							})
						}

						newQ.fieldId = `${eq.fieldId}${i}`
					}

					return newQ
				})
			)
		}

		indexModifier = newQuestions.questions.length
		recursiveQuestions.push(newQuestions)
	})

	recursiveQuestions.forEach((rq) => {
		let index = rq.index
		rq.questions.forEach((q) => {
			q.forEach((finalQ) =>
				questions.splice(index++, index === rq.index ? 1 : 0, finalQ)
			)
		})
	})

	return questions.filter((q) => q.type !== 'recursive')
}

/**
 * @description Test conditions on data object
 * @param condition
 * @param data
 * @returns {boolean}
 */
export const testConditions = (condition = '', data = {}) => {
	let statementParts
	// eslint-disable-next-line default-case
	switch (true) {
		case condition.includes('==='):
			statementParts = condition.split('===').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (!data.hasOwnProperty(`${varName}${i}`)) break
					if (data[`${varName}${i}`] === statementParts[1])
						return true
				}
			} else if (
				data.hasOwnProperty(statementParts[0]) &&
				data[statementParts[0]] === statementParts[1]
			)
				return true
			break
		case condition.includes('!=='):
			statementParts = condition.split('!==').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (
						!data.hasOwnProperty(`${varName}${i}`) ||
						data[`${varName}${i}`] !== statementParts[1]
					)
						return true
				}
			} else if (
				!data.hasOwnProperty(statementParts[0]) ||
				data[statementParts[0]] !== statementParts[1]
			)
				return true
			break
		case condition.includes('>='):
			statementParts = condition.split('>=').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (!data.hasOwnProperty(`${varName}${i}`)) break
					if (data[`${varName}${i}`] >= statementParts[1]) return true
				}
			} else if (
				data.hasOwnProperty(statementParts[0]) &&
				data[statementParts[0]] >= statementParts[1]
			)
				return true
			break
		case condition.includes('>'):
			statementParts = condition.split('>').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (!data.hasOwnProperty(`${varName}${i}`)) break
					if (data[`${varName}${i}`] > statementParts[1]) return true
				}
			} else if (
				data.hasOwnProperty(statementParts[0]) &&
				data[statementParts[0]] > statementParts[1]
			)
				return true
			break
		case condition.includes('<='):
			statementParts = condition.split('<=').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (!data.hasOwnProperty(`${varName}${i}`)) break
					if (data[`${varName}${i}`] <= statementParts[1]) return true
				}
			} else if (
				data.hasOwnProperty(statementParts[0]) &&
				data[statementParts[0]] <= statementParts[1]
			)
				return true
			break
		case condition.includes('<'):
			statementParts = condition.split('<').map((s) => s.trim())
			if (statementParts[0].slice(-2) === '[]') {
				let varName = statementParts[0].slice(0, -2)
				for (let i = 0; i < 5; i++) {
					if (!data.hasOwnProperty(`${varName}${i}`)) break
					if (data[`${varName}${i}`] < statementParts[1]) return true
				}
			} else if (
				data.hasOwnProperty(statementParts[0]) &&
				data[statementParts[0]] < statementParts[1]
			)
				return true
			break
		case condition.includes(' at least '):
			statementParts = condition
				.split(' at least ')
				.map((s) => s.trim())
			const m = moment(data[statementParts[0]], 'MM/DD/YYYY')
			const now = moment()
			const comparisonParts = statementParts[1].split(' ')
			const diff = now.diff(m, comparisonParts[1], true)
			if (diff >= comparisonParts[0]) return true
			break
		case condition.includes(' is true'):
			statementParts = condition
				.split(' is true')
				.map((s) => s.trim())
			return data[statementParts[0]] === true
		case condition.includes(' is false'):
			statementParts = condition
				.split(' is false')
				.map((s) => s.trim())
			return data[statementParts[0]] === false
		case condition.includes(' is null'):
			statementParts = condition
				.split(' is null')
				.map((s) => s.trim())
			return data[statementParts[0]] === null
		case condition.includes(' is not null'):
			statementParts = condition
				.split(' is not null')
				.map((s) => s.trim())
			return (
				data[statementParts[0]] !== null &&
				typeof data[statementParts[0]] !== 'undefined'
			)
	}
	return false
}
