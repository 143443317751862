const useTask = () => {
	const getStatusName = (type) => {
		const names = {
			Unknown: 'Unknown',
			Outstanding: 'Incomplete',
			Pending: 'Under Review',
			Completed: 'Completed',
		}
		return names[type] || 'Unknown'
	}

	const isOverdue = (createdAt, daysUntilDue, status) => {
		if (status !== 'Outstanding') return false
		const dueDate = getDueDate(createdAt, daysUntilDue)
		return new Date() > dueDate
	}

	const getDueDate = (createdAt, daysUntilDue) => {
		const dueDate = new Date(createdAt)
		dueDate.setDate(dueDate.getDate() + daysUntilDue)
		return dueDate
	}

	return {
		getStatusName,
		isOverdue,
		getDueDate,
	}
}

export default useTask
