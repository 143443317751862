import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import clsx from 'clsx'
import FileList from './FileList'
import { formatBytes } from '../services/utils'

const Dropzone = ({
	onDropAccepted,
	error,
	helperText,
	maxSize,
	maxFiles,
	...rest
}) => {
	const colorMap = {
		primary: 'text-indigo-800 border-indigo-600 bg-indigo-50',
		success: 'text-green-800 border-green-600 bg-green-50',
		danger: 'text-red-800 border-red-600 bg-red-50',
	}

	const [color, setColor] = useState(colorMap.primary)
	const [files, setFiles] = useState([])

	useEffect(() => {
		if (error) {
			setColor(colorMap.danger)
		}
	}, [error, colorMap.danger])

	const onDragEnter = () => {
		setColor(colorMap.success)

		if (isDragReject) {
			setColor(colorMap.danger)
		}
	}

	const onDragLeave = () => {
		setColor(colorMap.primary)
	}

	const onDrop = () => {
		setColor(colorMap.primary)
	}

	const onAccepted = (dropFiles) => {
		const allFiles = [...files, ...dropFiles]
		setFiles(allFiles)
		if (onDropAccepted) {
			onDropAccepted(allFiles)
		}
	}

	const onErrorOrRejected = () => {
		setColor(colorMap.danger)
	}

	const {
		getRootProps,
		getInputProps,
		acceptedFiles,
		isDragReject,
		fileRejections,
	} = useDropzone({
		onDrop,
		onDragEnter,
		onDragLeave,
		isDragReject: onErrorOrRejected,
		onDropRejected: onErrorOrRejected,
		onError: onErrorOrRejected,
		onDropAccepted: onAccepted,
		maxSize,
		maxFiles,
		...rest,
	})

	const onRemoveFile = (file) => {
		acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
		const newFiles = [...files]
		newFiles.splice(files.indexOf(file), 1)
		setFiles(newFiles)
	}

	return (
		<>
			<div
				className={clsx(
					'border-dashed border-2 rounded-lg p-10 cursor-pointer',
					color
				)}
			>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					<div className="text-center">
						<div className="font-black">
							Drag 'n' drop files here or click to select files
						</div>
						<DriveFolderUploadIcon fontSize="large" />
					</div>
				</div>
			</div>
			{(helperText || maxSize || maxFiles) && (
				<div
					className={clsx(
						'text-xs mt-1',
						error ? 'text-red-700' : undefined
					)}
				>
					<div>{helperText}</div>
					{(maxFiles || maxSize) && (
						<div>
							{maxFiles && <span>Up to {maxFiles} files</span>}
							{maxSize && (
								<span>
									{maxFiles ? '' : 'Up to '} {formatBytes(maxSize)}{' '}
									each
								</span>
							)}
						</div>
					)}
				</div>
			)}
			{fileRejections.length > 0 && (
				<div className="text-xs font-black text-red-700">
					{fileRejections.map((fileRejection) => (
						<div key={fileRejection.file.lastModified}>
							{fileRejection.errors.map((error) => (
								<div>
									{fileRejection.file.name}:{' '}
									{error.code === 'file-too-large' && maxSize
										? `File is larger than ${formatBytes(maxSize)} bytes`
										: error.message}
								</div>
							))}
						</div>
					))}
				</div>
			)}
			<FileList files={files} onRemoveClick={onRemoveFile} />
		</>
	)
}

export default Dropzone
