import React from 'react'
import TextField, { TextFieldProps } from './TextField'
import InheritanceButton from '../InheritanceButton'

export type InheritedTextFieldProps = {
	showLabel?: boolean
	inheritedValue?: string
	onCheckboxChange?: () => void
	isTopLevelSite?: boolean
} & Omit<TextFieldProps, 'disabled'>

const InheritedTextField = ({
	value,
	inheritedValue = '',
	showLabel = true,
	isTopLevelSite = false,
	onCheckboxChange,
	...rest
}: InheritedTextFieldProps) => {
	const [disabled, setDisabled] = React.useState(
		(value === null || value === undefined || value === '') &&
			!isTopLevelSite
	)

	const onBreakInheritanceChange = () => {
		const isDisabled = !disabled
		setDisabled(isDisabled)
		if (onCheckboxChange) onCheckboxChange()
	}

	return (
		<>
			<TextField
				{...rest}
				value={!disabled ? value : inheritedValue}
				disabled={disabled}
			/>
			{!isTopLevelSite && (
				<InheritanceButton
					isInherited={disabled}
					onClick={onBreakInheritanceChange}
					showLabel={showLabel}
				/>
			)}
		</>
	)
}

InheritedTextField.defaultProps = {
	showLabel: undefined,
	onCheckboxChange: undefined,
	inheritedValue: '',
	isTopLevelSite: false,
}

export default InheritedTextField
