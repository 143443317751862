import loading from 'assets/loading.gif'

export const Loading = ({ size }) => {
	return (
		<img
			className={[size === 'small' ? 'w-24 h-24 m-auto' : '']}
			src={loading}
			alt="loading"
		/>
	)
}
