import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button } from '../Button'
import { isJsonString } from '../../services/utils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
	questionTypes,
	validationOptions,
} from '../../services/helper'
import MailMergeSelect from '../MailMergeSelect'

export const ModalWorkflowQuestion = ({
	visible = false,
	setVisible,
	question = '',
	setQuestion,
	targets,
	updateActiveQuestion,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const [invalid, setInvalid] = useState(false)
	const [modified, setModified] = useState(false)
	const [tempQuestion, setTempQuestion] = useState('')

	useEffect(() => {
		setTempQuestion(question)
	}, [question])

	const updateQuestion = (field, val) => {
		setModified(true)
		setTempQuestion(() => {
			const obj = JSON.parse(tempQuestion)
			obj[field] = val
			return JSON.stringify(obj, undefined, 4)
		})
	}

	const updateJSONText = (e) => {
		setModified(true)
		const text = e.target.value
		setTempQuestion(text)
		setInvalid(!isJsonString(text))
	}

	const copyToClipboard = async () => {
		await navigator.clipboard.writeText(question)
		setModified(false)
		alert(`Workflow Question Copied to Clipboard!`)
	}

	const isValid = isJsonString(tempQuestion)

	const parsedQuestion = isValid ? JSON.parse(tempQuestion) : {}

	const handleSaveQuestion = () => {
		updateActiveQuestion(parsedQuestion)
	}

	return (
		<Dialog
			fullScreen
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				<Typography variant={`h4`}>
					Editing Workflow Question
				</Typography>
				<MailMergeSelect />
				<div className={`flex`}>
					<div
						className={`absolute right-2 top-1 text-xl cursor-pointer `}
						role={'button'}
						title={`Close`}
						onClick={() => setVisible(false)}
					>
						x
					</div>
					<div className={`w-1/3 px-3`}>
						<FormControl sx={{ width: '100%', mt: 1 }}>
							<TextField
								disabled={!isValid}
								label="Question ID"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.qId}
								onChange={(e) =>
									updateQuestion('qId', e.target.value)
								}
							/>
						</FormControl>
						<FormControl sx={{ width: '100%', mt: 3 }}>
							<TextField
								disabled={!isValid}
								label="Name"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.name}
								onChange={(e) =>
									updateQuestion('name', e.target.value)
								}
							/>
						</FormControl>
						<FormControl sx={{ width: '100%', mt: 3 }}>
							<TextField
								disabled={!isValid}
								label="Title"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.title}
								onChange={(e) =>
									updateQuestion('title', e.target.value)
								}
							/>
						</FormControl>
						<FormControl sx={{ width: '100%', mt: 3 }}>
							<TextField
								disabled={!isValid}
								label="Heading"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.heading}
								onChange={(e) =>
									updateQuestion('heading', e.target.value)
								}
							/>
						</FormControl>
						<FormControl
							variant="standard"
							sx={{ width: '100%', mt: 3 }}
						>
							<InputLabel>Type</InputLabel>
							<Select
								disabled={!isValid}
								label="Target"
								variant="standard"
								value={parsedQuestion.type}
								onChange={(e) =>
									updateQuestion('type', e.target.value)
								}
							>
								{questionTypes.map((option) => (
									<MenuItem value={option.value} key={option.value}>
										{option.label}
									</MenuItem>
								))}
								<MenuItem value={'***SubmitForm***'}>
									*** Submit Lead ***
								</MenuItem>
								<MenuItem value={'***SubmitLoan***'}>
									*** Submit Loan ***
								</MenuItem>
								<MenuItem value={'***SubmitLoanAndInviteUser***'}>
									*** Submit Loan & Invite User ***
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className={`w-1/3 px-3`}>
						<FormControl sx={{ width: '100%', mt: 1 }}>
							<TextField
								disabled={!isValid}
								label="Field ID"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.fieldId}
								onChange={(e) =>
									updateQuestion('fieldId', e.target.value)
								}
							/>
						</FormControl>
						<FormControl sx={{ width: '100%', mt: 3 }}>
							<TextField
								disabled={!isValid}
								label="Question"
								variant="standard"
								className={`w-full`}
								value={parsedQuestion.question}
								onChange={(e) =>
									updateQuestion('question', e.target.value)
								}
							/>
						</FormControl>
						<FormControl
							variant="standard"
							sx={{ width: '100%', mt: 3 }}
						>
							<InputLabel>Target</InputLabel>
							<Select
								disabled={!isValid}
								label="Target"
								variant="standard"
								value={parsedQuestion.target}
								onChange={(e) =>
									updateQuestion('target', e.target.value)
								}
							>
								{targets?.map((option, index) => (
									<MenuItem value={option} key={index}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{[
							'text',
							'email',
							'ssn',
							'phone',
							'dob',
							'currency',
						].includes(parsedQuestion.type) && (
							<>
								<FormControl
									variant="standard"
									sx={{ width: '100%', mt: 3 }}
								>
									<InputLabel>
										Validation / Masking (optional)
									</InputLabel>
									<Select
										disabled={!isValid}
										variant="standard"
										value={parsedQuestion.validation}
										label="Validation / Masking"
										onChange={(e) =>
											updateQuestion('validation', e.target.value)
										}
									>
										{validationOptions?.map((option, index) => (
											<MenuItem value={option.value} key={index}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: '100%', mt: 3 }}>
									<TextField
										disabled={!isValid}
										label="Placeholder"
										variant="standard"
										className={`w-full`}
										value={parsedQuestion.placeholder}
										onChange={(e) =>
											updateQuestion('placeholder', e.target.value)
										}
									/>
								</FormControl>
							</>
						)}
						<div className={`flex`}>
							<FormControl sx={{ width: '100%', mt: 3 }}>
								<FormControlLabel
									label={
										<Typography className="dark:text-white">
											Custom Field
										</Typography>
									}
									control={
										<Checkbox
											disabled={!isValid}
											checked={parsedQuestion.isCustom}
											onChange={() =>
												updateQuestion(
													'isCustom',
													!parsedQuestion.isCustom
												)
											}
										/>
									}
								/>
							</FormControl>
							<FormControl sx={{ width: '100%', mt: 3 }}>
								<FormControlLabel
									label={
										<Typography className="dark:text-white">
											Optional
										</Typography>
									}
									control={
										<Checkbox
											disabled={!isValid}
											checked={parsedQuestion.optional}
											onChange={() =>
												updateQuestion(
													'optional',
													!parsedQuestion.optional
												)
											}
										/>
									}
								/>
							</FormControl>
						</div>
					</div>
					<div className={`w-1/3 pl-3`}>
						<FormControl sx={{ width: '100%', mt: 1 }}>
							<TextField
								error={invalid}
								className={`w-full`}
								multiline
								rows={25}
								value={tempQuestion}
								onChange={updateJSONText}
							/>
							<InputLabel error={invalid}>
								{invalid ? `invalid JSON string` : ''}
							</InputLabel>
						</FormControl>
					</div>
				</div>
			</DialogContent>
			<DialogActions className="ml-5 mr-5">
				<Button
					id="ModalYesNoOKButton"
					text={'Close Window'}
					onClick={() => setVisible(false)}
					style={
						fullScreen
							? { width: '90%', marginBottom: 10 }
							: { width: 290, marginBottom: 10 }
					}
					variant="contained"
				/>
				<Button
					id="ModalYesNoYesButton"
					text={'Copy JSON to Clipboard'}
					disabled={!modified || invalid}
					onClick={copyToClipboard}
					style={
						fullScreen
							? { width: '90%', marginBottom: 10 }
							: { width: 290, marginBottom: 10 }
					}
					variant="outlined"
				/>
				{parsedQuestion.qId && (
					<Button
						id="ModalYesNoYesButton"
						text={'Save Question'}
						disabled={!modified || invalid}
						onClick={handleSaveQuestion}
						style={
							fullScreen
								? { width: '90%', marginBottom: 10 }
								: { width: 290, marginBottom: 10 }
						}
						variant="outlined"
					/>
				)}

				{/*<div className="w-full flex justify-end">*/}
				{/*  <LoadingBtn*/}
				{/*    style={{ width: '25%'}}*/}
				{/*    variant="outlined"*/}
				{/*    text="Cancel"*/}
				{/*    onClick={() => setVisible(false)}*/}
				{/*  />*/}
				{/*  <LoadingBtn*/}
				{/*    style={{ width: '25%'}}*/}
				{/*    disabled={!modified}*/}
				{/*    text="Copy"*/}
				{/*    onClick={copyToClipboard}*/}
				{/*  />*/}
				{/*</div>*/}
			</DialogActions>
		</Dialog>
	)
}
