import React from 'react'
import { Controller } from 'react-hook-form'
import useFormField from '../../hooks/useFormField'
import ImagePicker from '../ImagePicker'

const ImagePickerField = ({
	name,
	control,
	errors,
	helperText,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	if (!control) {
		return <ImagePicker name={name} errors={errors} {...rest} />
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<ImagePicker
					{...field}
					error={!!error}
					helperText={error ? error.message : helperText}
					onChange={field.onChange}
					{...rest}
				/>
			)}
		></Controller>
	)
}

export default ImagePickerField
