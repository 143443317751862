import { useCallback } from 'react'
import { useAppContext } from './index'
import { appActions } from './AppReducer'
import { navigationLinking } from '../../services/navigation'
import axios from 'axios'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useQueryClient } from '@tanstack/react-query'

export const useAppContextActions = () => {
	const { dispatch } = useAppContext()
	const mixpanel = useMixpanel()
	const queryClient = useQueryClient()

	const applySiteConfig = useCallback((siteConfig) => {
		dispatch({
			type: appActions.setSiteConfig,
			payload: { ...siteConfig },
		})
	}, [])

	const applyAuthToken = useCallback((authToken) => {
		axios.defaults.headers.common['Authorization'] =
			`Bearer ${authToken}`
		dispatch({
			type: appActions.setAuthToken,
			payload: authToken,
		})
	}, [])

	const applyUser = useCallback((user) => {
		const payload = {
			...user,
			email: !user?.impersonatedBy ? user.email : user.impersonatedBy,
		}
		dispatch({
			type: appActions.setUser,
			payload,
		})
	}, [])

	const applyInvite = useCallback((invite) => {
		dispatch({
			type: appActions.setInvite,
			payload: invite,
		})
	}, [])

	const applyRedirectUrl = useCallback((url) => {
		dispatch({
			type: appActions.setRedirectUrl,
			payload: url,
		})
	}, [])

	const applyImpersonationRequest = useCallback((request) => {
		dispatch({
			type: appActions.setImpersonationRequest,
			payload: request,
		})
	}, [])

	const logOut = useCallback(
		(route = `/${navigationLinking.SignIn}`) => {
			mixpanel.reset()
			queryClient.clear()
			window.location.href = '/'
		},
		[]
	)

	return {
		applySiteConfig,
		applyAuthToken,
		applyUser,
		applyInvite,
		applyRedirectUrl,
		applyImpersonationRequest,
		logOut,
	}
}
