import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'
import { Check } from '@mui/icons-material'

const BranchExpandedRow = ({ data: branch }) => {
	const { name, nmlsid, email, type, corporateName, url } = branch
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="NMLSID" value={nmlsid} />
			<RowExpandedProperty label="Email" value={email} />
			<RowExpandedProperty label="Type" value={type} />
			<RowExpandedProperty
				label="Corporate Name"
				value={corporateName}
			/>
			<RowExpandedProperty label="Domain" value={url} />
		</div>
	)
}

export default BranchExpandedRow
