import React, { useEffect, useState } from 'react'
import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	FormControlLabel,
	Checkbox,
	Tooltip,
} from '@mui/material'
import { ModalColorPicker } from '../modals/ModalColorPicker'
import { ModalUploadSizeLimit } from '../modals/ModalUploadSizeLimit'
import { getTheme } from '../../config'
import { fileMBSizes } from '../../services/utils'
import InheritedTextField from '../formControls/InheritedTextField'
import InheritanceButton from '../InheritanceButton'
import NoImagePlaceholder from './NoImagePlaceholder'
import { SiteTypes } from '../../services/client'

const theme = getTheme()

export const ImagePicker = ({
	title,
	handleImagePicker,
	field,
	value,
	onCheckboxChange,
	inheritedValue,
	inherit,
	isTopLevelSite = false,
	id,
}) => {
	const [disabled, setDisabled] = React.useState(
		(value === null || value === undefined || value === '') &&
			!isTopLevelSite
	)

	const onBreakInheritanceChange = () => {
		let isDisabled = !disabled
		setDisabled(isDisabled)
		if (onCheckboxChange) onCheckboxChange()
	}

	return (
		<>
			<p className="text-sm font-rubik">{title}</p>
			<div className="flex flex-row mt-2.5 mb-4 items-center">
				<div className="w-52 h-10 rounded-tl rounded-bl border border-gray-300 flex items-center justify-center border-r-0">
					{(!value && !disabled) || (disabled && !inheritedValue) ? (
						<p className="pl-3.5 text-sm font-rubik">
							Choose your image
						</p>
					) : (
						<img
							className="h-10 rounded-tl rounded-bl"
							src={!disabled ? value : inheritedValue}
							alt={field}
						/>
					)}
				</div>
				<Button
					id={id}
					variant="contained"
					component="label"
					style={{
						textTransform: 'none',
						height: 40,
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						boxShadow: 'none',
					}}
					disabled={disabled && inherit}
				>
					Browse
					<input
						onChange={(e) => handleImagePicker(e, field)}
						hidden
						accept="image/*"
						multiple
						type="file"
						name="myImage"
						disabled={disabled && inherit}
					/>
				</Button>

				{inherit && !isTopLevelSite && (
					<div className="flex flex-col sm:w-1/2 pt-2 sm:pt-0 sm:pl-5 self-center">
						<InheritanceButton
							isInherited={disabled}
							onClick={onBreakInheritanceChange}
							showLabel={true}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export const ColorPicker = ({
	title,
	field,
	openModal,
	value,
	onCheckboxChange,
	inheritedValue,
	isTopLevelSite = false,
	inherit,
}) => {
	const [disabled, setDisabled] = React.useState(
		(value === null || value === undefined || value === '') &&
			!isTopLevelSite
	)

	const onBreakInheritanceChange = () => {
		let isDisabled = !disabled
		setDisabled(isDisabled)
		if (onCheckboxChange) onCheckboxChange()
	}

	return (
		<div
			className="flex flex-col mr-10"
			style={{ position: 'relative' }}
		>
			<p className="text-sm font-rubik">{title}</p>
			<div
				className="w-14 h-14 rounded border border-slate-300"
				style={{
					backgroundColor: !disabled
						? value === ''
							? null
							: value
						: inheritedValue === ''
							? null
							: inheritedValue,
					position: 'relative',
				}}
			>
				<NoImagePlaceholder
					value={value}
					inheritedValue={inheritedValue}
					disabled={disabled}
				/>
				{inherit && !isTopLevelSite && (
					<InheritanceButton
						isInherited={disabled}
						onClick={onBreakInheritanceChange}
						showLabel={false}
						style={{
							position: 'absolute',
							top: -5,
							right: -5,
							padding: 0,
							margin: '2px',
							zIndex: 999,
						}}
						size="small"
					/>
				)}
				<Button
					onClick={() => openModal(field, value)}
					style={{ height: 55, minWidth: 55, padding: 0 }}
					hidden
					disabled={disabled}
				/>
			</div>
		</div>
	)
}

export const IconPicker = ({
	title,
	value,
	field,
	handleImagePicker,
	onCheckboxChange,
	inheritedValue,
	isTopLevelSite = false,
	inherit,
}) => {
	const [disabled, setDisabled] = React.useState(
		(value === null || value === undefined || value === '') &&
			!isTopLevelSite
	)

	const onBreakInheritanceChange = () => {
		let isDisabled = !disabled
		setDisabled(isDisabled)
		if (onCheckboxChange) onCheckboxChange()
	}
	return (
		<div className="flex flex-col mr-10">
			<p className="text-sm font-rubik">{title}</p>
			<div
				className="w-14 h-14 rounded border border-slate-300"
				style={{ position: 'relative' }}
			>
				{inherit && !isTopLevelSite && (
					<InheritanceButton
						isInherited={disabled}
						onClick={onBreakInheritanceChange}
						showLabel={false}
						style={{
							position: 'absolute',
							top: -5,
							right: -5,
							padding: 0,
							margin: '2px',
							zIndex: 999,
						}}
						size="small"
					/>
				)}
				<Button
					component="label"
					style={{
						height: 55,
						minWidth: 55,
						padding: 0,
						backgroundImage: !disabled
							? value === ''
								? null
								: `url(${value})`
							: inheritedValue === ''
								? null
								: `url(${inheritedValue})`,
						backgroundRepeat: 'round',
					}}
					disabled={disabled}
				>
					<NoImagePlaceholder
						value={value}
						inheritedValue={inheritedValue}
						disabled={disabled}
					/>
					<input
						onChange={(e) => handleImagePicker(e, field)}
						hidden
						accept="image/*"
						multiple
						type="file"
						name="myImage"
						disabled={disabled}
					/>
				</Button>
			</div>
		</div>
	)
}

export const WebsiteSettings = ({
	formData,
	setFormData,
	inheritedSiteConfiguration,
	entityType,
	width,
	isTopLevelSite = false,
}) => {
	const [modalColorPicker, setModalColorPicker] = useState({
		open: false,
		colorName: '',
		modalColor: '',
		dataColor: '',
	})
	const [modalSizeLimitVisible, setModalSizeLimitVisible] =
		useState(false)

	const openModal = (colorName, dataColor) => {
		if (dataColor === '') {
			dataColor = theme.color.primary.white
		}
		setModalColorPicker({
			open: true,
			colorName: colorName,
			dataColor: dataColor,
			modalColor: '',
		})
	}

	const updatePOSSiteConfiguration = (event) => {
		const { name: field, value } = event.target
		setFormData((formData) => ({ ...formData, [field]: value }))
	}

	const updateSiteConfiguration = (field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }))
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const handleImagePicker = async (e, field) => {
		const file = e.target.files[0]

		if (file?.size > fileMBSizes['20']) {
			setModalSizeLimitVisible(true)
		} else {
			const base64 = await convertBase64(file)
			setFormData({ ...formData, [field]: base64 })
		}
	}

	const onCheckboxChange = (id) => {
		setFormData((formData) => ({ ...formData, [id]: null }))
	}

	return (
		<>
			<ModalColorPicker
				modalColorPicker={modalColorPicker}
				setModalColorPicker={setModalColorPicker}
				setFormData={setFormData}
				formData={formData}
			/>
			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>
			<div className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<div className="flex flex-row w-full items-end">
						<FormControl
							key={'WebsiteSettingsLandingPageTypePicker'}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: 2 }}
						>
							<InputLabel>Landing Page Type</InputLabel>
							<Select
								value={formData?.landingPageType || 0}
								onChange={(e) =>
									updatePOSSiteConfiguration(
										'landingPageType',
										e.target.value
									)
								}
								label="Landing Page Type"
							>
								<MenuItem value={0}>Default Landing Page</MenuItem>
								<MenuItem value={1}>Condensed Landing Page</MenuItem>
								<MenuItem value={2}>
									LO Condensed Landing Page
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					{entityType !== 3 && (
						<ImagePicker
							title="Main Banner (1270 x 415)"
							value={formData?.bannerUrl}
							inheritedValue={inheritedSiteConfiguration?.bannerUrl}
							handleImagePicker={handleImagePicker}
							field="bannerUrl"
							id="WebsiteSettingsUpdateMainBannerImageButton"
							onCheckboxChange={() => onCheckboxChange('bannerUrl')}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
					)}
					<div className="flex flex-col">
						<ImagePicker
							title="Secondary Banner (1270 x 300)"
							value={formData?.secondaryBannerUrl}
							inheritedValue={
								inheritedSiteConfiguration?.secondaryBannerUrl
							}
							handleImagePicker={handleImagePicker}
							field="secondaryBannerUrl"
							onCheckboxChange={() =>
								onCheckboxChange('secondaryBannerUrl')
							}
							id="WebsiteSettingsUpdateSecondaryBannerImageButton"
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
					<ImagePicker
						title="Brand Logo (620 x 120)"
						value={formData?.logoUrl}
						inheritedValue={inheritedSiteConfiguration?.logoUrl}
						handleImagePicker={handleImagePicker}
						field="logoUrl"
						id="WebsiteSettingsUpdateLogoImageButton"
						onCheckboxChange={() => onCheckboxChange('logoUrl')}
						inherit
						isTopLevelSite={isTopLevelSite}
					/>
					<ImagePicker
						title="Portal Logo (600 x 300)"
						value={formData?.portalLogoUrl}
						inheritedValue={inheritedSiteConfiguration?.portalLogoUrl}
						handleImagePicker={handleImagePicker}
						field="portalLogoUrl"
						id="WebsiteSettingsUpdatePortalLogoImageButton"
						onCheckboxChange={() => onCheckboxChange('portalLogoUrl')}
						inherit
						isTopLevelSite={isTopLevelSite}
					/>
					{![0, 1, 2].includes(entityType) && (
						<ImagePicker
							title="Headshot (300 x 300)"
							value={formData?.profilePhotoUrl}
							inheritedValue={
								inheritedSiteConfiguration?.profilePhotoUrl
							}
							handleImagePicker={handleImagePicker}
							field="profilePhotoUrl"
							id="WebsiteSettingsUpdateProfilePhotoImageButton"
						/>
					)}
					<ImagePicker
						title="App Background Image"
						value={formData?.backgroundImageUrl}
						inheritedValue={
							inheritedSiteConfiguration?.backgroundImageUrl
						}
						handleImagePicker={handleImagePicker}
						field="backgroundImageUrl"
						id="WebsiteSettingsUpdateBackgroundImageButton"
						onCheckboxChange={() =>
							onCheckboxChange('backgroundImageUrl')
						}
						inherit
						isTopLevelSite={isTopLevelSite}
					/>
					{entityType === 2 && (
						<ImagePicker
							title="Location Image"
							value={formData?.locationImageUrl}
							inheritedValue={
								inheritedSiteConfiguration?.locationImageUrl
							}
							handleImagePicker={handleImagePicker}
							field="locationImageUrl"
							id="WebsiteSettingsUpdateLocationImageButton"
						/>
					)}
					<div className="flex flex-row justify-between w-72 mt-5">
						<ColorPicker
							title="Bg Color"
							value={formData.backgroundColor}
							inheritedValue={
								inheritedSiteConfiguration?.backgroundColor
							}
							field="backgroundColor"
							openModal={openModal}
							onCheckboxChange={() =>
								onCheckboxChange('backgroundColor')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<ColorPicker
							title="Primary"
							value={formData?.primaryColor}
							inheritedValue={
								inheritedSiteConfiguration?.primaryColor
							}
							field="primaryColor"
							openModal={openModal}
							onCheckboxChange={() =>
								onCheckboxChange('primaryColor')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<ColorPicker
							title="Secondary"
							value={formData?.secondaryColor}
							inheritedValue={
								inheritedSiteConfiguration?.secondaryColor
							}
							field="secondaryColor"
							openModal={openModal}
							onCheckboxChange={() =>
								onCheckboxChange('secondaryColor')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
					<div className="flex flex-row justify-between w-72 mt-5">
						<ColorPicker
							title="Text"
							value={formData?.textColor}
							inheritedValue={inheritedSiteConfiguration?.textColor}
							field="textColor"
							openModal={openModal}
							onCheckboxChange={() => onCheckboxChange('textColor')}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<ColorPicker
							title="Icon"
							value={formData?.iconColor}
							inheritedValue={inheritedSiteConfiguration?.iconColor}
							field="iconColor"
							openModal={openModal}
							onCheckboxChange={() => onCheckboxChange('iconColor')}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<IconPicker
							title="Favicon"
							value={formData?.iconUrl}
							inheritedValue={inheritedSiteConfiguration?.iconUrl}
							field="iconUrl"
							handleImagePicker={handleImagePicker}
							onCheckboxChange={() => onCheckboxChange('iconUrl')}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
					<div>
						<div className="mt-8 mb-4 font-bold">Dark Mode</div>
						<ImagePicker
							title="Brand Logo (620 x 120)"
							value={formData?.darkModeLogoUrl}
							inheritedValue={
								inheritedSiteConfiguration?.darkModeLogoUrl
							}
							handleImagePicker={handleImagePicker}
							field="darkModeLogoUrl"
							id="WebsiteSettingsUpdateDarkModeLogoImageButton"
							onCheckboxChange={() =>
								onCheckboxChange('darkModeLogoUrl')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<ImagePicker
							title="Portal Logo (600 x 300)"
							value={formData?.darkModePortalLogoUrl}
							inheritedValue={
								inheritedSiteConfiguration?.darkModePortalLogoUrl
							}
							handleImagePicker={handleImagePicker}
							field="darkModePortalLogoUrl"
							id="WebsiteSettingsUpdateDarkPortalLogoImageButton"
							onCheckboxChange={() =>
								onCheckboxChange('darkModePortalLogoUrl')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
						<div className="flex flex-row justify-between w-72 mt-5">
							<ColorPicker
								title="Primary"
								value={formData?.darkModePrimaryColor}
								inheritedValue={
									inheritedSiteConfiguration?.darkModePrimaryColor
								}
								field="darkModePrimaryColor"
								openModal={openModal}
								onCheckboxChange={() =>
									onCheckboxChange('darkModePrimaryColor')
								}
								inherit
								isTopLevelSite={isTopLevelSite}
							/>
							<ColorPicker
								title="Secondary"
								value={formData?.darkModeSecondaryColor}
								inheritedValue={
									inheritedSiteConfiguration?.darkModeSecondaryColor
								}
								field="darkModeSecondaryColor"
								openModal={openModal}
								onCheckboxChange={() =>
									onCheckboxChange('darkModeSecondaryColor')
								}
								inherit
								isTopLevelSite={isTopLevelSite}
							/>
							<IconPicker
								title="Favicon"
								value={formData?.darkModeIconUrl}
								inheritedValue={
									inheritedSiteConfiguration?.darkModeIconUrl
								}
								field="darkModeIconUrl"
								handleImagePicker={handleImagePicker}
								onCheckboxChange={() =>
									onCheckboxChange('darkModeIconUrl')
								}
								inherit
								isTopLevelSite={isTopLevelSite}
							/>
						</div>
					</div>
					<div>
						<div className="mt-8 mb-4 font-bold">Mobile App</div>
						<ImagePicker
							title="Logo"
							value={formData?.mobileAppLogoUrl}
							inheritedValue={
								inheritedSiteConfiguration?.mobileAppLogoUrl
							}
							handleImagePicker={handleImagePicker}
							field="mobileAppLogoUrl"
							id="WebsiteSettingsUpdateMobileAppLogoUrlImageButton"
							onCheckboxChange={() =>
								onCheckboxChange('mobileAppLogoUrl')
							}
							inherit
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
				</div>

				<div className="w-full mt-5 md:mt-0 md:ml-2 md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<div
						className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
					>
						<InheritedTextField
							name="facebookUrl"
							label="Facebook"
							placeholder="Enter Facebook URL"
							id="AdminAccountWebsiteSettingsFacebookUrl"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
							value={formData.facebookUrl || ''}
							inheritedValue={
								inheritedSiteConfiguration.facebookUrl || ''
							}
							showLabel={false}
							variant="standard"
							onChange={updatePOSSiteConfiguration}
							onCheckboxChange={() => onCheckboxChange('facebookUrl')}
							isTopLevelSite={isTopLevelSite}
						/>
						<InheritedTextField
							name="twitterUrl"
							label="Twitter"
							placeholder="Enter Twitter URL"
							id="AdminAccountWebsiteSettingsTwitterUrl"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
							value={formData.twitterUrl || ''}
							inheritedValue={
								inheritedSiteConfiguration.twitterUrl || ''
							}
							showLabel={false}
							variant="standard"
							onChange={updatePOSSiteConfiguration}
							onCheckboxChange={() => onCheckboxChange('twitterUrl')}
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
					<div
						className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
					>
						<InheritedTextField
							name="instagramUrl"
							label="Instagram"
							placeholder="Enter Instagram URL"
							id="AdminAccountWebsiteSettingsInstagramUrl"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
							value={formData.instagramUrl || ''}
							inheritedValue={
								inheritedSiteConfiguration.instagramUrl || ''
							}
							showLabel={false}
							variant="standard"
							onChange={updatePOSSiteConfiguration}
							onCheckboxChange={() =>
								onCheckboxChange('instagramUrl')
							}
							isTopLevelSite={isTopLevelSite}
						/>
						<InheritedTextField
							name="linkedInUrl"
							label="LinkedIn"
							placeholder="Enter LinkedIn URL"
							id="AdminAccountWebsiteSettingsLinkedInUrl"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
							value={formData.linkedInUrl || ''}
							inheritedValue={
								inheritedSiteConfiguration.linkedInUrl || ''
							}
							showLabel={false}
							variant="standard"
							onChange={updatePOSSiteConfiguration}
							onCheckboxChange={() => onCheckboxChange('linkedInUrl')}
							isTopLevelSite={isTopLevelSite}
						/>
					</div>
					{[SiteTypes.loanOfficer, SiteTypes.realtor].includes(
						entityType
					) && (
						<div
							className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
						>
							<TextField
								id="AdminAccountWebsiteSettingsCalendarUrl"
								placeholder="Enter Calendar URL (external)"
								value={formData?.calendarUrl}
								label="Calendar"
								onChange={(e) =>
									updateSiteConfiguration(
										'calendarUrl',
										e.target.value
									)
								}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
							/>
							<TextField
								id="AdminAccountWebsiteSettingsLinkedInUrl"
								placeholder="Enter Surveys URL (external)"
								value={formData?.surveysUrl}
								label="Reviews"
								onChange={(e) =>
									updateSiteConfiguration(
										'surveysUrl',
										e.target.value
									)
								}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
					)}
					<p className="mt-5 md:mt-0 mb-2.5 text-sm font-rubik">
						Footer Disclaimer Text 1
					</p>
					<InheritedTextField
						name="footerDisclaimerText1"
						label=""
						placeholder="Type something..."
						id="AdminBranchProfileFooterDisclaimerText1TextArea"
						className="w-full"
						sx={{ mb: 2 }}
						value={formData.footerDisclaimerText1 || ''}
						inheritedValue={
							inheritedSiteConfiguration.footerDisclaimerText1 || ''
						}
						showLabel={true}
						multiline
						variant={undefined}
						variant="outlined"
						rows={10}
						onChange={updatePOSSiteConfiguration}
						onCheckboxChange={() =>
							onCheckboxChange('footerDisclaimerText1')
						}
						isTopLevelSite={isTopLevelSite}
					/>
					<p className="mt-5 md:mt-0 mb-2.5 text-sm font-rubik">
						Footer Disclaimer Text 2
					</p>
					<InheritedTextField
						name="footerDisclaimerText2"
						label=""
						placeholder="Type something..."
						id="AdminBranchProfileFooterDisclaimerText2TextArea"
						className="w-full"
						sx={{ mb: 2 }}
						value={formData.footerDisclaimerText2 || ''}
						inheritedValue={
							inheritedSiteConfiguration.footerDisclaimerText2 || ''
						}
						showLabel={true}
						multiline
						variant="outlined"
						rows={10}
						onChange={updatePOSSiteConfiguration}
						onCheckboxChange={() =>
							onCheckboxChange('footerDisclaimerText2')
						}
						isTopLevelSite={isTopLevelSite}
					/>
				</div>
			</div>
		</>
	)
}
