import React, { useState } from 'react'
import {
	List,
	ListItem,
	IconButton,
	ListItemText,
	ListItemSecondaryAction,
	ListItemAvatar,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import { ModalRemoveRestoreRecordConfirm } from './modals/ModalRemoveRestoreRecordConfirm'
import useImageValidator from '../hooks/useImageValidator'
import ImagePlaceholderPreview from './ImagePlaceholderPreview'

const FileList = ({
	className,
	files,
	onRemoveClick: onRemoveConfirmProp,
}) => {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedFile, setSelectedFile] = useState(null)
	const isImage = useImageValidator()

	if (!files || files.length === 0) {
		return null
	}

	const onRemoveClick = (file) => {
		setSelectedFile(file)
		setDeleteModalOpen(true)
	}

	const onRemoveConfirm = () => {
		if (onRemoveConfirmProp) {
			onRemoveConfirmProp(selectedFile)
		}
		setDeleteModalOpen(false)
		setSelectedFile(null)
	}

	return (
		<>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={deleteModalOpen}
				setRemoveModalVisible={setDeleteModalOpen}
				remove={onRemoveConfirm}
				row={selectedFile}
			/>
			<List className={className}>
				{files.map((file) => (
					<ListItem key={file.id || file.name}>
						<ListItemAvatar>
							{isImage(file) && file.file ? (
								<ImagePlaceholderPreview file={file} />
							) : (
								<FileIcon />
							)}
						</ListItemAvatar>
						<ListItemText primary={file.name || file.file.fileName} />
						{onRemoveClick && (
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									onClick={() => onRemoveClick(file)}
								>
									<CloseIcon />
								</IconButton>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				))}
			</List>
		</>
	)
}

export default FileList
