import React from 'react'
import { List } from '@mui/material'
import VersionListItem from './VersionListItem'

const VersionList = ({ versions, ...rest }) => {
	return (
		<List dense>
			{versions.map((version) => (
				<VersionListItem
					key={version.id}
					version={version}
					{...rest}
				/>
			))}
		</List>
	)
}

export default VersionList
