import React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Tooltip } from '@mui/material'

export type InheritanceButtonProps = {
	isInherited: boolean
	showLabel?: boolean
} & IconButtonProps

const InheritanceButton = ({
	isInherited,
	showLabel,
	...rest
}: InheritanceButtonProps) => {
	const label = isInherited
		? 'Break Inheritance'
		: 'Use Inherited Value'
	return (
		<div className="flex flex-row items-center">
			<Tooltip title={label}>
				<IconButton {...rest}>
					{isInherited ? <LockIcon /> : <LockOpenIcon />}
				</IconButton>
			</Tooltip>
			{showLabel && <div>{label}</div>}
		</div>
	)
}

InheritanceButton.defaultProps = {
	showLabel: true,
}

export default InheritanceButton
