import React from 'react'
import { applyMask } from '../../../../services/utils'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'

const TaskRowExpanded = ({ data: task }) => {
	const {
		name,
		type,
		description,
		daysDueFromApplication,
		targetUserRole,
	} = task
	return (
		<div className="p-5">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Description" value={description} />
			<RowExpandedProperty label="Type" value={type} />
			<RowExpandedProperty
				label="Days due"
				value={daysDueFromApplication}
			/>
			<RowExpandedProperty label="Role" value={targetUserRole} />
		</div>
	)
}

export default TaskRowExpanded
