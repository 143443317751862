import React from 'react'
import Dialog from '../../../../components/modals/Dialog'
import LoanTaskFieldForm from '../forms/LoanTaskFieldForm'
import LoanTaskDocumentForm from '../forms/LoanTaskDocumentForm'
import { useMutation } from '@tanstack/react-query'
import {
	createLoanDocumentBuckets,
	createLoanTaskDocument,
	createLoanTaskDocumentBucket,
	updateLoanTask,
} from '../../../../services/client'
import { useAlert } from '../../../../hooks'
import { Verification } from './Verification'
import { eventTypes } from '../../../../services/constants'
import { useMixpanel } from '../../../../hooks/useMixpanel'

const ModalLoanTask = ({
	loanTask,
	onSubmit,
	onCancel = undefined,
	...rest
}) => {
	const { task } = loanTask

	const { alert } = useAlert()
	const mixpanel = useMixpanel()

	const updateLoanTaskFieldMutation = useMutation({
		mutationFn: async (data) =>
			updateLoanTask(loanTask.loanID, loanTask.id, data),
	})

	const createLoanTaskDocumentMutation = useMutation({
		mutationFn: async (data) =>
			createLoanTaskDocument(loanTask.loanID, loanTask.id, data),
	})

	/**
	 * @todo tmp fix for dup doc buckets. once current loan tasks are closed prior to this release 9/13/24, this can be removed.
	 * Buckets are created when tasks are added to a loan on the backend
	 */
	const createLoanDocumentBucketMutation = useMutation({
		mutationFn: async (data) =>
			createLoanTaskDocumentBucket(loanTask.loanID, loanTask.id),
	})

	const handleSubmit = async (data) => {
		try {
			const { id, name, type } = task
			await formMap[type]?.onSubmit(data)
			alert('The task was successfully updated', {
				severity: 'success',
			})
			mixpanel.trackEvent(eventTypes.LOAN_TASK_SUBMITTED, {
				id,
				name,
				type,
			})
			onSubmit()
		} catch (e) {
			alert('There was a problem updating the task', {
				severity: 'error',
			})
		}
	}

	const handleFieldFormSubmit = async (data) => {
		await updateLoanTaskFieldMutation.mutateAsync(data)
	}

	const handleDocumentFormSubmit = async (data) => {
		if (loanTask.documents.length === 0 && task.losTarget) {
			try {
				await createLoanDocumentBucketMutation.mutateAsync([
					task.losTarget,
				])
			} catch (e) {
				console.error('Error creating document bucket', e) // ignore this error since its temporary to fix dup doc bucket issues on existing loans
			}
		}

		const { documents } = data
		const mutations = []
		documents.forEach((file) => {
			mutations.push(
				createLoanTaskDocumentMutation.mutateAsync({ file })
			)
		})
		await Promise.all(mutations)
	}

	const verificationFormMap = {
		onSubmit: handleDocumentFormSubmit,
		component: (
			<Verification
				loanTask={loanTask}
				onSubmit={handleSubmit}
				onCancel={onCancel}
			/>
		),
	}

	const formMap = {
		Document: {
			onSubmit: handleDocumentFormSubmit,
			component: (
				<LoanTaskDocumentForm
					loanTask={loanTask}
					onSubmit={handleSubmit}
					onCancel={onCancel}
				/>
			),
		},
		Field: {
			onSubmit: handleFieldFormSubmit,
			component: (
				<LoanTaskFieldForm
					loanTask={loanTask}
					onSubmit={handleSubmit}
					onCancel={onCancel}
				/>
			),
		},
		VerificationOfAssets: verificationFormMap,
		VerificationOfIncome: verificationFormMap,
		VerificationOfEmployment: verificationFormMap,
		VerificationOfCredit: verificationFormMap,
		VerificationOfIncomeAndEmployment: verificationFormMap,
	}

	return (
		<Dialog
			title={task.name}
			onClose={onCancel}
			fullWidth
			maxWidth="sm"
			{...rest}
		>
			{task.description && <p className="mb-2">{task.description}</p>}
			{formMap[task.type]?.component}
		</Dialog>
	)
}

export default ModalLoanTask
