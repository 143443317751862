import React from 'react'
import { applyMask } from '../../../../services/utils'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'

const TaskRowExpanded = ({ data: rule }) => {
	const { name, description, tasks } = rule
	return (
		<div className="p-5">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Description" value={description} />
			<RowExpandedProperty label="# of Tasks" value={tasks.length} />
		</div>
	)
}

export default TaskRowExpanded
