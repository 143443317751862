import React from 'react'
import { Step, StepLabel } from '@mui/material'

const LoanMilestoneStep = ({ loan, milestone, ...other }) => {
	const { name, completed } = milestone
	return (
		<Step completed={completed} {...other}>
			<StepLabel>{name}</StepLabel>
		</Step>
	)
}

export default LoanMilestoneStep
