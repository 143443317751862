import React, { useMemo } from 'react'
import { useAppContext } from '../../components/AppContext'
import { disclosuresSSOSignIn } from '../../services/utils'

export default function DisclosuresSSO() {
	const { state } = useAppContext()
	const { user, authToken } = state

	useMemo(() => {
		disclosuresSSOSignIn(user, authToken)
	}, [user])

	return <div>redirecting...</div>
}
