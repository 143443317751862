import React from 'react'
import {
	FormControlLabel,
	Checkbox as MuiCheckbox,
	CheckboxProps as MuiCheckboxProps,
} from '@mui/material'
import { Controller, ControllerProps } from 'react-hook-form'

export type CheckboxProps = {
	name?: string
	label?: string
	control?: ControllerProps['control']
	className?: string
} & Omit<MuiCheckboxProps, 'className' | 'name'>

const Checkbox = ({
	name,
	label,
	control,
	defaultValue,
	className,
	...rest
}: CheckboxProps) => (
	<div className={className}>
		{name && control && (
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<FormControlLabel
						label={label}
						control={
							<MuiCheckbox
								{...field}
								{...rest}
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
							/>
						}
					/>
				)}
			/>
		)}
		{!control && (
			<FormControlLabel
				label={label}
				control={
					<MuiCheckbox
						name={name}
						defaultValue={defaultValue}
						{...rest}
					/>
				}
			/>
		)}
	</div>
)

Checkbox.defaultProps = {
	name: undefined,
	label: undefined,
	control: undefined,
	className: undefined,
}

export default Checkbox
