import React from 'react'
import TaskAutocomplete from './TaskAutocomplete'
import { Controller } from 'react-hook-form'
import useFormField from '../../../hooks/useFormField'

const TaskAutocompleteField = ({
	name,
	control,
	errors,
	valueField = 'id',
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	if (!control) {
		return <TaskAutocomplete {...rest} />
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<TaskAutocomplete
					{...field}
					error={!!error}
					helperText={error ? error.message : undefined}
					onChange={(e) => {
						let value = null
						if (e) {
							value = valueField ? e[valueField] : e
						}
						field.onChange(value)
					}}
					{...rest}
				/>
			)}
		/>
	)
}

export default TaskAutocompleteField
