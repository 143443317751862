import React from 'react'
import { InputAdornment } from '@mui/material'
import { merge } from 'lodash'
import TextField from './TextField'

const PercentField = ({ InputProps, ...rest }) => {
	const endAdornment = (
		<InputAdornment position="end">%</InputAdornment>
	)
	const mergedInputProps = InputProps
		? merge(InputProps, { endAdornment })
		: { endAdornment }
	return <TextField InputProps={mergedInputProps} {...rest} />
}

export default PercentField
