import React from 'react'
import clsx from 'clsx'
import Switch, { SwitchProps } from './Switch'
import InheritanceButton from '../InheritanceButton'

export type InheritedSwitchProps = {
	className?: string
	showLabel?: boolean
	value?: boolean
	inheritedValue?: boolean
	isTopLevelSite?: boolean
	onCheckboxChange?: (_isDisabled: boolean) => void
} & SwitchProps

const InheritedSwitch = ({
	className,
	showLabel = true,
	value,
	inheritedValue,
	onCheckboxChange,
	isTopLevelSite,
	...rest
}: InheritedSwitchProps) => {
	const [disabled, setDisabled] = React.useState(
		(value === null || value === undefined) && !isTopLevelSite
	)

	const onBreakInheritanceChange = () => {
		const isDisabled = !disabled
		setDisabled(isDisabled)
		if (onCheckboxChange) onCheckboxChange(isDisabled)
	}

	return (
		<div className={clsx('flex flex-row', className)}>
			<Switch
				disabled={disabled}
				checked={!disabled && value != null ? value : inheritedValue}
				{...rest}
			/>

			{!isTopLevelSite && (
				<InheritanceButton
					isInherited={disabled}
					onClick={onBreakInheritanceChange}
					showLabel={showLabel}
				/>
			)}
		</div>
	)
}

InheritedSwitch.defaultProps = {
	className: undefined,
	showLabel: undefined,
	value: undefined,
	onCheckboxChange: undefined,
	inheritedValue: undefined,
	isTopLevelSite: false,
}

export default InheritedSwitch
