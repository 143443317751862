import React from 'react'
import loadingDots from 'assets/loadingDots.gif'

export const LoadingDots = ({ style, className }) => (
	<img
		src={loadingDots}
		alt="Loading dots"
		style={style}
		className={className}
	/>
)
