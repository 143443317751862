import React, { useState } from 'react'
import { ListItemSecondaryAction, ListItemText } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

const TaskListItem = ({
	task,
	onEditClick,
	onDeleteClick,
	error = false,
}) => {
	const [actionAnchorEl, setActionAnchorEl] = useState(null)

	const showActionMenu = (e) => {
		e.stopPropagation()
		setActionAnchorEl(e.currentTarget)
	}

	const hideActionMenu = () => {
		setActionAnchorEl(null)
	}

	const handleEditClick = (e) => {
		onEditClick(e, task)
		hideActionMenu()
	}

	const handleDeleteClick = (e) => {
		onDeleteClick(e, task)
		hideActionMenu()
	}

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={actionAnchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(actionAnchorEl)}
				className="mt-8 sm:mt-10"
				onClose={hideActionMenu}
			>
				<MenuItem onClick={handleEditClick}>Edit</MenuItem>
				<MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
			</Menu>
			<ListItem>
				<div>
					<div>{task.name}</div>
					{task.description && (
						<div className="text-xs">{task.description}</div>
					)}
					{error && (
						<div className="text-xs text-red-800">
							*requires updates
						</div>
					)}
				</div>

				<ListItemSecondaryAction>
					<IconButton
						size="small"
						edge="start"
						color="inherit"
						aria-label="refresh"
						onClick={showActionMenu}
						sx={bgColor}
					>
						<MoreVertIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</>
	)
}

export default TaskListItem
