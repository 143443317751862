import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
	Button as MuiButton,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material'
import { Button } from '../../../../components/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ModalImportTasks from './ModalImportTasks'
import {
	createLoanTask,
	createTask,
	importLoanTasks,
} from '../../../../services/client'
import { useAlert, useWindowSize } from '../../../../hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import queryKeys from '../../../../services/queryKeys'
import ModalTaskForm from '../../../../components/modals/ModalTaskForm'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { eventTypes } from '../../../../services/constants'
import { getTheme } from '../../../../config'

const theme = getTheme()

const AddTaskButtonGroup = ({
	loan,
	onSave,
	loanUsers,
	className = undefined,
	...rest
}) => {
	const [width] = useWindowSize()
	const { dataTable: breakpoint } = theme.breakpoints
	const isMobile = width < breakpoint

	const { LoanID: loanId } = loan

	const mixpanel = useMixpanel()

	const addBtnGroupRef = useRef()
	const [popoverOpen, setPopoverOpen] = useState(false)
	const [importModalOpen, setImportModalOpen] = useState(false)
	const [importModalType, setImportModalType] = useState(null)
	const [selectedTask, setSelectedTask] = useState(null)
	const [taskModalOpen, setTaskModalOpen] = useState(false)

	const { alert } = useAlert()
	const queryClient = useQueryClient()

	const createTaskMutation = useMutation({
		mutationFn: async (data) => createTask(data),
	})

	const importLoanTaskMutation = useMutation({
		mutationFn: async (data) => importLoanTasks(loanId, data),
	})

	useEffect(() => {
		if (importModalType) {
			setImportModalOpen(true)
		}
	}, [importModalType])

	const handleAddClick = () => {
		setTaskModalOpen(true)
	}

	const handleAddPopoverClose = () => {
		setPopoverOpen(false)
	}

	const handleAddPopoverToggle = () => {
		setPopoverOpen(!popoverOpen)
	}

	const handleAddFromExistingClick = () => {
		setImportModalType('existing')
	}

	const handleAddFromUWConditionsClick = () => {
		setImportModalType('underwritingConditions')
	}

	const handleAddFromPreliminaryConditionsClick = () => {
		setImportModalType('preliminaryConditions')
	}

	const handleImportSave = async (tasks) => {
		try {
			const newTasksToCreate = tasks.filter((t) => !t.id)
			const newTaskRequests = []
			newTasksToCreate.forEach((t) => {
				newTaskRequests.push(createTaskMutation.mutateAsync(t))
			})

			const newTasks = await Promise.all(newTaskRequests)
			const newTasksWithBorrowers = newTasks.map((newTask, i) => ({
				...newTask,
				borrowers: tasks[i].borrowers,
			}))
			const existingTasks = tasks.filter((t) => t.id)
			const allTasks = [...newTasksWithBorrowers, ...existingTasks]
			const loanTasksRequest = []

			allTasks.forEach((t) => {
				if (t.borrowers?.length > 0) {
					// Add specific borrowers
					t.borrowers.forEach((userId) => {
						loanTasksRequest.push({
							taskId: t.id,
							userId,
						})
					})
				} else {
					// Add by targetUserRole
					if (t.targetUserRole === 'Borrowers') {
						loanUsers.forEach((u) => {
							loanTasksRequest.push({
								taskId: t.id,
								userId: u.id,
							})
						})
					} else {
						const user = loanUsers.find(
							(b) => b.role === t.targetUserRole
						)
						if (user) {
							loanTasksRequest.push({
								taskId: t.id,
								userId: user.id,
							})
						}
					}
				}
			})

			const results =
				await importLoanTaskMutation.mutateAsync(loanTasksRequest)
			alert('Your tasks have been added!', { severity: 'success' })
			mixpanel.trackEvent(eventTypes.LOAN_TASKS_IMPORTED)
			onSave(results)
		} catch (e) {
			const errorMessage = 'There was a problem adding the tasks'
			alert(errorMessage, { severity: 'error' })
		} finally {
			handleImportModalClose()
		}
	}

	const handleImportModalClose = () => {
		setImportModalOpen(false)
		setImportModalType(null)
	}

	const handleSaveTask = async (task) => {
		try {
			const { id, name, borrowers } = task
			let taskId = id
			if (!taskId) {
				let newTask = await createTaskMutation.mutateAsync(task)
				taskId = newTask.id
				const { id, name } = newTask
				mixpanel.trackEvent(eventTypes.TASK_CREATED, {
					id,
					name,
				})
			}

			const loanTaskRequests = []
			borrowers.forEach((userId) => {
				loanTaskRequests.push({
					taskId,
					userId,
				})
			})

			const results =
				await importLoanTaskMutation.mutateAsync(loanTaskRequests)
			results.forEach((result) => {
				mixpanel.trackEvent(eventTypes.LOAN_TASK_CREATED, {
					id,
					name,
				})
			})

			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanTasks, loanId],
			})

			alert('Your task was created!', { severity: 'success' })
			//onSave(tasks)
		} catch (e) {
			const errorMessage = 'There was a problem saving the task'
			alert(errorMessage, { severity: 'error' })
		} finally {
			handleTaskModalClose()
		}
	}

	const handleTaskModalClose = () => {
		setTaskModalOpen(false)
		setSelectedTask(null)
	}

	const borrowers = useMemo(
		() => loanUsers.filter((u) => u.role === 'Borrower'),
		[loanUsers]
	)

	return (
		<div className={className}>
			<ModalTaskForm
				open={taskModalOpen}
				task={selectedTask}
				loanId={loanId}
				onSubmit={handleSaveTask}
				onClose={handleTaskModalClose}
				borrowers={borrowers}
			/>

			{importModalType && (
				<ModalImportTasks
					open={importModalOpen}
					type={importModalType}
					loanId={loanId}
					borrowers={borrowers}
					onSave={handleImportSave}
					onClose={handleImportModalClose}
				/>
			)}

			<ButtonGroup variant="contained" ref={addBtnGroupRef} {...rest}>
				<Button
					text={isMobile ? 'Add' : 'Add Task'}
					onClick={handleAddClick}
				/>
				<MuiButton size="small" onClick={handleAddPopoverToggle}>
					<ArrowDropDownIcon />
				</MuiButton>
			</ButtonGroup>
			<Popper
				open={popoverOpen}
				anchorEl={addBtnGroupRef.current}
				role={undefined}
				placement="bottom-end"
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleAddPopoverClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									<MenuItem selected onClick={handleAddClick}>
										Add Task
									</MenuItem>
									<MenuItem onClick={handleAddFromExistingClick}>
										Import Tasks
									</MenuItem>
									<MenuItem
										onClick={handleAddFromPreliminaryConditionsClick}
									>
										Preliminary Conditions
									</MenuItem>
									<MenuItem onClick={handleAddFromUWConditionsClick}>
										UW Conditions
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}

export default AddTaskButtonGroup
