import React, { useEffect, useState } from 'react'
import DataTable from './DataTable'

const PaginatedDataTable = ({
	defaultSortAsc = true,
	defaultSortFieldId,
	onPaginationChange,
	...rest
}) => {
	const [pageNumber, setPageNumber] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [sortBy, setSortBy] = useState(defaultSortFieldId)
	const [sortDirection, setSortDirection] = useState(
		defaultSortAsc ? 'asc' : 'desc'
	)

	const handlePerRowsChange = (newPerPage, page) => {
		setPageNumber(page)
		setPageSize(newPerPage)
	}

	const handlePageChange = (page) => {
		setPageNumber(page)
	}

	const onSort = (column, sortDirection) => {
		setSortBy(column?.sortField || defaultSortFieldId)
		setSortDirection(sortDirection)
	}

	useEffect(() => {
		onPaginationChange({
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		})
	}, [pageNumber, pageSize, sortBy, sortDirection])

	return (
		<DataTable
			pagination
			paginationServer
			sortServer
			defaultSortAsc={defaultSortAsc}
			defaultSortFieldId={defaultSortFieldId}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			onSort={onSort}
			{...rest}
		/>
	)
}

export default PaginatedDataTable
