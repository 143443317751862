import React, { useEffect } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'
import useUser from '../../../../hooks/useUser'
import { FixMeLater } from '../../../../../types'
import { useLoanSignalRContext } from '../contexts/LoanContext'

export type LoanLockedAlertProps = {
	loan: FixMeLater // @todo replace with type when SDK is in the project
}

const LoanLockedAlert = ({ loan }: LoanLockedAlertProps) => {
	const { isLocked: loanLocked, isInSync } = loan
	const { isLoanAdmin } = useUser()
	const { useSignalREffect } = useLoanSignalRContext()
	const [locked, setLocked] = React.useState(loanLocked)

	useEffect(() => {
		setLocked(loan.isLocked)
	}, [loan])

	const handleLoanSave = (updatedLoan: FixMeLater) => {
		setLocked(updatedLoan.isLocked)
	}

	// @ts-ignore
	useSignalREffect<string, FixMeLater>('LoanSave', handleLoanSave)

	if (!locked || !isLoanAdmin || !isInSync) return null
	return (
		<div className="flex flex-row text-red-700 items-center">
			<div className="flex flex-row  items-center mr-1">
				<LockIcon fontSize="large" />{' '}
				<strong>Locked in the LOS</strong>
			</div>

			<Tooltip title="When a loan is locked in your LOS, updates (loan documents, task uploads) are queued until the loan is unlocked.">
				<InfoIcon fontSize="small" />
			</Tooltip>
		</div>
	)
}

export default LoanLockedAlert
