import React, { useEffect, useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import FieldLabel from './formControls/FieldLabel'

const ImagePicker = ({
	name,
	label,
	onChange,
	image = null,
	error,
	helperText,
	required,
	...rest
}) => {
	const colorMap = {
		success: 'text-green-900 border-green-600 bg-green-50',
		danger: 'text-red-900 border-red-700 bg-red-50',
	}

	const [color, setColor] = useState(null)
	const [file, setFile] = useState(image)

	useEffect(() => {
		if (error) {
			setColor(colorMap.danger)
		} else {
			setColor(null)
		}
	}, [error, colorMap.danger])

	const onDragEnter = () => {
		setColor(colorMap.success)
	}

	const onDragLeave = () => {
		setColor(null)
	}

	const onDrop = () => {
		setColor(null)
	}

	const onAccepted = (dropFiles) => {
		setFile(dropFiles[0])
		if (onChange) {
			onChange(dropFiles[0])
		}
	}

	const onErrorOrRejected = () => {
		setColor(colorMap.danger)
	}

	const { getRootProps, getInputProps, open, acceptedFiles } =
		useDropzone({
			onDrop,
			onDragEnter,
			onDragLeave,
			onDropRejected: onErrorOrRejected,
			onError: onErrorOrRejected,
			onDropAccepted: onAccepted,
			multiple: false,
			accept: {
				'image/jpeg': [],
				'image/png': [],
				'image/gif': [],
				'image/webp': [],
			},
			...rest,
		})

	const onRemoveClick = () => {
		acceptedFiles.splice(0, acceptedFiles.length)
		setFile(null)
		if (onChange) {
			onChange(null)
		}
	}

	return (
		<>
			<FieldLabel label={label} required={required} />
			<div className="flex flex-row items-center">
				<div
					{...getRootProps()}
					className={clsx(
						'w-52 h-10 rounded-tl rounded-bl border border-gray-300 border-r-0 flex items-center',
						color
					)}
				>
					<input {...getInputProps()} />
					{!image && (
						<p className="px-3.5 text-sm font-rubik">
							Drag 'n' drop or click
						</p>
					)}
					{image && (
						<img
							className="h-10 rounded-tl rounded-bl"
							src={image}
							alt={name}
						/>
					)}
				</div>
				<Button
					variant="contained"
					style={{
						textTransform: 'none',
						height: 40,
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						boxShadow: 'none',
					}}
					onClick={open}
				>
					Browse
				</Button>
			</div>
			{helperText && (
				<small className={error ? 'text-red-700' : undefined}>
					{helperText}
				</small>
			)}
			{file && (
				<div className="mt-2 flex items-center">
					<p className="text-sm font-rubik">{file.name}</p>
					<IconButton onClick={onRemoveClick} className="ml-3">
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			)}
		</>
	)
}

export default ImagePicker
