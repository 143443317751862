import React, { Suspense as ReactSuspense } from 'react'
import { Loading } from './Loading'

const Suspense = ({ fallback, ...rest }) => (
	<ReactSuspense
		fallback={
			fallback || (
				<div className="py-5">
					<Loading size="small" />
				</div>
			)
		}
		{...rest}
	/>
)

export default Suspense
