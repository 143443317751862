import React from 'react'
import { useWatch } from 'react-hook-form'
import { Select } from '../../../../components/formControls'

const BusinessRuleFilterValueSelect = ({
	name,
	typeName,
	control,
	errors,
	...rest
}) => {
	const type = useWatch({ control, name: typeName })
	const loanPurposeOptions = [
		{
			label: 'Purchase',
			value: 'Purchase',
		},
		{
			label: 'Cash Out Refinance',
			value: 'CashOutRefinance',
		},
		{
			label: 'Rate and Term Refinance',
			value: 'RateTermRefinance',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const loanTypeOptions = [
		{
			label: 'Conventional',
			value: 'Conventional',
		},
		{
			label: 'VA',
			value: 'VA',
		},
		{
			label: 'FHA',
			value: 'FHA',
		},
		{
			label: 'USDA',
			value: 'USDA',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const occupancyTypeOptions = [
		{
			label: 'Primary Residence',
			value: 'PrimaryResidence',
		},
		{
			label: 'Second Home',
			value: 'SecondHome',
		},
		{
			label: 'Investment property',
			value: 'InvestmentProperty',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const propertyTypeOptions = [
		{
			label: 'Attached',
			value: 'Attached',
		},
		{
			label: 'Condominium',
			value: 'Condominium',
		},
		{
			label: 'Co-Operative',
			value: 'Cooperative',
		},
		{
			label: 'Detached',
			value: 'Detached',
		},
		{
			label: 'High Rise Condo',
			value: 'HighRiseCondominium',
		},
		{
			label: 'Manufactured Housing',
			value: 'ManufacturedHousing',
		},
		{
			label: 'PUD',
			value: 'PUD',
		},
		{
			label: 'Detached Condo',
			value: 'DetachedCondo',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const employmentTypeOptions = [
		{
			label: 'Standard Wage Earner',
			value: 'StandardWageEarner',
		},
		{
			label: 'Self Employed',
			value: 'SelfEmployed',
		},
		{
			label: 'Retired',
			value: 'Retired',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const specialLoanFeatureOptions = [
		{
			label: 'Rental Income Used to qualify',
			value: 'RentalIncomeUsedToQualify',
		},
		{
			label: 'Permanent Resident Alien',
			value: 'PermanentResidentAlien',
		},
		{
			label: 'Non Permanent Resident Alien',
			value: 'NonPermanentResidentAlien',
		},
		{
			label: 'Home Ready',
			value: 'HomeReady',
		},
		{
			label: 'Home Possible',
			value: 'HomePossible',
		},
		{
			label: 'Apply to All',
			value: 'All',
		},
	]

	const filterTypeMap = {
		LoanPurpose: {
			label: 'Apply to the following Loan Purpose',
			options: loanPurposeOptions,
		},
		LoanType: {
			label: 'Apply to the following Loan Type',
			options: loanTypeOptions,
		},
		OccupancyType: {
			label: 'Apply to the following Occupancy Type',
			options: occupancyTypeOptions,
		},
		PropertyType: {
			label:
				'Apply to the following Property Type (all field 1041 options)',
			options: propertyTypeOptions,
		},
		BorrowerEmploymentType: {
			label: 'Apply to the following Employment Type',
			options: employmentTypeOptions,
		},
		SpecialLoanFeature: {
			label: 'Apply this task to the following Loan Feature',
			options: specialLoanFeatureOptions,
		},
	}

	const options = filterTypeMap[type]?.options || []

	return (
		<Select
			name={name}
			label="Value"
			menuItems={options}
			control={control}
			errors={errors}
			disabled={options.length === 0}
			{...rest}
		/>
	)
}

export default BusinessRuleFilterValueSelect
