import React from 'react'
import { Button } from '@mui/material'

const StatusButton = ({ status, ...rest }) => {
	return (
		<Button variant="outlined" color="primary" {...rest}>
			{status === 'Draft' ? 'Publish' : 'Unpublish'}
		</Button>
	)
}

export default StatusButton
