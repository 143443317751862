import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { useMemo } from 'react'
import { automatedServices } from '../services/helper'
import { useAppContext } from '../components/AppContext'

export default function ASOTriggersForm({ selected, setSelected }) {
	const { state } = useAppContext()
	const { siteConfig } = state
	const services = useMemo(() => {
		return automatedServices?.map((group) => ({
			...group,
			items: group.items.filter(
				(service) => siteConfig.asoSettings?.[service.settingId]
			),
		}))
	}, [automatedServices])

	const handleServiceCheckboxChange = (id) => {
		setSelected((current) => {
			if (current.includes(id))
				return current.filter((item) => item !== id)

			return [...current, id]
		})
	}

	return (
		<FormGroup sx={{ width: '100%', mt: 1 }}>
			<div className={`grid grid-cols-2 grid-rows-flow mb-6`}>
				{services.map((group, index) => (
					<div
						key={`${group.name}-${index}`}
						className={`mb-6 ${group.items.length > 0 ? '' : 'hidden'}`}
					>
						<div className={`font-bold`}>{group.name}</div>
						{group.items.map(
							(service) =>
								siteConfig.asoSettings?.[service.settingId] && (
									<div>
										<FormControlLabel
											key={`checkbox-${service.fieldId}`}
											className={`block`}
											control={
												<Checkbox
													checked={selected.includes(service.fieldId)}
													onChange={() =>
														handleServiceCheckboxChange(
															service.fieldId
														)
													}
												/>
											}
											label={service.name}
										/>
									</div>
								)
						)}
					</div>
				))}
			</div>
		</FormGroup>
	)
}
