import React, { useMemo } from 'react'
import ErrorList from '../components/ErrorList'
import Form from '../components/formControls/Form'
import { TextField, PasswordField } from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { getTheme } from '../config'

const theme = getTheme()

const SignInForm = ({
	onSubmit,
	loading,
	className = null,
	email = '',
	emailDisabled = false,
	errors: apiErrors = [],
}) => {
	const defaultValues = useMemo(
		() => ({
			email,
			password: '',
		}),
		[email]
	)

	const schema = yup.object().shape({
		email: yup
			.string()
			.email('Invalid email')
			.required('Email is required'),
		password: yup
			.string()
			.required('Password is required')
			.min(
				theme.validation.passwordLength,
				`Password must be at least of ${theme.validation.passwordLength}`
			),
	})

	const form = useForm({
		schema,
		defaultValues,
	})

	const {
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting },
		control,
	} = form

	const onFormSubmit = async () => {
		const data = getValues()
		await onSubmit(data)
	}

	return (
		<Form onSubmit={handleSubmit(onFormSubmit)} className={className}>
			<ErrorList errors={apiErrors} className="mb-3" />
			<TextField
				name="email"
				label="Email"
				placeholder="Email"
				className="dark:text-white"
				disabled={emailDisabled}
				fullWidth
				control={control}
				errors={errors}
			/>
			<div className="w-full mt-2">
				<PasswordField
					name="password"
					label="Password"
					placeholder="Password"
					className="dark:text-white"
					fullWidth
					control={control}
					errors={errors}
				/>
			</div>
			<div className="w-full mt-6">
				<LoadingBtn
					type="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					fullWidth
					text="Sign In"
				/>
			</div>
		</Form>
	)
}

export default SignInForm
