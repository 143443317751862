import React from 'react'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import { InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

const TaskRowName = ({ task }) => {
	const { name, description, isFromLegacySource } = task
	return (
		<>
			{name}
			{description && (
				<Tooltip title={description}>
					<InfoOutlined className="ml-2" style={{ fontSize: 16 }} />
				</Tooltip>
			)}

			{isFromLegacySource ? (
				<HistoryEduIcon
					color="disabled"
					fontSize="6"
					className="ml-1"
				/>
			) : null}
		</>
	)
}

export default TaskRowName
