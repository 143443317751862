import React, { useMemo } from 'react'
import TaskStatusBadge from './TaskStatusBadge'
import clsx from 'clsx'

const LoanTaskSummary = ({ loanTasks, className = undefined }) => {
	const loanTasksByStatus = useMemo(() => {
		return loanTasks?.reduce((groupedTasks, loanTask) => {
			groupedTasks[loanTask.status] =
				(groupedTasks[loanTask.status] || 0) + 1
			return groupedTasks
		}, {})
	}, [loanTasks])

	if (loanTasks?.length === 0) {
		return null
	}

	return (
		<div className={clsx('flex flex-row', className)}>
			{Object.keys(loanTasksByStatus).map((key) => (
				<div key={key} className="mr-2">
					<TaskStatusBadge
						status={key}
						labelPrefix={loanTasksByStatus[key]}
					/>
				</div>
			))}
		</div>
	)
}

export default LoanTaskSummary
