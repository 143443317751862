import React from 'react'
import { isDarkMode } from '../services/helper'
import { useAppContext } from './AppContext'
import { useNavigate } from 'react-router-dom'

const SiteLogo = ({ className = undefined }) => {
	const navigate = useNavigate()
	const { state } = useAppContext()
	const { siteConfig } = state

	const onClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		navigate('/')
	}

	return (
		<a onClick={onClick} href="/">
			<img
				className={className}
				src={
					isDarkMode() && siteConfig?.darkModePortalLogoUrl
						? siteConfig.darkModePortalLogoUrl
						: siteConfig?.portalLogoUrl || siteConfig?.logoUrl
				}
				alt="Logo"
			/>
		</a>
	)
}

export default SiteLogo
