import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'
import { Check } from '@mui/icons-material'

const NotificationExpandedRow = ({ data: notificationTemplate }) => {
	const {
		description,
		subject,
		status,
		emailEnabled,
		textEnabled,
		isDefault,
	} = notificationTemplate
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="Name" value={description} />
			<RowExpandedProperty label="Subject" value={subject} />
			<RowExpandedProperty label="Status" value={status} />
			<RowExpandedProperty
				label="Send Email"
				value={emailEnabled ? <Check /> : '-'}
			/>
			<RowExpandedProperty
				label="Send Text"
				value={textEnabled ? <Check /> : '-'}
			/>
			<RowExpandedProperty
				label="Default"
				value={isDefault ? <Check /> : '-'}
			/>
		</div>
	)
}

export default NotificationExpandedRow
