import React, { useMemo } from 'react'
import { TextField } from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import ErrorList from '../components/ErrorList'

const AuthCodeForm = ({
	onSubmit,
	loading,
	errors: apiErrors = [],
}) => {
	const defaultValues = useMemo(
		() => ({
			code: '',
		}),
		[]
	)

	const schema = yup.object().shape({
		code: yup.string().required(),
	})

	const form = useForm({
		schema,
		defaultValues,
	})

	const {
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting },
		control,
	} = form

	const onVerify = async () => {
		const formData = getValues()
		await onSubmit(formData)
	}

	return (
		<form onSubmit={handleSubmit(onVerify)} noValidate>
			<ErrorList errors={apiErrors} className="mb-3" />
			<div className="w-64 sm:w-96 sm:px-5 dark:text-white mb-5">
				<TextField
					name="code"
					className="w-full"
					label="Code"
					placeholder="Enter code"
					control={control}
					errors={errors}
				/>
			</div>
			<LoadingBtn
				disabled={isSubmitting || loading}
				loading={isSubmitting || loading}
				fullWidth
				variant="contained"
				type="submit"
				text="Submit"
			/>
		</form>
	)
}

export default AuthCodeForm
