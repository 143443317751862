import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import NewVersionForm from '../../forms/NewVersionForm'
import { useTheme } from '@mui/material/styles'

const ModalNewVersion = ({
	open,
	onSubmit,
	onDialogClose,
	loading = false,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog
			open={open}
			onClose={onDialogClose}
			fullScreen={fullScreen}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>Create New Version</DialogTitle>
			<Divider />
			<DialogContent>
				<NewVersionForm
					loading={loading}
					onSubmit={onSubmit}
					onCancel={onDialogClose}
				/>
			</DialogContent>
		</Dialog>
	)
}

export default ModalNewVersion
