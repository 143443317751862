import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { ButtonGroup } from '@mui/material'
import { clone } from 'lodash'
import { LOCalculatorForm } from './LOCalculatorForm'
import LOCalculatorResults from './LOCalculatorResults'
import { Button, LoadingBtn } from '../Button'
import LOLoanComparisonScenario from './LOLoanComparisonScenario'
import {
	getLoanComparisons,
	saveLoanComparison,
	deleteLoanComparison,
	Roles,
	saveLoanComparisonPdf,
	getLoanDocuments,
} from '../../services/client'
import { Loading } from '../Loading'
import { getErrorMessage } from '../../services/helper'
import { useAlert } from '../../hooks'
import { useVenti } from 'venti'
import { getTheme } from '../../config'
import { LOLockedCalculatorForm } from './LOLockedCalculatorForm'
import { useAppContext } from '../AppContext'
import { navigationLinking } from '../../services/navigation'
import { generatePath, useNavigate } from 'react-router-dom'
import { useMixpanel } from '../../hooks/useMixpanel'
import { eventTypes } from '../../services/constants'

const theme = getTheme()

const LOLoanComparisonForm = ({
	formData,
	setFormData,
	width,
	handleZipCodeBlur,
	buttonDisabled,
	loading,
	submitAction,
	counties,
	handleCountyChange,
}) => {
	const { alert } = useAlert()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const mixpanel = useMixpanel()
	const { siteConfig, user } = state

	const { loanID } = formData
	const defaultScenario = {
		loanType: '',
		purchasePrice: '',
		propertyValue: '',
		downpaymentAmount: '',
		downpaymentPercent: '',
		loanAmount: '',
		totalAssets: '',
		monthlyPayment: '',
		totalMortgageAmount: '',
		dtiFront: '',
		dtiBack: '',
		ltvFront: '',
		ltvBack: '',
		totalCashToClose: '',
		apr: '',
		mi: '',
		miFactor: '',
		rate: '',
	}

	const [scenarios, setScenarios] = useState([
		clone(defaultScenario),
		clone(defaultScenario),
	])
	const [comparisonLoading, setComparisonLoading] = useState(false)
	const [scenarioLoading, setScenarioLoading] = useState(false)

	const handleError = useCallback((e) => {
		alert(getErrorMessage(e), { severity: 'error' })
		ventiState.set(
			theme.storageKeys.errorMessage,
			e?.data?.message || theme.api_messages.server_error
		)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (loanID)
			(async () => {
				try {
					setComparisonLoading(true)
					const data = await getLoanComparisons(loanID)
					setScenarios(clone(data.scenarios))
				} catch (e) {
					handleError(e)
				} finally {
					setComparisonLoading(false)
				}
			})()
	}, [loanID, handleError])

	const onCopyScenarioClick = async (index) => {
		try {
			setScenarioLoading(true)
			const result = await saveLoanComparison(loanID, index, formData)
			mixpanel.trackEvent(eventTypes.SAVE_TO_S2_S3, {
				loanId: loanID,
			})
			const newScenarios = clone(scenarios)
			newScenarios[index] = result
			setScenarios(newScenarios)
		} catch (e) {
			handleError(e)
		} finally {
			setScenarioLoading(false)
		}
	}

	const onClearScenarioClick = async (index) => {
		try {
			setScenarioLoading(true)
			await deleteLoanComparison(loanID, index)
			const newScenarios = clone(scenarios)
			newScenarios[index] = clone(defaultScenario)
			setScenarios(newScenarios)
		} catch (e) {
			handleError(e)
		} finally {
			setScenarioLoading(false)
		}
	}

	const hasResult = useMemo(() => {
		const resultKeys = [
			'monthlyPayment',
			'principleAndInterestPITIField',
			'dtiFront',
			'dtiBack',
			'ltvFront',
			'ltvBack',
			'totalCashtoClose',
			'apr',
		]
		let result = false
		for (let i = 0; i < resultKeys.length; i++) {
			if (formData[resultKeys[i]]) {
				result = true
				break
			}
		}
		return result
	}, [formData])

	const handleGeneratePdf = async () => {
		ventiState.set(
			theme.storageKeys.pleaseWaitMessage,
			`Please be patient while the loan comparison document is generated`
		)

		try {
			await saveLoanComparisonPdf(loanID, {
				siteConfigurationID: siteConfig.id,
			})

			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				'Fetching loan documents'
			)
			await ventiState.set(
				theme.storageKeys.loanDocs,
				await getLoanDocuments(loanID)
			)
			mixpanel.trackEvent(eventTypes.GENERATE_LOAN_COMPARISON_PDF, {
				loanId: loanID,
			})

			const navigatePath = generatePath(
				`/${navigationLinking.Documents}`,
				{ loanId: loanID }
			)
			navigate(navigatePath)
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e),
			})
		}
	}

	return (
		<div className="flex flex-row flex-wrap">
			<div className="md:w-1/3 md:pr-10 w-full pr-0 mb-5 md:mb-0">
				<p className="text-lg font-rubik font-bold dark:text-white">
					Scenario 1
				</p>
				<LoadingBtn
					id={`LOCalculatorFormGetRateButton`}
					text={
						formData.loanLocked
							? `Loan is Locked`
							: `Update Loan & Calculate Payment`
					}
					onClick={submitAction}
					loading={loading}
					disabled={
						loading ||
						buttonDisabled ||
						formData.loanLocked ||
						scenarioLoading
					}
					variant="contained"
					fullWidth={true}
					style={{ marginBottom: 12, marginTop: 12 }}
				/>

				<ButtonGroup
					variant="contained"
					aria-label="Copy Scenarios"
					style={{ marginBottom: 12, marginTop: 12 }}
					fullWidth
				>
					<Button
						text="Save to S2"
						onClick={() => onCopyScenarioClick(0)}
						disabled={scenarioLoading || !hasResult}
					/>
					<Button
						text="Save to S3"
						onClick={() => onCopyScenarioClick(1)}
						disabled={scenarioLoading || !hasResult}
					/>
				</ButtonGroup>

				<Button
					text="Generate Loan Comparison PDF"
					onClick={handleGeneratePdf}
					disabled={scenarioLoading || !hasResult}
					fullWidth={true}
					style={{ marginBottom: 24, marginTop: 12 }}
				/>

				{user.role === Roles.loanOfficer ||
				user.role === Roles.branchManager ? (
					<LOCalculatorForm
						loanLocked={formData.loanLocked}
						handleZipCodeBlur={handleZipCodeBlur}
						counties={counties}
						handleCountyChange={handleCountyChange}
						formData={formData}
						setFormData={setFormData}
						width={width}
						stacked
					/>
				) : (
					<LOLockedCalculatorForm
						loanLocked={formData.loanLocked}
						handleZipCodeBlur={handleZipCodeBlur}
						counties={counties}
						handleCountyChange={handleCountyChange}
						formData={formData}
						setFormData={setFormData}
						buttonDisabled={buttonDisabled}
						width={width}
					/>
				)}

				<LOCalculatorResults width={width} formData={formData} />
			</div>

			{scenarios.map((scenario, i) => {
				let hasValues = false
				for (const key of Object.keys(scenario)) {
					if (scenario[key]) {
						hasValues = true
						break
					}
				}
				return (
					<div
						key={`loan-comparison-scenario-${i}`}
						className="md:w-1/3 md:pr-10 w-full pr-0 mb-5 md:mb-0"
					>
						<p className="text-lg font-rubik font-bold dark:text-white">
							Scenario {i + 2}
						</p>
						{comparisonLoading && <Loading size="small" />}
						{!comparisonLoading && (
							<>
								{!hasValues && (
									<p className="mt-2">No saved scenario</p>
								)}
								{hasValues && (
									<>
										<div className="my-3">
											<LoadingBtn
												loading={scenarioLoading}
												text="Clear"
												onClick={() => onClearScenarioClick(i)}
											/>
										</div>
										{!scenarioLoading && (
											<LOLoanComparisonScenario
												width={width}
												scenario={scenario}
												loanData={formData}
											/>
										)}
									</>
								)}
							</>
						)}
					</div>
				)
			})}
		</div>
	)
}

export default LOLoanComparisonForm
