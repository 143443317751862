import React from 'react'
import ErrorList from '../ErrorList'

const Form = ({ errors, children, onSubmit, ...rest }) => {
	const onKeyDown = (event) => {
		if (event?.keyCode === 13) {
			onSubmit()
		}
	}

	return (
		<form
			{...rest}
			onSubmit={onSubmit}
			onKeyDown={onKeyDown}
			noValidate
		>
			<ErrorList className="my-3" errors={errors} />
			{children}
		</form>
	)
}

export default Form
