import React, { useState } from 'react'
import { a11yProps } from '../../../services/helper'
import Page from '../../../components/Page'
import { Tab, Tabs } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import Current from './Current'
import { useTheme } from '@mui/material/styles'
import Prospects from './Prospects'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function Partners() {
	const themeMUI = useTheme()

	const [tab, setTab] = useState(0)

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	return (
		<Page title="Partners" isFullWidth={true}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={tab}
				onChange={handleTabChange}
				aria-label="Tabs"
			>
				<Tab label="Current Partners" {...a11yProps(0)} />
				<Tab label="Prospects" {...a11yProps(1)} />
			</Tabs>
			<SwipeableViews
				axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={tab}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel value={tab} index={0} dir={themeMUI.direction}>
					<Current />
				</TabPanel>
				<TabPanel value={tab} index={1} dir={themeMUI.direction}>
					<Prospects />
				</TabPanel>
			</SwipeableViews>
		</Page>
	)
}
