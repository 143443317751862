import { createContext, useContext } from 'react'
import { getTheme } from '../../config'
import { state } from 'venti'
import { navigationLinking } from '../../services/navigation'

const theme = getTheme()

let initialUserState = null
let initialAuthTokenState = null

if (
	theme.debug.enabled === true &&
	process.env.NODE_ENV !== 'production'
) {
	initialAuthTokenState = theme.debug.token
	initialUserState = {
		...theme.debug.user,
		isLoggedIn: true,
		access_token: initialAuthTokenState,
		tokenExp: 10000000,
		loanIDs: [theme.debug.loanId],
	}
	state.set(theme.storageKeys.authToken, initialAuthTokenState)
	state.set(theme.storageKeys.user, initialUserState)
}

export const defaultState = {
	siteConfig: null,
	socialLinks: null,
	legalLinks: null,
	user: initialUserState,
	authToken: initialAuthTokenState,
	invite: null,
	redirectUrl: navigationLinking.Portal,
	impersonationRequest: null,
}

export const AppContext = createContext({
	state: defaultState,
	dispatch: () => {},
})

export const useAppContext = () => useContext(AppContext)
