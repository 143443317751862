import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import Tab from '@mui/material/Tab'
import { useVenti } from 'venti'
import { useAppContext } from '../../../components/AppContext'
import Page from '../../../components/Page'
import { LoanInfo } from '../../../components/loanAppDetails/LoanInfo'
import { PersonalInfo } from '../../../components/loanAppDetails/PersonalInfo'
import { RealEstateOwned } from '../../../components/loanAppDetails/RealEstateOwned'
import { EmploymentIncome } from '../../../components/loanAppDetails/EmploymentIncome'
import { ModalPleaseWait } from '../../../components/modals/ModalPleaseWait'
import { Assets } from '../../../components/loanAppDetails/Assets'
import { getTheme } from '../../../config'
import { getLoan, getLoanData, Roles } from '../../../services/client'
import Tabs from '@mui/material/Tabs'
import { a11yProps, getErrorMessage } from '../../../services/helper'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useAlert } from '../../../hooks'
import IconButton from '@mui/material/IconButton'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { useTheme } from '@mui/material/styles'
import TabPanel from '../../../components/TabPanel'
import LoanStatus from '../../../components/loanAppDetails/LoanStatus'
import LoanUsersTable from '../../../components/loanAppDetails/LoanUsersTable'
import withAuth from '../../../components/withAuth'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import LoanLockedAlert from './components/LoanLockedAlert'

const theme = getTheme()

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

function LoanDetailsContent() {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { loanId } = useParams()
	const loanData = ventiState.get(theme.storageKeys.loanData)
	const overrideLoanId = ventiState.get(
		theme.storageKeys.editingLoanApplicationId
	)
	const { state } = useAppContext()
	const { siteConfig, user } = state

	const [tab, setTab] = useState(
		siteConfig.enabledServices.loanStatus ? 0 : 1
	)
	const [tabs, setTabs] = useState([])
	const [fetching, setFetching] = useState(false)
	const [displayData, setDisplayData] = useState({})

	const themeMUI = useTheme()

	const {
		// isError: isLoanError,
		data: loan,
		refetch: refetchLoan,
	} = useQuery({
		queryKey: [queryKeys.loan, loanId],
		queryFn: () => getLoan(loanId),
	})

	/*
	Suppress error in the event its an Encompass out loan
	@todo - Add back when we have local loan pipeline so we dont have Encompass only loans
	useEffect(() => {
		if (isLoanError) {
			alert('There was a problem fetching the loan', {
				severity: 'error',
			})
		}
	}, [isLoanError])
	*/

	useEffect(() => {
		let routes = []

		if (siteConfig.enabledServices.loanStatus) {
			routes.push({
				key: 'loanStatus',
				title: 'Loan Status',
				index: 0,
			})
		}

		routes = [
			...routes,
			{
				key: 'loanInformation',
				title: 'Loan and Property',
				index: 1,
			},
			{
				key: 'personalInformation',
				title: 'Personal Information',
				index: 2,
			},
		]

		if (![Roles.realtor].includes(user.role)) {
			routes = [
				...routes,
				...[
					{
						key: 'currentEmployerAndAdditionalIncomeSource',
						title: 'Employment',
						index: 3,
					},
					{ key: 'assetAccount', title: 'Asset Accounts', index: 4 },
					{ key: 'realEstateOwned', title: 'Real Estate', index: 5 },
					// { key: 'declarations', title: 'Declarations', index: 6 },
					// { key: 'governmentMonitoring', title: 'Government Monitoring', index: 7 }
				],
			]
		}

		routes.push({
			key: 'people',
			title: 'People',
			index: 6,
		})

		setTabs(routes)
	}, [user])

	useEffect(() => {
		if (loanData) {
			setDisplayData(loanData)
		} else if (overrideLoanId) {
			fetchLoanData(overrideLoanId)
		}
	}, [overrideLoanId, loanData])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const fetchLoanData = async (overrideLoanId = '') => {
		setFetching(true)
		try {
			const data = await getLoanData(overrideLoanId || loanId)
			await refetchLoan()
			setDisplayData(data)
			ventiState.set(theme.storageKeys.loanData, data)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setFetching(false)
		}
	}

	return (
		<Page
			page="app-loan-details"
			title="Loan Details"
			isFullWidth={true}
		>
			<div className="pl-5 pr-5 pb-10 pt-5">
				<div className="flex items-center">
					<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
						Loan Details
					</p>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="refresh"
						onClick={() => fetchLoanData()}
						disabled={fetching}
						sx={bgColor}
					>
						<img
							className="w-14 mt-1"
							src={
								themeMUI.palette.mode === 'light'
									? RefreshPNG
									: RefreshDarkPNG
							}
							onMouseOver={(e) =>
								(e.currentTarget.src =
									themeMUI.palette.mode === 'light'
										? RefreshGIF
										: RefreshDarkGIF)
							}
							onMouseOut={(e) =>
								(e.currentTarget.src =
									themeMUI.palette.mode === 'light'
										? RefreshPNG
										: RefreshDarkPNG)
							}
							alt="refresh"
						/>
					</IconButton>
					{loan && <LoanLockedAlert loan={loan} />}
				</div>

				<ModalPleaseWait
					logo={siteConfig.logoUrl}
					visible={
						fetching &&
						[Roles.loanOfficer, Roles.branchManager].includes(
							user.role
						)
					}
					message={`Fetching loan information`}
				/>

				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					{tabs.map((route) => (
						<Tab
							value={route.index}
							label={route.title}
							key={route.key}
							{...a11yProps(route.index)}
						/>
					))}
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					{siteConfig.enabledServices.loanStatus && (
						<TabPanel value={tab} index={0} dir={themeMUI.direction}>
							<Card className="min-h-screen overflow-auto">
								<CardContent>
									{!fetching &&
										displayData &&
										Object.keys(displayData).length > 0 && (
											<LoanStatus loan={displayData} />
										)}
								</CardContent>
							</Card>
						</TabPanel>
					)}
					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								<LoanInfo data={displayData} loading={fetching} />
							</CardContent>
						</Card>
					</TabPanel>
					{/*<TabPanel value={tab} index={1}>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<PropertyInfo data={displayData} loading={fetching} />
						</CardContent>
					</Card>
				</TabPanel>*/}
					<TabPanel value={tab} index={2} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								<PersonalInfo data={displayData} loading={fetching} />
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={3} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								{[0, 1, 2].map((index) => (
									<EmploymentIncome
										key={`Employment${index}`}
										data={displayData}
										loading={fetching}
										index={index}
									/>
								))}
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={4} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								{[0, 1, 2].map((index) => (
									<Assets
										key={`AssetAccount${index}`}
										data={displayData}
										loading={fetching}
										index={index}
									/>
								))}
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={5} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								{[0, 1, 2].map((index) => (
									<RealEstateOwned
										key={`RealEstateOwned${index}`}
										data={displayData}
										loading={fetching}
										index={index}
									/>
								))}
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={6} dir={themeMUI.direction}>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								<LoanUsersTable loanId={loanId} />
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}

export default withAuth(LoanDetailsContent)
