import {
	Button as ButtonMUI,
	ButtonProps as MuiButtonProps,
} from '@mui/material'
import React from 'react'

export type ButtonProps = {
	text: string
} & MuiButtonProps

export const Button = ({
	text,
	variant = 'contained',
	style,
	...rest
}: ButtonProps) => (
	<ButtonMUI
		variant={variant}
		style={{
			textTransform: 'none',
			fontSize: '14px',
			paddingBottom: '4px',
			...style,
		}}
		{...rest}
	>
		{text}
	</ButtonMUI>
)
