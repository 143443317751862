import React from 'react'
import DocumentBucketAutocomplete from './DocumentBucketAutocomplete'
import { Controller } from 'react-hook-form'
import useFormField from '../../../hooks/useFormField'

const DocumentBucketAutocompleteField = ({
	name,
	control,
	errors,
	defaultValue,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	if (!control) {
		return <DocumentBucketAutocomplete {...rest} />
	}

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field }) => (
				<DocumentBucketAutocomplete
					{...field}
					error={!!error}
					helperText={error ? error.message : undefined}
					onChange={(e) => {
						field.onChange(e ? e.value : null)
					}}
					{...rest}
				/>
			)}
		/>
	)
}

export default DocumentBucketAutocompleteField
