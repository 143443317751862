import React from 'react'
import RowExpandedProperty from '../../../components/RowExpandedProperty'

const RequestQueueExpandedRow = ({ data: requestQueue }) => {
	const { userEmail, errorMessage, endpoint } = requestQueue
	return (
		<div className="p-5 dark:bg-[#121212]">
			<RowExpandedProperty label="User Email" value={userEmail} />
			<RowExpandedProperty
				label="Error Message"
				value={errorMessage}
			/>
			<RowExpandedProperty label="Endpoint" value={endpoint} />
		</div>
	)
}

export default RequestQueueExpandedRow
