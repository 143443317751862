import React from 'react'
import Dialog from '../../../../components/modals/Dialog'
import TaskCheckboxForm from '../forms/TaskCheckboxForm'

const ModalImportDiffTasks = ({
	tasks,
	onSubmit,
	onClose,
	...rest
}) => {
	return (
		<Dialog
			title="Import Tasks"
			maxWidth="md"
			fullWidth
			onClose={onClose}
			{...rest}
		>
			<TaskCheckboxForm
				tasks={tasks}
				onClose={onClose}
				onSubmit={onSubmit}
				submitLabel="Import"
			/>
		</Dialog>
	)
}

export default ModalImportDiffTasks
