import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { ChromePicker } from 'react-color'
import { Button } from '../Button'

export const ModalColorPicker = ({
	modalColorPicker,
	setModalColorPicker,
	setFormData,
	formData,
}) => {
	const [color, setColor] = useState(
		modalColorPicker?.dataColor ?? '#fff'
	)

	useEffect(() => {
		setColor(modalColorPicker?.dataColor ?? '#fff')
	}, [modalColorPicker])

	const handleChangeComplete = (color) => {
		setColor(color.hex)
	}

	const cancel = () => {
		setModalColorPicker({ ...modalColorPicker, open: false })
	}

	const saveColor = (param) => {
		setFormData((formData) => ({
			...formData,
			[param]: color,
		}))

		setModalColorPicker({ ...modalColorPicker, open: false })
	}

	return (
		<Dialog
			open={modalColorPicker.open}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-4 md:max-w-lg py-8 md:px-10">
				<ChromePicker
					color={color}
					onChangeComplete={handleChangeComplete}
					disableAlpha={true}
				/>
				<div className="flex flex-row justify-between mt-5 w-full">
					<Button
						id="ModalYesNoOKButton"
						text="Cancel"
						onClick={cancel}
						style={{ width: 110 }}
						variant="outlined"
					/>
					<Button
						id="ModalYesNoYesButton"
						text="Save"
						onClick={() => saveColor(modalColorPicker.colorName)}
						style={{ width: 110 }}
						variant="contained"
					/>
				</div>
			</div>
		</Dialog>
	)
}
