import React from 'react'
import { Select } from './index'

const ActiveDeletedSelect = ({ onChange, value, ...rest }) => {
	const activeDeletedOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'active', label: 'Active' },
		{ value: 'deleted', label: 'Deleted' },
	]

	return (
		<Select
			{...rest}
			label="Active/Deleted"
			menuItems={activeDeletedOptions}
			selectProps={{
				onChange,
				value,
			}}
		/>
	)
}

export default ActiveDeletedSelect
