import React, { useState } from 'react'
import {
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@mui/material'
import { formatDate } from '../../services/helper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ListItem from '@mui/material/ListItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

const VersionListItem = ({ version, onItemClick, onDeleteClick }) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const showActionMenu = (e) => {
		e.stopPropagation()
		setAnchorEl(e.currentTarget)
	}

	const hideActionMenu = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				className="mt-8 sm:mt-10"
				onClose={hideActionMenu}
			>
				<MenuItem onClick={() => onDeleteClick(version)}>
					Delete
				</MenuItem>
			</Menu>
			<ListItem
				key={version.id}
				className={
					version.isActive
						? 'bg-gray-100 !text-[rgba(0,0,0,0.87)] group is-active'
						: ''
				}
				button={!version.isActive}
				onClick={onItemClick ? () => onItemClick(version) : undefined}
			>
				<ListItemText
					className="group-[.is-active]:*:!text-[rgba(0,0,0,0.87)]"
					primary={version.name}
					secondary={formatDate(version.createdAt, false)}
				/>

				<ListItemSecondaryAction>
					{version.isActive && (
						<>
							<small className="mr-1">Active</small>
							<CheckCircleIcon
								color="success"
								fontSize="small"
								className="mr-2"
							/>
						</>
					)}
					{!version.isActive && (
						<IconButton
							size="small"
							edge="start"
							onClick={showActionMenu}
						>
							<MoreVertIcon />
						</IconButton>
					)}
				</ListItemSecondaryAction>
			</ListItem>
		</>
	)
}
export default VersionListItem
