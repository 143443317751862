import { useCallback } from 'react'
import { noop } from 'lodash'
import { useAlertsDispatch } from '../components/Alerts/AlertsContext'
import { alertsActions } from '../components/Alerts/AlertsReducer'

const defaultState = { dispatch: noop }

export function useAlert() {
	const { dispatch } = useAlertsDispatch() ?? defaultState

	/**
	 * @param {string} - text
	 * param {object} - options
	 * 	alert(text, { severity: "success" })
	 * 	alert(text, { severity: "warning" })
	 * 	alert(text, { severity: "info" })
	 * 	alert(text, { severity: "error" })
	 */
	const alert = useCallback(
		(text, options = {}) => {
			dispatch({
				type: alertsActions.add,
				payload: { text, ...options },
			})
		},
		[dispatch]
	)

	const remove = useCallback(
		(id) => {
			dispatch({ type: alertsActions.remove, payload: id })
		},
		[dispatch]
	)

	const clear = useCallback(
		(id) => {
			dispatch({ type: alertsActions.clear })
		},
		[dispatch]
	)

	return {
		alert,
		remove,
		clear,
	}
}
