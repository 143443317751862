export const recipientPreviewData = {
	FirstName: 'John',
	LastName: 'Doe',
	Email: 'jdoe@example.com',
}

export const loanPreviewData = {
	LoanNumber: '123456789',
	LoanPurpose: 'Home Purchase',
	LoanType: 'Conventional',
	LoanAmount: '350000',
	DownPaymentAmount: '70000',
	LoanTerm: '30 years',
	LTV: '80%',
	CLTV: '80%',
	Rate: '3.5%',
	TXReviewedAdditionalItems: 'None',
	TXRequiredAdditionalItems: 'None',
	BorrowerFirstName: 'John',
	BorrowerLastName: 'Doe',
	BorrowerEmail: 'johndoe@example.com',
	SubjectPropertySalePrice: '420000',
	HasCoBorrower: 'Yes',
	CoBorrowerFirstName: 'Jane',
	CoBorrowerLastName: 'Doe',
	CoBorrowerEmail: 'janedoe@example.com',
	BorrowerSuffix: 'Jr',
	Referrer: 'Google',
	BorrowerContactPhone: '555-555-5555',
	BorrowerDOB: '01/01/1980',
	BorrowerMaritalStatus: 'Married',
	BorrowerHomeAddressStreet: '123 Main St',
	BorrowerHomeAddressUnitType: 'Apt',
	BorrowerHomeAddressUnitNumber: '4B',
	BorrowerHomeAddressCity: 'Los Angeles',
	BorrowerHomeAddressState: 'CA',
	BorrowerHomeAddressZip: '90001',
	BorrowerMailingAddressStreet: '123 Main St',
	BorrowerMailingAddressUnitType: 'Apt',
	BorrowerMailingAddressAddressCity: 'Los Angeles',
	BorrowerMailingAddressAddressState: 'CA',
	BorrowerMailingAddressAddressZip: '90001',
	SubjectPropertyAddressCity: 'Los Angeles',
	SubjectPropertyAddressState: 'CA',
	SubjectPropertyAddressZip: '90001',
	SubjectPropertyNumUnits: '1',
	SubjectPropertyYearBuilt: '1950',
	SubjectPropertyUse: 'Primary Residence',
	SubjectPropertyResidencyType: 'Owner Occupied',
}

export const documentTypePreviewData = {
	OpenHouseFlyer: {
		Listing: {
			Description:
				'Charming 3 bedroom, 2 bath home in the heart of the city. Features a spacious living room, modern kitchen, and a large backyard. Close to schools and shopping centers.',
			Address: {
				Street: '123 Main St',
				Unit: 'Apt 1',
				City: 'Los Angeles',
				State: 'CA',
				PostalCode: '90001',
			},
			MLSNumber: '123456789',
			SalePrice: 500000,
			ActiveDate: '01/01/2024',
			ExpirationDate: '12/31/2024',
			OpenHouseDate: '09/15/2024',
			BuyerAgentCommission: '.03',
			SellerAgentCommission: '.03',
			ZillowURL:
				'https://www.zillow.com/homedetails/123-Main-St-Los-Angeles-CA-90001/123456789_zpid/',
			OpenHouseQRCode:
				'https://www.qrstuff.com/images/default_qrcode.png',
			Url: 'https://www.example.com',
			Photos: [
				{
					Name: 'Front of house',
					Description: 'Great location on a quiet street',
					File: {
						FileName: 'front-of-house.jpg',
						IsPublic: true,
						Url: 'https://www.example.com/images/front-of-house.jpg',
					},
				},
			],
		},
		LoanOfficer: {
			FirstName: 'John',
			LastName: 'Doe',
			Email: 'jdoe@email.com',
			Phone: '555-555-5555',
			Title: 'Loan Officer',
		},
	},
	PrequalLetter: loanPreviewData,
	PreapprovalLetter: loanPreviewData,
	EConsent: {
		Phone: '555-555-5555',
		Address: '123 Main St, Los Angeles, CA 90001',
		Acceptance:
			'John Doe (jdoe@email.com) on 01/01/2024 from 192.168.1.13',
	},
	TextOptIn: {
		...loanPreviewData,
		DateUtcNow: '01/01/2024 12:00:00 UTC',
	},
}

export const siteConfigurationUserPreviewData = {
	profilePhotoUrl: 'https://placehold.co/100',
	name: 'John Doe',
	email: 'jdoe@email.com',
	title: 'Loan Officer',
	nmlsid: '123456',
	phone: '555-555-5555',
}

export const commonPreviewData = {
	Date: '01/01/2024',
	Recipient: recipientPreviewData,
}
