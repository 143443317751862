import { useContext } from 'react'
import { MixpanelContext } from '../components/MixPanel/MixPanelProvider'

export const useMixpanel = () => {
	const mixpanel = useContext(MixpanelContext)

	const trackEvent = (eventName, properties) => {
		try {
			mixpanel?.track(eventName, properties)
		} catch (error) {
			console.error(`Mixpanel Tracking Error: ${error}`)
		}
	}

	const identifyAndSetUser = (me, siteConfig) => {
		let { id, firstName, lastName, role, email, accountID } = me
		try {
			mixpanel?.identify(id)
			mixpanel?.people?.set({
				$first_name: firstName,
				$last_name: lastName,
				$email: email,
				$id: id,
				$role: role,
				$siteConfigId: siteConfig.id,
				$siteConfigName: siteConfig.name,
				$accountID: accountID,
			})
		} catch (error) {
			console.error(`Mixpanel Identification Error: ${error}`)
		}
	}

	const reset = () => {
		try {
			mixpanel?.reset()
		} catch (error) {
			console.error(`Mixpanel Reset Error: ${error}`)
		}
	}

	return { trackEvent, identifyAndSetUser, reset }
}
