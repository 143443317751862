import { get } from 'lodash'

const useFormField = () => {
	const getError = (name, errors) => {
		return errors ? get(errors, name) : undefined
	}

	return {
		getError,
	}
}

export default useFormField
