import React, { useCallback, useEffect, useState } from 'react'
import { getTheme } from '../../config'
import {
	FormGroup,
	FormControlLabel,
	Switch,
	TextField,
	Checkbox,
} from '@mui/material'
import { SiteTypes } from '../../services/client'
import InheritedSwitch from '../formControls/InheritedSwitch'
import InheritedTextField from '../formControls/InheritedTextField'
import InheritanceButton from '../InheritanceButton'

const theme = getTheme()

const sortArray = (arr1, arr2) => {
	arr2.sort((a, b) => {
		const aKey = a.title
		const bKey = b.title
		return (
			arr1.findIndex((f) => f.field === aKey) -
			arr1.findIndex((f) => f.field === bKey)
		)
	})
}

const currentFeatures = [
	{
		field: 'leads',
		label: 'Leads',
		description: 'Show Leads Card on Dashboard',
		hideForSiteTypes: [],
	},
	{
		field: 'leadApp',
		label: 'Lead App (Super Short App)',
		description:
			'The shortest Loan Application with minimal information',
		hideForSiteTypes: [],
	},
	{
		field: 'mobilePrequal',
		label: 'Prequal (Short App)',
		description:
			'Loan application with enough information to make a Pre-Qualification decision',
		hideForSiteTypes: [],
	},
	{
		field: 'fullApp',
		label: 'Full App (Full URLA)',
		description:
			'The "Soup to Nuts" application with almost all of the URLA fields',
		hideForSiteTypes: [],
	},
	{
		field: 'spanishPrequal',
		label: 'Spanish Prequal (Short App)',
		description: 'Spanish version of the Prequal (Short App)',
		hideForSiteTypes: [],
	},
	{
		field: 'spanishFullApp',
		label: 'Spanish Full App (Full URLA)',
		description: 'Spanish version of the Full App (Full URLA)',
		hideForSiteTypes: [],
	},
	{
		field: 'buyerClient',
		label: 'Buyer Lead Form',
		description: 'Lead form for Buyer Clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'sellerClient',
		label: 'Seller Lead Form',
		description: 'Lead form for Seller clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'clientLifestyle',
		label: 'Client Lifestyle',
		description: 'Gather valuable information about your clients',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'openHouseForm',
		label: 'Open House Form',
		description: 'Show open house check-in form',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	{
		field: 'listingOfferForm',
		label: 'Listing Offer Form',
		description: 'Show listing offer form',
		hideForSiteTypes: [
			SiteTypes.corporate,
			SiteTypes.branch,
			SiteTypes.loanOfficer,
		],
	},
	// { field: "alexa", label: "Alexa", description: "Enabled Alexa Loan Application", hideForSiteTypes: [] },
	{
		field: 'ringCentral',
		label: 'Apply By Phone',
		description:
			'Display an icon for one-touch dialing to take applications by phone',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'pricingCalculator',
		label: 'Pricing Calculator',
		description:
			'Allow Loan Officers to Calculation Pricing.\n* Requires Pricing integration',
		hideForSiteTypes: [],
	},
	// { field: "rates", label: "Rates", description: "Display Rates in real-time. * Requires Pricing integration", hideForSiteTypes: [SiteTypes.realtor] },
	{
		field: 'borrowerTasks',
		label: 'Borrower Tasks',
		description: 'Display a list of Tasks for the user to complete',
		hideForSiteTypes: [],
	},
	{
		field: 'documents',
		label: 'Documents',
		description: 'Show Document Vault',
		hideForSiteTypes: [],
	},
	{
		field: 'loanCalculator',
		label: 'Loan Calculator',
		description:
			'Allow Loan Structuring for Loan Officers and Partners',
		hideForSiteTypes: [],
	},
	{
		field: 'emailNotifications',
		label: 'Email Notifications',
		description:
			'Notify users when an action is performed or the changes are made',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	// { field: "borrowerMissions", label: "Borrower Missions", description: "Enables Borrower Missions", hideForSiteTypes: [SiteTypes.realtor] },
	{
		field: 'docusign',
		label: 'Docusign',
		description:
			'Enables E-Signature tasks\n* Requires Docusign integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'disclosures',
		label: 'Disclosures',
		description:
			'Enables Single Sign On (SSO) to Consumer Connect\n* Requires Consumer Connect connection',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'payments',
		label: 'Payments',
		description:
			'Show a button to allow Borrowers to make first-time payments or pay a bill\n* Requires Finigree integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'voc',
		label: 'VOC (Soft Pull)',
		description: 'Enabled Verification of Credit (VOC)',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'socialSurvey',
		label: 'Experience.com Reviews',
		description:
			'Show reviews from Experience.com on landing pages\n* Requires Experience.com integration',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'encompassWeb',
		label: 'Encompass Web',
		description: 'Enable Encompass Web Tile in Loan Officer Portal',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'support',
		label: 'Support',
		description: 'Enable Support Tile in Loan Officer Portal',
		hideForSiteTypes: [SiteTypes.realtor],
	},
	{
		field: 'loanDetails',
		label: 'Loan Details',
		description: 'Show loan details to borrowers.',
		hideForSiteTypes: [],
	},
	{
		field: 'listings',
		label: 'Listings',
		description: 'Show listings on the Dashboard',
		hideForSiteTypes: [
			SiteTypes.admin,
			SiteTypes.corporate,
			SiteTypes.branch,
		],
	},
	// { field: "textNotifications", label: "Text Notifications", description: "Enabled Text Notifications", hideForSiteTypes: [SiteTypes.realtor] }
]

const uncheckChildren = {
	ringCentral: ['byPhoneTitle', 'byPhoneSubtitle'],
	mobilePrequal: ['shortAppTitle', 'shortAppSubtitle'],
	fullApp: ['fullAppTitle', 'fullAppSubtitle'],
	spanishPrequal: ['spanishPrequalTitle', 'spanishPrequalSubtitle'],
	spanishFullApp: ['spanishFullAppTitle', 'spanishFullAppSubtitle'],
	loanDetails: [
		'loanStatus',
		'loanAndProperty',
		'personalInformation',
		'employment',
		'assets',
		'realEstate',
	],
}

export const Features = ({
	formData,
	setFormData,
	inheritedSiteConfiguration,
	isTopLevelSite = false,
}) => {
	const [systemFeatures, setSystemFeatures] = useState([])
	const [servicesId, setServicesId] = useState('')
	const [checkedItems, setCheckedItems] = useState({})

	const handleToggle = (title, checkedValue) => {
		const checkedItem = systemFeatures.findIndex(
			(x) => x.title === title
		)
		const checked = { ...systemFeatures[checkedItem] }
		checked.checked =
			checkedValue !== undefined ? checkedValue : !checked.checked
		systemFeatures[checkedItem] = checked
		const enabledServices = systemFeatures.reduce(
			(acc, val) => ({ ...acc, [val.title]: val.checked }),
			{}
		)
		enabledServices.id = servicesId
		setFormData((formData) => ({
			...formData,
			enabledServices,
		}))
	}

	const handleCheckboxChange = (id) => {
		var checked = !checkedItems[id]
		setCheckedItems({
			...checkedItems,
			[id]: !checkedItems[id],
		})
		if (checked) return
		handleToggle(id, null)
		uncheckChildren[id]?.forEach((child) =>
			setFormData((formData) => ({ ...formData, [child]: null }))
		)
	}

	const handleUncheck = (id, isDisabled) => {
		handleToggle(id, null)
		if (id == 'loanDetails') {
			uncheckChildren[id]?.forEach((child) =>
				setFormData((formData) => ({
					...formData,
					enabledServices: {
						...formData.enabledServices,
						[child]: null,
					},
				}))
			)
			setCheckedItems((prev) => ({
				...prev,
				loanDetails: !isDisabled,
			}))
		} else
			uncheckChildren[id]?.forEach((child) =>
				setFormData((formData) => ({ ...formData, [child]: null }))
			)
	}

	const handleSiteconfigurationPropertyUnchecked = (id) => {
		setFormData({ ...formData, [id]: null })
	}

	useEffect(() => {
		setServicesId(formData?.enabledServices.id)
		const servicesList = Object.entries(formData?.enabledServices)
			.reduce(
				(acc, val) => [...acc, { title: val[0], checked: val[1] }],
				[]
			)
			.filter(
				(x) =>
					x.title !== 'id' &&
					x.title !== 'createdAt' &&
					x.title !== 'updatedAt' &&
					x.title !== 'deletedAt'
			)
		sortArray(currentFeatures, servicesList)
		setSystemFeatures(servicesList)
	}, [formData])

	const handleSelect = (id, checked) => {
		handleToggle(id, checked)
	}

	const handleInputChangeText = (event) => {
		const { name: field, value } = event.target
		setFormData({
			...formData,
			[field]: value,
		})
	}

	const getValueFromInheritedSiteConfig = useCallback(
		(field) => {
			return inheritedSiteConfiguration?.enabledServices
				? inheritedSiteConfiguration?.enabledServices[field || '']
				: undefined
		},
		[inheritedSiteConfiguration]
	)

	return (
		<div className="flex flex-col">
			<p className="mt-5 mb-2.5 text-sm font-rubik">
				Global System Features
			</p>
			<FormGroup>
				{systemFeatures.map((item, index) => {
					const feature = (currentFeatures || []).find(
						(c) => c.field === item.title
					)
					if (
						feature &&
						!feature.hideForSiteTypes.includes(formData.entityType)
					)
						return (
							<div
								className="pt-2 pb-2 flex flex-col sm:flex-row border-b last:border-0"
								key={feature.field}
							>
								<div className="w-full sm:w-1/2">
									<div
										className="pt-1.5 text-lg font-rubik text-button-blue w-full font-bold"
										style={{ color: theme.siteConfig.color.primary }}
									>
										{feature.label}
									</div>
									<div className="text-sm font-rubik">
										{feature.description}
									</div>
								</div>
								<div className="flex flex-col w-full sm:w-1/2 pt-2 sm:pt-0 sm:pl-5">
									<InheritedSwitch
										name={item.title}
										value={item.checked}
										inheritedValue={getValueFromInheritedSiteConfig(
											feature?.field
										)}
										id={`Features${item.title}Checkbox`}
										onCheckboxChange={(isDisabled) =>
											handleUncheck(item.title, isDisabled)
										}
										inputProps={{ 'aria-label': 'controlled' }}
										onChange={(e) =>
											handleSelect(item.title, e.target.checked)
										}
										isTopLevelSite={isTopLevelSite}
									/>
									{item.title === 'loanDetails' &&
										(item.checked ||
											(inheritedSiteConfiguration?.enabledServices
												?.loanDetails === true &&
												checkedItems.loanDetails === true)) && (
											<div className="w-1/2">
												<InheritedSwitch
													name="loanStatus"
													value={
														systemFeatures.find(
															(s) => s.title === 'loanStatus'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'loanStatus'
													)}
													label="Loan Status Tab"
													key={`${item + index}`}
													id="FeaturesLoanStatusCheckbox"
													onCheckboxChange={() =>
														handleUncheck('loanStatus')
													}
													inputProps={{
														'aria-label': 'controlled',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect(
															'loanStatus',
															e.target.checked
														)
													}
													isTopLevelSite={isTopLevelSite}
													inputProps={{
														'aria-label':
															'Loan and Property Information',
													}}
												/>
												<InheritedSwitch
													name="loanAndProperty"
													value={
														systemFeatures.find(
															(s) => s.title === 'loanAndProperty'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'loanAndProperty'
													)}
													label="Loan And Property Tab"
													key={`${item + index}`}
													id="FeaturesLoanAndPropertyCheckbox"
													onCheckboxChange={() =>
														handleUncheck('loanAndProperty')
													}
													inputProps={{
														'aria-label': 'controlled',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect(
															'loanAndProperty',
															e.target.checked
														)
													}
													isTopLevelSite={isTopLevelSite}
													inputProps={{
														'aria-label':
															'Loan and Property Information',
													}}
												/>
												<InheritedSwitch
													name="personalInformation"
													value={
														systemFeatures.find(
															(s) => s.title === 'personalInformation'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'personalInformation'
													)}
													label="Personal Information Tab"
													key={`${item + index}`}
													id="FeaturesPersonalInfoTabCheckbox"
													onCheckboxChange={() =>
														handleUncheck('personalInformation')
													}
													inputProps={{
														'aria-label': 'controlled',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect(
															'personalInformation',
															e.target.checked
														)
													}
													isTopLevelSite={isTopLevelSite}
													inputProps={{
														'aria-label': 'Personal Information',
													}}
												/>
												<InheritedSwitch
													name="employment"
													value={
														systemFeatures.find(
															(s) => s.title === 'employment'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'employment'
													)}
													label="Employment Information Tab"
													key={`${item + index}`}
													id="FeaturesEmploymentInfoTabCheckbox"
													onCheckboxChange={() =>
														handleUncheck('employment')
													}
													inputProps={{
														'aria-label': 'Employment Information',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect(
															'employment',
															e.target.checked
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
												<InheritedSwitch
													name="assets"
													value={
														systemFeatures.find(
															(s) => s.title === 'assets'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'assets'
													)}
													label="Assets Tab"
													key={`${item + index}`}
													id="FeaturesAssetsTabCheckbox"
													onCheckboxChange={() =>
														handleUncheck('assets')
													}
													inputProps={{
														'aria-label': 'Assets',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect('assets', e.target.checked)
													}
													isTopLevelSite={isTopLevelSite}
												/>
												<InheritedSwitch
													name="realEstate"
													value={
														systemFeatures.find(
															(s) => s.title === 'realEstate'
														).checked
													}
													inheritedValue={getValueFromInheritedSiteConfig(
														'realEstate'
													)}
													label="Real Estate Tab"
													key={`${item + index}`}
													id="FeaturesRealEstateTabCheckbox"
													onCheckboxChange={() =>
														handleUncheck('realEstate')
													}
													inputProps={{
														'aria-label': 'Real Estate',
													}}
													className="justify-between"
													showLabel={false}
													onChange={(e) =>
														handleSelect(
															'realEstate',
															e.target.checked
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
											</div>
										)}

									{item.title === 'ringCentral' && item.checked && (
										<div className="flex flex-col w-1/2">
											<InheritedTextField
												name="byPhoneTitle"
												label="Title Override"
												placeholder="Title Override"
												className="mr-5"
												value={formData.byPhoneTitle || ''}
												inheritedValue={
													inheritedSiteConfiguration.byPhoneTitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'byPhoneTitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
											<InheritedTextField
												name="byPhoneSubtitle"
												label="Description Override"
												placeholder="Description Override"
												value={formData?.byPhoneSubtitle || ''}
												inheritedValue={
													inheritedSiteConfiguration?.byPhoneSubtitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'byPhoneSubtitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
										</div>
									)}

									{item.title === 'mobilePrequal' && item.checked && (
										<div className="flex flex-col w-1/2">
											<InheritedTextField
												name="shortAppTitle"
												label="Title Override"
												placeholder="Title Override"
												className="mr-5"
												value={formData.shortAppTitle || ''}
												inheritedValue={
													inheritedSiteConfiguration.shortAppTitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'shortAppTitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
											<InheritedTextField
												name="shortAppSubtitle"
												label="Description Override"
												placeholder="Description Override"
												value={formData?.shortAppSubtitle || ''}
												inheritedValue={
													inheritedSiteConfiguration?.shortAppSubtitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'shortAppSubtitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
										</div>
									)}

									{item.title === 'fullApp' && item.checked && (
										<div className="flex flex-col w-1/2">
											<InheritedTextField
												name="fullAppTitle"
												label="Title Override"
												placeholder="Title Override"
												className="mr-5"
												value={formData?.fullAppTitle || ''}
												inheritedValue={
													inheritedSiteConfiguration?.fullAppTitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'fullAppTitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
											<InheritedTextField
												name="fullAppSubtitle"
												label="Description Override"
												placeholder="Description Override"
												value={formData?.fullAppSubtitle || ''}
												inheritedValue={
													inheritedSiteConfiguration?.fullAppSubtitle ||
													''
												}
												showLabel={true}
												variant="standard"
												onChange={handleInputChangeText}
												onCheckboxChange={() =>
													handleSiteconfigurationPropertyUnchecked(
														'fullAppSubtitle'
													)
												}
												isTopLevelSite={isTopLevelSite}
											/>
										</div>
									)}

									{item.title === 'spanishPrequal' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<InheritedTextField
													name="spanishPrequalTitle"
													label="Title Override"
													placeholder="Title Override"
													className="mr-5"
													value={formData.spanishPrequalTitle || ''}
													inheritedValue={
														inheritedSiteConfiguration.spanishPrequalTitle ||
														''
													}
													showLabel={true}
													variant="standard"
													onChange={handleInputChangeText}
													onCheckboxChange={() =>
														handleSiteconfigurationPropertyUnchecked(
															'spanishPrequalTitle'
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
												<InheritedTextField
													name="spanishPrequalSubtitle"
													label="Description Override"
													placeholder="Description Override"
													value={
														formData?.spanishPrequalSubtitle || ''
													}
													inheritedValue={
														inheritedSiteConfiguration?.spanishPrequalSubtitle ||
														''
													}
													showLabel={true}
													variant="standard"
													onChange={handleInputChangeText}
													onCheckboxChange={() =>
														handleSiteconfigurationPropertyUnchecked(
															'spanishPrequalSubtitle'
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
											</div>
										)}

									{item.title === 'spanishFullApp' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<InheritedTextField
													name="spanishFullAppTitle"
													label="Title Override"
													placeholder="Title Override"
													className="mr-5"
													value={formData?.spanishFullAppTitle || ''}
													inheritedValue={
														inheritedSiteConfiguration?.spanishFullAppTitle ||
														''
													}
													showLabel={true}
													variant="standard"
													onChange={handleInputChangeText}
													onCheckboxChange={() =>
														handleSiteconfigurationPropertyUnchecked(
															'spanishFullAppTitle'
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
												<InheritedTextField
													name="spanishFullAppSubtitle"
													label="Description Override"
													placeholder="Description Override"
													value={
														formData?.spanishFullAppSubtitle || ''
													}
													inheritedValue={
														inheritedSiteConfiguration?.spanishFullAppSubtitle ||
														''
													}
													showLabel={true}
													variant="standard"
													onChange={handleInputChangeText}
													onCheckboxChange={() =>
														handleSiteconfigurationPropertyUnchecked(
															'spanishFullAppSubtitle'
														)
													}
													isTopLevelSite={isTopLevelSite}
												/>
											</div>
										)}

									{item.title === 'emailNotifications' &&
										item.checked && (
											<div className="flex flex-col w-1/2">
												<TextField
													onChange={(e) =>
														setFormData((formData) => ({
															...formData,
															ccEmails: e.target.value,
														}))
													}
													value={formData?.ccEmails}
													label="CC Emails"
													placeholder="CC Emails"
													variant="standard"
													helperText="enter emails separated by a semicolon (;)"
												/>
											</div>
										)}
								</div>
							</div>
						)
				})}
			</FormGroup>
		</div>
	)
}
