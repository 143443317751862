import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useVenti } from 'venti'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled, useTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import CloseIcon from '@mui/icons-material/Close'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {
	navigationLinking,
	getActionsByRole,
	getRouteNameFromPath,
} from '../services/navigation'
import { adminMenu, disclosuresSSOSignIn } from '../services/utils'
import { useAppContextActions } from './AppContext/AppHooks'
import { useAppContext } from './AppContext'
import DashboardPNG from 'assets/lordicons/Dashboard.png'
import DashboardGIF from 'assets/lordicons/DashboardAnimated.gif'
import { getTheme } from '../config'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import DangerousIcon from '@mui/icons-material/Dangerous'
import PublishSubscribe from 'publish-subscribe-js'
import {
	allowImpersonation,
	extendImpersonation,
	getMe,
	Roles,
	startImpersonation,
	stopImpersonation,
} from '../services/client'
import { getErrorMessage, isDarkMode } from '../services/helper'
import HomeIcon from '@mui/icons-material/Home'
import { routeTypes } from '../pages/loanapp/LoanApp'
import packageInfo from '../../package.json'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material'
import { NotificationImportant } from '@mui/icons-material'
import { Button } from './Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import moment from 'moment'
import { useMixpanel } from '../hooks/useMixpanel'
import { eventTypes } from '../services/constants'
import useUser from '../hooks/useUser'

const drawerWidth = 240
const theme = getTheme()
const middleScreen = 768

const Main = styled('main', {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width }) => ({
	flexGrow: 1,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: 0,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: width > middleScreen ? drawerWidth : 0,
	}),
}))

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	justifyContent: 'space-between',
	...theme.mixins.toolbar,
}))

export const HeaderNavigation = ({ children, width }) => {
	const navigate = useNavigate()
	const route = useLocation()
	const ventiState = useVenti()
	const themeMUI = useTheme()
	const fullScreen = useMediaQuery(themeMUI.breakpoints.down('md'))
	const { state } = useAppContext()
	const { logOut, applyImpersonationRequest, applyUser } =
		useAppContextActions()
	const { siteConfig, user, authToken, impersonationRequest } = state
	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(width >= 768)
	const [portalNavItems, setPortalNavItems] = useState([])
	const [hoveredId, setHoveredId] = useState(null)
	const [impersonationMessage, setImpersonationMessage] = useState('')
	const [
		showImpersonationRequestModal,
		setShowImpersonationRequestModal,
	] = useState(false)
	const [
		showImpersonationExtensionModal,
		setShowImpersonationExtensionModal,
	] = useState(false)
	const [userIsImpersonator, setUserIsImpersonator] = useState()
	const [impersonationTimeLeft, setImpersonationTimeLeft] =
		useState(0)
	const mixpanel = useMixpanel()
	const { isAdmin } = useUser()
	const [impersonationExpiration, setImpersonationExpiration] =
		useState({
			timeLeft: 0,
			sessionExpiresAt: '',
		})

	const routeName = getRouteNameFromPath(route.pathname)
	const appPosted =
		ventiState.get(theme.storageKeys.appPosted) || false
	const loanId = ventiState.get(theme.storageKeys.loanId) || ''
	const loanData = ventiState.get(theme.storageKeys.loanData)

	const DashboardStaticIcon =
		siteConfig?.dashboardStaticIcon || DashboardPNG
	const DashboardAnimatedIcon =
		siteConfig?.dashboardAnimatedIcon || DashboardGIF

	const bgColor = {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	}

	const textUnderline = {
		'&:hover': {
			textDecoration: 'underline',
		},
	}

	let interval

	useEffect(() => {
		PublishSubscribe.subscribe('CLOSE_MENU', () => {
			handleDrawerClose()
		})

		return () => {
			PublishSubscribe.unsubscribe('CLOSE_MENU')
		}
	}, [])

	useEffect(() => {
		if (width >= 768) {
			handleDrawerOpen(true)
		}
	}, [width])

	useEffect(() => {
		if (user?.isLoggedIn && siteConfig?.id) {
			let navItems = getActionsByRole(user, siteConfig, {
				loanId,
				locked: loanData?.LoanLocked === 'True',
			})

			setPortalNavItems(navItems)
		}
	}, [user, loanId, siteConfig])

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const handleCloseInMobile = () => {
		if (width <= 768) {
			handleDrawerClose()
		}
	}

	const handleAppLink = async (item) => {
		switch (item.action) {
			case 'disclosures':
				await handleDisclosures()
				break
			default:
				navigate(`/${item.route}`, { state: item } || {})
				handleCloseInMobile()
		}
	}

	const handleDisclosures = async () => {
		disclosuresSSOSignIn(siteConfig, authToken)
	}

	const stopImpersonating = () => {
		applyUser({ ...user })
		ventiState.set(theme.storageKeys.loanId, null)
		ventiState.set(theme.storageKeys.loanData, null)
		ventiState.set(theme.storageKeys.loanTasks, null)
		ventiState.set(theme.storageKeys.loanDocs, null)
		navigate(`/${navigationLinking.Portal}`)
	}

	const changeMode = () => {
		const mode = themeMUI.palette.mode === 'light' ? 'dark' : 'light'
		mixpanel.trackEvent(eventTypes.USER_PROFILE_DARK_MODE_TOGGLED, {
			mode,
		})
		PublishSubscribe.publish('SET_DARK_MODE', mode)
	}

	const setExpirationInterval = (sessionExpiresAt) => {
		const expiration = moment(sessionExpiresAt)
		// check every minute for expiration
		interval = setInterval(() =>
			setImpersonationExpiration({
				timeLeft: expiration.diff(moment(), 'seconds'),
				sessionExpiresAt,
			})
		)
	}

	useEffect(() => {
		let message = ''
		if (
			impersonationRequest &&
			impersonationRequest.status !== 'NotFound'
		) {
			const { impersonator, impersonatee, status, sessionExpiresAt } =
				impersonationRequest

			let isImpersonator = user.email === impersonator?.email
			setUserIsImpersonator(isImpersonator)

			switch (status) {
				case 'Requested':
					if (isImpersonator)
						message = `Requesting to Impersonate ${impersonatee.firstName} ${impersonatee.lastName} (${impersonatee.email})`
					else {
						message = `${impersonator.firstName} ${impersonator.lastName} (${impersonator.email}) is requesting to impersonate you`
						setShowImpersonationRequestModal(true)
					}
					break
				case 'Allowed':
					setExpirationInterval(sessionExpiresAt)

					if (isImpersonator) {
						message = `${impersonatee.firstName} ${impersonatee.lastName} (${impersonatee.email}) has accepted your request`
						setShowImpersonationRequestModal(true)
					} else
						message = `Awaiting response from ${impersonator.firstName} ${impersonator.lastName} (${impersonator.email})`
					break
				case 'Active':
					if (
						sessionExpiresAt !==
						impersonationExpiration.sessionExpiresAt
					)
						setExpirationInterval(sessionExpiresAt)

					setShowImpersonationRequestModal(false)
					if (isImpersonator)
						message = `You are impersonating ${impersonatee.firstName} ${impersonatee.lastName} (${impersonatee.email})`
					else
						message = `${impersonator.firstName} ${impersonator.lastName} (${impersonator.email}) is impersonating you`
					break
				case 'Stopped':
					setShowImpersonationRequestModal(false)
					if (isImpersonator) {
						message = `Impersonation of ${impersonatee.firstName} ${impersonatee.lastName} (${impersonatee.email}) has ended`
						getMe()
							.then((res) => {
								applyUser({
									...user,
									...res,
									isLoggedIn: true,
								})
								setTimeout(
									() => navigate(`/${navigationLinking.Portal}`),
									300
								)
							})
							.catch((e) => {
								alert(getErrorMessage(e), { severity: 'error' })
								ventiState.set(
									theme.storageKeys.errorMessage,
									e?.data?.message || theme.api_messages.server_error
								)
							})
					} else message = `The Impersonation Session has Ended`
			}
			setImpersonationMessage(message)
		}
	}, [impersonationRequest])

	useEffect(() => {
		if (
			impersonationTimeLeft > 0 &&
			impersonationTimeLeft < 60 &&
			!showImpersonationExtensionModal
		)
			setShowImpersonationExtensionModal(true)
		if (impersonationTimeLeft < 1 && showImpersonationExtensionModal)
			(async () => await handleStopImpersonation())()
	}, [impersonationTimeLeft])

	const handleStartImpersonation = async () => {
		try {
			if (user.email !== impersonationRequest.impersonator.email) {
				await allowImpersonation(
					impersonationRequest.impersonator.email
				)
				mixpanel.trackEvent(eventTypes.IMPERSONATION_AUTHORIZED)
				setImpersonationMessage(
					`Awaiting response from ${impersonationRequest.impersonator.firstName} ${impersonationRequest.impersonator.lastName} (${impersonationRequest.impersonator.email})`
				)
			} else {
				await startImpersonation()
				mixpanel.trackEvent(eventTypes.IMPERSONATION_STARTED)
				applyUser({
					...user,
					role: impersonationRequest.impersonatee.role || 'Borrower',
				})
				setImpersonationMessage(
					`You are impersonating ${impersonationRequest.impersonatee.firstName} ${impersonationRequest.impersonatee.lastName} (${impersonationRequest.impersonatee.email})`
				)
				applyImpersonationRequest({
					...impersonationRequest,
					status: 'Active',
				})
				navigate(navigationLinking.Portal)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setShowImpersonationRequestModal(false)
		}
	}

	const handleStopImpersonation = async () => {
		if (interval) clearInterval(interval)
		if (impersonationRequest.status === 'Stopped') {
			setImpersonationMessage('')
			return
		}
		try {
			await stopImpersonation()
			mixpanel.trackEvent(eventTypes.IMPERSONATION_STOPPED)
			setImpersonationExpiration({
				timeLeft: 0,
				sessionExpiresAt: '',
			})
			setShowImpersonationExtensionModal(false)
			if (userIsImpersonator) {
				stopImpersonating()
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setShowImpersonationRequestModal(false)
		}
	}

	const handleExtendImpersonation = async () => {
		try {
			await extendImpersonation()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setShowImpersonationExtensionModal(false)
		}
	}

	/*
	 * @todo Header visibility should be refactored to not rely on the user object or MFA preferences.
	 *   An easy solution would be to toggle the display of the header at the page level instead of wrapping the entire app
	 */
	const showNavigation = useMemo(() => {
		if (!user) {
			return false
		}
		// if mfa is required and not enabled for the user, we want to force the user to enable it and not have access the portal
		return (
			authToken &&
			(siteConfig.mfaPreference !== 'Required' ||
				(siteConfig.mfaPreference === 'Required' &&
					(user.mfaEnabled || user.skippedMFAConfig || appPosted)))
		)
	}, [authToken, user, siteConfig.mfaPreference])

	if (!showNavigation) return <>{children}</>
	//if (!authToken) return <>{children}</>

	return (
		<>
			{impersonationRequest && impersonationRequest.impersonatee && (
				<>
					<Dialog open={showImpersonationRequestModal}>
						<DialogTitle>Impersonation Request</DialogTitle>
						<DialogContent>
							<Typography className="pb-3">
								{impersonationMessage}.
							</Typography>
							{!userIsImpersonator && (
								<Typography variant="caption">
									Note: This is a request to have the above named user
									impersonate you. This means they will have access to
									your information and be able to perform actions on
									your behalf. If you did not request to be
									impersonated by the user named above, please click
									"No, Don't Allow" below and contact your{' '}
									{user.role === 'Borrower'
										? 'Loan Officer'
										: 'Administrator'}
									.
								</Typography>
							)}
						</DialogContent>
						<DialogActions>
							<Button
								id="ModalYesNoOKButton"
								text={
									user.email ===
									impersonationRequest.impersonatee.email
										? 'Yes, Allow Impersonation'
										: 'Start Impersonation Session'
								}
								onClick={handleStartImpersonation}
								style={
									fullScreen
										? { width: '90%', marginBottom: 10 }
										: { width: 290 }
								}
								variant="contained"
							/>
							<Button
								id="ModalYesNoYesButton"
								text={
									user.email ===
									impersonationRequest.impersonatee.email
										? "No Don't Allow"
										: 'Cancel Request'
								}
								onClick={handleStopImpersonation}
								style={fullScreen ? { width: '90%' } : { width: 290 }}
								variant="outlined"
							/>
						</DialogActions>
					</Dialog>
					<Dialog
						open={
							showImpersonationExtensionModal &&
							userIsImpersonator &&
							impersonationTimeLeft
						}
					>
						<DialogTitle>Extend Impersonation Request</DialogTitle>
						<DialogContent>
							<Typography className="pb-3">
								Your session is about to expire.
							</Typography>
							<Typography className="pb-3">
								Would you like to extend your impersonation session?
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								id="ModalYesNoOKButton"
								text="Yes, Extend My Session"
								onClick={handleExtendImpersonation}
								style={
									fullScreen
										? { width: '90%', marginBottom: 10 }
										: { width: 290 }
								}
								variant="contained"
							/>
							<Button
								id="ModalYesNoYesButton"
								text={`No, Stop Impersonation (${impersonationTimeLeft})`}
								onClick={handleStopImpersonation}
								style={fullScreen ? { width: '90%' } : { width: 290 }}
								variant="outlined"
							/>
						</DialogActions>
					</Dialog>
				</>
			)}
			<Main open={open} width={width}>
				<AppBar
					color="inherit"
					position="relative"
					width={width}
					open={open}
				>
					<Toolbar
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div className="flex items-center">
							{Number(routeTypes.indexOf(routeName)) !== -1 && (
								<div className="pr-2 mr-1">
									<HomeIcon
										onClick={() =>
											navigate(navigationLinking.Landing)
										}
									/>
								</div>
							)}
							{!open && (
								<Tooltip title="Menu">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
										onClick={handleDrawerOpen}
									>
										<MenuIcon />
									</IconButton>
								</Tooltip>
							)}
							{loanData &&
								loanId &&
								[
									Roles.loanOfficer,
									Roles.branchManager,
									Roles.realtor,
								].includes(user.role) && (
									<div className="flex items-center">
										<p className="font-bold font-rubik dark:text-white text-sm lg:text-base">
											Impersonating {loanData?.BorrowerFirstName}{' '}
											{loanData?.BorrowerLastName}
										</p>
										<div className="ml-4 hidden sm:block">
											<Tooltip title="Stop Impersonating">
												<IconButton
													sx={{ ml: 0, p: 0, color: '#ef4444' }}
													onClick={stopImpersonating}
												>
													<DangerousIcon />
												</IconButton>
											</Tooltip>
										</div>
									</div>
								)}
						</div>
						{!!impersonationMessage && impersonationRequest && (
							<div className="text-left flex border-2 rounded px-6 py-3 shadow-md">
								{['Requested', 'Allowed'].includes(
									impersonationRequest.status
								) && (
									<NotificationImportant
										color="primary"
										sx={{ mr: 2, color: '#ef4444' }}
									/>
								)}
								<Typography variant="p">
									{impersonationMessage}
								</Typography>
								<Tooltip
									title={
										['Requested', 'Allowed'].includes(
											impersonationRequest.status
										)
											? 'Cancel Impersonation Request'
											: 'Stop Impersonation'
									}
								>
									<IconButton
										sx={{ ml: 2, p: 0, color: '#ef4444' }}
										onClick={handleStopImpersonation}
									>
										{impersonationRequest.status !== 'Stopped' ? (
											<DangerousIcon />
										) : (
											<CloseIcon />
										)}
									</IconButton>
								</Tooltip>
							</div>
						)}
						<div>
							<div className="flex items-center">
								<div onClick={handleMenu} className="text-right">
									<span
										className="text-md text-right cursor-pointer"
										style={{ color: theme.color.primary.dark_grey }}
									>
										welcome back{' '}
										<Typography variant="link" className="font-bold">
											{user?.firstName}!
										</Typography>
									</span>
								</div>
								<div className="mr-2">
									<Tooltip
										title={`Turn on ${themeMUI.palette.mode === 'light' ? 'Dark' : 'Light'} mode`}
									>
										<IconButton
											sx={{ ml: 1 }}
											onClick={changeMode}
											color="inherit"
										>
											{themeMUI.palette.mode === 'dark' ? (
												<Brightness7Icon />
											) : (
												<Brightness4Icon />
											)}
										</IconButton>
									</Tooltip>
								</div>
							</div>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								className="mt-8 sm:mt-10"
								onClose={handleClose}
							>
								{adminMenu.map((item, index) => (
									<MenuItem
										key={index}
										onClick={() => {
											if (item.route) {
												handleClose()
												navigate(
													`/${item.route}`,
													{ state: item } || {}
												)
											} else {
												handleClose()
												logOut()
											}
										}}
									>
										{item.name}
									</MenuItem>
								))}
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
				>
					<DrawerHeader>
						<img
							className="h-5 pl-2.5 mt-1"
							src={
								isDarkMode() && siteConfig?.darkModeLogoUrl
									? siteConfig.darkModeLogoUrl
									: siteConfig.logoUrl
							}
							alt="Logo"
						/>

						<Tooltip title="Close">
							<IconButton onClick={handleDrawerClose}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</DrawerHeader>
					<Divider />
					<List>
						<ListItem
							onClick={() => {
								navigate(`/${navigationLinking.Portal}`)
								handleCloseInMobile()
							}}
							id={'dashboard'}
							onMouseOver={(e) => setHoveredId(e.currentTarget.id)}
							onMouseOut={(e) => setHoveredId(null)}
							disablePadding
						>
							<ListItemButton sx={bgColor}>
								<img
									className="h-8 mr-2.5 my-1"
									alt="Dashboard"
									src={
										hoveredId === 'dashboard'
											? DashboardAnimatedIcon
											: DashboardStaticIcon
									}
									onMouseOver={(e) =>
										(e.currentTarget.src = DashboardAnimatedIcon)
									}
									onMouseOut={(e) =>
										(e.currentTarget.src = DashboardStaticIcon)
									}
								/>
								<ListItemText
									sx={textUnderline}
									primary="Dashboard"
								/>
							</ListItemButton>
						</ListItem>
						{portalNavItems.map((item, index) => {
							if (
								(!!item.route &&
									siteConfig.enabledServices[item.id] !== false) ||
								item.id === 'disclosures'
							)
								return (
									<ListItem
										key={index}
										onClick={() => handleAppLink(item)}
										disablePadding
										onMouseOver={(e) =>
											setHoveredId(e.currentTarget.id)
										}
										onMouseOut={(e) => setHoveredId(null)}
										id={index}
									>
										<ListItemButton sx={bgColor}>
											{!item.staticIcon ? (
												<item.icon
													color={theme.color.primary.dark_grey}
													className="h-8 mr-2.5 my-1"
												/>
											) : (
												<img
													className="h-8 mr-2.5 my-1"
													alt={item.id}
													src={
														parseInt(hoveredId?.toString(), 10) ===
														index
															? item.animatedIcon
															: item.staticIcon
													}
												/>
											)}
											<ListItemText
												sx={textUnderline}
												primary={item.name}
											/>
										</ListItemButton>
									</ListItem>
								)
							return null
						})}
					</List>
					{isAdmin && (
						<ListItem
							className="pl-5 opacity-40"
							disablePadding
							id={'bigposversion'}
						>
							<ListItemText
								sx={textUnderline}
								primary={`v${packageInfo.version}`}
							/>
						</ListItem>
					)}
				</Drawer>
				{children}
			</Main>
		</>
	)
}
