import {
	FormControl,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
} from '@mui/material'
import {
	defaultOptions,
	loanFieldOptions,
	groupedOptions,
	siteConfigOptions,
} from '../services/helper'
import { useAlert } from '../hooks'

export default function MailMergeSelect({
	loanOptions = false,
	siteOptions = false,
	combinedOptions = false,
	className = '',
}) {
	const { alert } = useAlert()

	const formatMailMergeVar = (opt) => {
		return opt.html ? `{{{${opt.value}}}}` : `{{${opt.value}}}`
	}

	const handleMailMergeChange = async (
		opt,
		wrapInCurlies = false
	) => {
		if (opt.value) {
			const value = wrapInCurlies
				? formatMailMergeVar(opt)
				: opt.value
			await navigator.clipboard.writeText(value)
			alert(`${value} Copied to Clipboard!`)
		}
	}

	return (
		<div className={className || `flex justify-end space-x-4`}>
			{loanOptions && (
				<FormControl className="w-1/3 sm:px-12">
					<InputLabel htmlFor="grouped-select">
						- Loan Fields -
					</InputLabel>
					<Select
						inputProps={{ paddingLeft: 2 }}
						variant={'outlined'}
						defaultValue={defaultOptions[0].value}
						onChange={(e) =>
							handleMailMergeChange(e.target.value, true)
						}
						value={``}
					>
						{loanFieldOptions.map((item, index) => (
							<MenuItem value={item} key={index}>
								{item.label}{' '}
								{!!item.value && `- ${formatMailMergeVar(item)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{siteOptions && (
				<FormControl className="w-1/3 sm:px-12 mr-10">
					<InputLabel htmlFor="grouped-select">
						- Site Configuration Fields -
					</InputLabel>
					<Select
						variant={'outlined'}
						defaultValue={defaultOptions[0].value}
						onChange={(e) =>
							handleMailMergeChange(e.target.value, true)
						}
						value={``}
					>
						{siteConfigOptions.map((item, index) => (
							<MenuItem value={item} key={index}>
								{item.label}{' '}
								{!!item.value && `- ${formatMailMergeVar(item)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{combinedOptions && (
				<FormControl className="w-1/3 sm:px-12">
					<InputLabel htmlFor="grouped-select">
						- All Mail Merge Fields -
					</InputLabel>
					<Select
						variant={'outlined'}
						defaultValue={defaultOptions[0].value}
						onChange={(e) =>
							handleMailMergeChange(e.target.value, true)
						}
						value={``}
					>
						{groupedOptions.map((item, index) => [
							<ListSubheader>{item.label}</ListSubheader>,
							item.options.map((option, optionIndex) => (
								<MenuItem value={option} key={optionIndex}>
									{option.label}{' '}
									{!!option.value &&
										`- ${formatMailMergeVar(option)}`}
								</MenuItem>
							)),
						])}
					</Select>
				</FormControl>
			)}
		</div>
	)
}
