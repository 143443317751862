import React, { useEffect, useState } from 'react'
import { ModalExportData } from '../modals/ModalExportData'
import DownloadIcon from '@mui/icons-material/Download'
import { IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
	exportToCsv,
	exportToExcel,
	exportToJSON,
} from '../../services/utils'
import { eventTypes } from '../../services/constants'
import { useMixpanel } from '../../hooks/useMixpanel'
export const ExportTable = ({ data, title }) => {
	const themeMUI = useTheme()
	const [exportData, setExportData] = useState()
	const [fileTitle, setFileTitle] = useState()
	const [exportModalOpen, setExportModalOpen] = useState(false)
	const mixpanel = useMixpanel()

	useEffect(() => {
		setExportData(data)
	}, [data])

	useEffect(() => {
		setFileTitle(title.toLowerCase().replace(' ', '-'))
	}, [title])

	const exportTable = (fileType) => {
		const eventTypeMap = {
			branches: eventTypes.BRANCH_EXPORTED,
			brands: eventTypes.BRAND_EXPORTED,
			partners: eventTypes.PARTNER_EXPORTED,
			'loan-officers': eventTypes.LOAN_OFFICER_EXPORTED,
			default: 'EXPORT',
		}

		const eventType = eventTypeMap[fileTitle] || eventTypeMap.default

		const exportFunctions = {
			csv: exportToCsv,
			xlsx: exportToExcel,
			json: exportToJSON,
		}

		const exportFunction = exportFunctions[fileType]

		if (exportFunction) {
			exportFunction(exportData, fileTitle)
		}

		mixpanel.trackEvent(eventType, {
			fileType,
			fileTitle,
		})
	}

	return (
		<>
			<ModalExportData
				visible={exportModalOpen}
				setVisible={setExportModalOpen}
				action={exportTable}
			/>
			<Tooltip title={`Export table data`}>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="Export to CSV"
					sx={{ mr: 2 }}
					onClick={() => setExportModalOpen(true)}
				>
					<DownloadIcon
						sx={{
							color:
								themeMUI.palette.mode === 'dark' ? '#fff' : '#000',
						}}
					/>
				</IconButton>
			</Tooltip>
		</>
	)
}
