import React, { useState } from 'react'
import { LoanDetailsCard } from './LoanDetailsCard'

export const EmploymentIncome = ({ data, loading, index }) => {
	const [title, setTitle] = useState(
		index === 0
			? 'Current Employer and Additional Income Sources'
			: `Current Employer ${index + 1}`
	)
	const noData = 'No employment data found.'

	let indexBasedFields = [
		{
			key: `BorrowerCurrentEmploymentContactGroup${index}`,
			title: 'Current Employer',
			value: [
				{
					key: `BorrowerCurrentEmploymentName${index}`,
					title: 'Employer Name',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentContactPhone${index}`,
					title: 'Employer Phone #',
					value: null,
				},
			],
		},
		{
			key: `BorrowerCurrentEmploymentAddressGroup${index}`,
			title: 'Current Employer Address',
			value: [
				{
					key: `BorrowerCurrentEmploymentAddressStreet${index}`,
					title: 'Street Address',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentAddressUnitType${index}`,
					title: 'Unit Type',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentAddressUnit${index}`,
					title: 'Unit / Apt #',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentAddressCity${index}`,
					title: 'City',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentAddressState${index}`,
					title: 'State',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentAddressZip${index}`,
					title: 'Zip',
					value: null,
				},
			],
		},
		{
			key: `IsBorrowerCurrentEmploymentSelfEmployed${index}`,
			title: 'Self Employed',
			value: null,
		},
		{
			key: `BorrowerCurrentEmploymentDetailsGroup${index}`,
			title: 'Employment Details',
			value: [
				{
					key: `BorrowerCurrentEmploymentStartDate${index}`,
					title: 'Start Date',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentPosition${index}`,
					title: 'Position / Job Title',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentSalary${index}`,
					title: 'Annual Wages',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentNumYearsInField0${index}`,
					title: 'Number of Years in Field',
					value: null,
				},
				{
					key: `BorrowerCurrentEmploymentNumMonthsInField0${index}`,
					title: 'Number of Months in Field',
					value: null,
				},
			],
		},
	]

	if (index === 0) {
		indexBasedFields = [
			...indexBasedFields,
			...[
				{
					key: `BorrowerIncomeOtherGroup${index}`,
					title: 'Additional Income',
					value: [
						{
							key: `BorrowerIncomeOtherSource${index}`,
							title: 'Other Income Source',
							value: null,
						},
						{
							key: `BorrowerIncomeOtherAmount${index}`,
							title: 'Monthly Income Amount',
							value: null,
						},
					],
				},
			],
		]
	}

	const incomeData = indexBasedFields.map((e) => {
		if (e.key === `BorrowerCurrentEmploymentContactGroup${index}`) {
			return {
				key: `BorrowerCurrentEmploymentContactGroup${index}`,
				title: 'Current Employer',
				value: [
					{
						key: `BorrowerCurrentEmploymentName${index}`,
						title: 'Employers Name',
						value: data[`BorrowerCurrentEmploymentName${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentContactPhone${index}`,
						title: 'Employer Phone #',
						value:
							data[`BorrowerCurrentEmploymentContactPhone${index}`],
					},
				],
			}
		} else if (e.key === `BorrowerIncomeOtherGroup${index}`) {
			let bonus =
				data[
					`BorrowerCurrentEmploymentAdditionalCompensationBonuses${index}`
				]
			let commission =
				data[
					`BorrowerCurrentEmploymentAdditionalCompensationCommissions${index}`
				]
			let overtime =
				data[
					`BorrowerCurrentEmploymentAdditionalCompensationOvertime${index}`
				]

			return {
				key: `BorrowerIncomeOtherGroup${index}`,
				title: 'Additional Monthly Income',
				value: [
					{
						key: `BorrowerCurrentEmploymentAdditionalCompensationBonuses${index}`,
						title: 'Bonus',
						value: bonus,
					},
					{
						key: `BorrowerCurrentEmploymentAdditionalCompensationCommissions${index}`,
						title: 'Comission',
						value: commission,
					},
					{
						key: `BorrowerCurrentEmploymentAdditionalCompensationOvertime${index}`,
						title: 'Overtime',
						value: overtime,
					},
				],
				// { key: `BorrowerIncomeOtherAmount`, title: 'Monthly Income Amount', value: null }
			}
		} else if (
			e.key === `BorrowerCurrentEmploymentAddressGroup${index}`
		) {
			return {
				key: `BorrowerCurrentEmploymentAddressGroup${index}`,
				title: 'Current Employer Address',
				value: [
					{
						key: `BorrowerCurrentEmploymentAddressStreet${index}`,
						title: 'Street Address',
						value:
							data[`BorrowerCurrentEmploymentAddressStreet${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentAddressUnitType${index}`,
						title: 'Unit Type',
						value:
							data[
								`BorrowerCurrentEmploymentAddressUnitType${index}`
							],
					},
					{
						key: `BorrowerCurrentEmploymentAddressUnit${index}`,
						title: 'Unit / Apt #',
						value:
							data[`BorrowerCurrentEmploymentAddressUnit${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentAddressCity${index}`,
						title: 'City',
						value:
							data[`BorrowerCurrentEmploymentAddressCity${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentAddressState${index}`,
						title: 'State',
						value:
							data[`BorrowerCurrentEmploymentAddressState${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentAddressZip${index}`,
						title: 'Zip',
						value:
							data[`BorrowerCurrentEmploymentAddressZip${index}`],
					},
				],
			}
		} else if (
			e.key === `IsBorrowerCurrentEmploymentSelfEmployed${index}`
		) {
			return {
				key: `IsBorrowerCurrentEmploymentSelfEmployed${index}`,
				title: 'Self Employed',
				value:
					data[`IsBorrowerCurrentEmploymentSelfEmployed${index}`],
			}
		} else if (
			e.key === `BorrowerCurrentEmploymentDetailsGroup${index}`
		) {
			return {
				key: `BorrowerCurrentEmploymentDetailsGroup${index}`,
				title: 'Employment Details',
				value: [
					{
						key: `BorrowerCurrentEmploymentStartDate${index}`,
						title: 'Start Date',
						value: data[`BorrowerCurrentEmploymentStartDate${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentPosition${index}`,
						title: 'Position / Job Title',
						value: data[`BorrowerCurrentEmploymentPosition${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentSalary${index}`,
						title: 'Monthly Wages',
						value: data[`BorrowerCurrentEmploymentSalary${index}`],
					},
					{
						key: `BorrowerCurrentEmploymentNumYearsInField${index}`,
						title: 'Number of Years in Field',
						value:
							data[
								`BorrowerCurrentEmploymentNumYearsInField${index}`
							],
					},
					{
						key: `BorrowerCurrentEmploymentNumMonthsInField${index}`,
						title: 'Number of Months in Field',
						value:
							data[
								`BorrowerCurrentEmploymentNumMonthsInField${index}`
							],
					},
				],
			}
		} else if (e.key === `BorrowerIncomeOtherGroup${index}`) {
			return {
				key: `BorrowerIncomeOtherGroup${index}`,
				title: 'Additional Income',
				value: [
					{
						key: `BorrowerIncomeOtherSource${index}`,
						title: 'Other Income Source',
						value: data[`BorrowerIncomeOtherSource`],
					},
					{
						key: `BorrowerIncomeOtherAmount${index}`,
						title: 'Monthly Income Amount',
						value: data[`BorrowerIncomeOtherAmount`],
					},
				],
			}
		} else {
			e.value =
				typeof data[e.key] !== 'undefined' || data[e.key] === ''
					? `$${data[e.key]}`
					: ''
		}

		e.value = data[`${e.key}${index}`]
		return e
	})

	if (index > 0 && !data[`BorrowerCurrentEmploymentName${index}`])
		return <></>

	if (data.BorrowerCurrentEmploymentName0 !== '') {
		return (
			<>
				<LoanDetailsCard title={title} data={incomeData} />
			</>
		)
	} else {
		return (
			<>
				<LoanDetailsCard
					title={noData}
					data={incomeData}
					loading={loading}
					style={{ marginBottom: 0 }}
				/>
			</>
		)
	}
}
