import React from 'react'
import {
	FormControlLabel,
	Switch as MuiSwitch,
	SwitchProps as MuiSwitchProps,
} from '@mui/material'
import { Controller, ControllerProps } from 'react-hook-form'

export type SwitchProps = {
	name?: string
	label?: string
	control?: ControllerProps['control']
	className?: string
} & Omit<MuiSwitchProps, 'className'>

const Switch = ({
	name,
	label,
	control,
	className,
	sx,
	defaultValue,
	...rest
}: SwitchProps) => (
	<div className={className}>
		{name && control && (
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<FormControlLabel
						label={label}
						control={
							<MuiSwitch
								{...field}
								{...rest}
								sx={sx}
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
							/>
						}
					/>
				)}
			/>
		)}
		{!control && (
			<FormControlLabel
				label={label}
				sx={sx}
				control={
					<MuiSwitch
						name={name}
						defaultValue={defaultValue}
						{...rest}
					/>
				}
			/>
		)}
	</div>
)

Switch.defaultProps = {
	name: undefined,
	label: undefined,
	control: undefined,
	className: undefined,
}

export default Switch
