import React, { useState } from 'react'
import Editor from '@monaco-editor/react'
import { isDarkMode } from '../services/helper'
import clsx from 'clsx'
import { LoadingDots } from './LoadingDots'

const CodeEditor = ({
	loading = false,
	className = undefined,
	onValidate = undefined,
	errors = [],
	...rest
}) => {
	const [validationErrors, setValidationErrors] = useState([])
	const handleValidate = (markers) => {
		setValidationErrors(markers)
		if (onValidate) {
			onValidate(markers)
		}
	}

	return (
		<div className={className}>
			{loading && (
				<div className="flex flex-col items-center w-full">
					<LoadingDots className="w-48" />
				</div>
			)}
			{!loading && (
				<Editor
					width="100%"
					onValidate={handleValidate}
					theme={isDarkMode() ? 'vs-dark' : 'light'}
					className={clsx(
						validationErrors.length > 0
							? 'border-red-500'
							: 'border-gray-200',
						'border-2'
					)}
					{...rest}
				/>
			)}
			{(validationErrors.length > 0 || errors.length > 0) && (
				<div className="ml-2 pt-2 w-full text-xs">
					{validationErrors.map((error) => (
						<div key={error.message} className="text-red-600">
							Line {error.startLineNumber}: {error.message}
						</div>
					))}
					{errors.map((error) => (
						<div key={error} className="text-red-600">
							{error}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default CodeEditor
