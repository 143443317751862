import React from 'react'
import MuiLoadingButton from '@mui/lab/LoadingButton'
import type { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton'

export type LoadingBtnProps = {
	indicatorOnlyOnLoading?: boolean
	text: string
} & MuiLoadingButtonProps

const LoadingBtn = ({
	text,
	variant = 'contained',
	fullWidth = true,
	indicatorOnlyOnLoading = false,
	style,
	loading,
	...rest
}: LoadingBtnProps) => (
	<MuiLoadingButton
		fullWidth={fullWidth}
		variant={indicatorOnlyOnLoading && loading ? 'text' : variant}
		style={{
			textTransform: 'none',
			fontSize: '14px',
			width: indicatorOnlyOnLoading && loading ? '65px' : undefined,
			height: indicatorOnlyOnLoading && loading ? '30px' : undefined,
			...style,
		}}
		{...rest}
	>
		{text}
	</MuiLoadingButton>
)

LoadingBtn.defaultProps = {
	indicatorOnlyOnLoading: false,
}

export default LoadingBtn
export { LoadingBtn } // legacy export. should be default export only
