import { useAppContext } from '../components/AppContext'
import { getRouteFromInvite } from '../services/utils'
import { navigationLinking } from '../services/navigation'
import {
	createLoanApplication,
	getLoanOfficer,
	getMe,
	Roles,
} from '../services/client'
import { useAppContextActions } from '../components/AppContext/AppHooks'
import { useVenti } from 'venti'
import { getTheme } from '../config'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	updateMe as apiUpdateMe,
	updateMyPhone as apiUpdateMyPhone,
} from '../services/client'
import { useMixpanel } from './useMixpanel'
import { useMemo } from 'react'

const theme = getTheme()
const useUser = () => {
	const { state } = useAppContext()
	const { applyAuthToken, applyUser } = useAppContextActions()
	const ventiState = useVenti()
	const { user: currentUser } = state
	const isLoggedIn = currentUser?.isLoggedIn
	const { siteConfig, redirectRoute, invite } = state
	const navigate = useNavigate()
	const location = useLocation()
	const mixpanel = useMixpanel()

	const postLoanData =
		ventiState.get(theme.storageKeys.postLoanData) || {}

	const getSignInRedirectURL = (user = null) => {
		if (location.state?.from) {
			return location.state.from
		}

		const signInUser = user || currentUser
		let redirectRouteFromSignIn =
			redirectRoute || navigationLinking.Portal

		if (invite?.emailAddress) {
			return getRouteFromInvite(invite, siteConfig)
		} else {
			const loRoles = [Roles.loanOfficer, Roles.branchManager]
			const isAppRedirect =
				redirectRouteFromSignIn.includes('Apply') ||
				redirectRouteFromSignIn.includes('Prequalify')
			if (
				loRoles.includes(signInUser.role) &&
				isAppRedirect &&
				!redirectRouteFromSignIn.includes('LO')
			) {
				redirectRouteFromSignIn = `LO${redirectRouteFromSignIn}`
			}

			if (
				!navigationLinking[redirectRouteFromSignIn] ||
				adminRoles.includes(signInUser.role)
			) {
				redirectRouteFromSignIn = navigationLinking.Portal
			}
			return `/${redirectRouteFromSignIn}`
		}
	}

	const setUser = (data) => {
		applyUser(data)
		ventiState.set(theme.storageKeys.user, data)
		return data
	}

	const setAuthToken = (token, expiresIn) => {
		applyAuthToken(token)
		ventiState.set(theme.storageKeys.authToken, token)

		let authTokenExp = new Date()
		authTokenExp.setMinutes(
			authTokenExp.getMinutes() + expiresIn / 60 - 5
		)
		ventiState.set(theme.storageKeys.authTokenExp, authTokenExp)
	}

	const initUser = async (authData, redirectOnSuccess = true) => {
		const { access_token: token, expires_in } = authData
		setAuthToken(token, expires_in)
		const expirationInMilliseconds = expires_in * 1000
		const tokenExp = ventiState.set(
			theme.storageKeys.authTokenExp,
			new Date(new Date().getTime() + expirationInMilliseconds)
		)
		authData.isLoggedIn = true
		const me = await getMe()
		mixpanel.identifyAndSetUser(me, siteConfig)

		if (
			[Roles.loanOfficer, Roles.branchManager].includes(me.role) &&
			me.id !== siteConfig.id &&
			!window.location.hostname.includes('localhost')
		) {
			const loanOfficer = await getLoanOfficer(me.id)
			//TODO: if more than one siteConfigurationurl we should show a list to choose
			if (
				!loanOfficer.siteConfigurations.some(
					(s) => s.id === siteConfig.id
				)
			) {
				await window.open(
					`https://${loanOfficer.siteConfigurations[0].url}`,
					'_blank',
					'noopener,noreferrer'
				)
				return
			}
		}

		authData.tokenExp = tokenExp
		const userData = {
			...authData,
			...me,
		}

		const user = setUser(userData)

		if (siteConfig.mfaPreference === 'Required' && !me.mfaEnabled) {
			return navigate(`/${navigationLinking.SMSVerification}`)
		}

		if (redirectOnSuccess) {
			const redirectURL = getSignInRedirectURL(user)
			navigate(redirectURL)
		}

		// If there's loan data to send, send it now
		if (!!postLoanData.BorrowerEmail) {
			ventiState.set(theme.storageKeys.appPosting, true)
			ventiState.set(theme.storageKeys.appSubmitted, true)
			const loanId = await createLoanApplication(postLoanData)
			ventiState.set(theme.storageKeys.loanId, loanId)
			ventiState.set(theme.storageKeys.appPosting, false)
			ventiState.unset(theme.storageKeys.postLoanData)
		}
	}

	const updateMe = async (data) => {
		await apiUpdateMe(data)
		applyUser(data)
	}

	const updateMyPhone = async (phone) => {
		await apiUpdateMyPhone(phone)
		applyUser({ ...currentUser, phone })
	}

	const isLoanOfficer = useMemo(
		() => currentUser && currentUser.role === Roles.loanOfficer,
		[currentUser]
	)

	const isBorrower = useMemo(
		() => currentUser && currentUser.role === Roles.borrower,
		[currentUser]
	)

	const isRealtor = useMemo(
		() => currentUser && currentUser.role === Roles.realtor,
		[currentUser]
	)

	const adminRoles = [Roles.admin, Roles.superAdmin]

	const isAdmin = useMemo(
		() => currentUser && adminRoles.includes(currentUser.role),
		[currentUser]
	)

	const isSettlementAgent = useMemo(
		() => currentUser && currentUser.role === Roles.settlementAgent,
		[currentUser]
	)

	const isLoanAdmin = useMemo(() => {
		const roles = [
			Roles.loanOfficer,
			Roles.branchManager,
			Roles.loanProcessor,
			Roles.loanOfficerAssistant,
		]
		return currentUser && roles.includes(currentUser.role)
	}, [currentUser])

	const canUseTasks = useMemo(() => {
		const taskRoles = [
			Roles.borrower,
			Roles.branchManager,
			Roles.loanOfficer,
			Roles.loanProcessor,
			Roles.loanOfficerAssistant,
		]
		return currentUser && taskRoles.includes(currentUser.role)
	}, [currentUser])

	return {
		user: currentUser,
		isLoggedIn,
		getSignInRedirectURL,
		setUser,
		setAuthToken,
		initUser,
		updateMe,
		updateMyPhone,
		isLoanOfficer,
		isBorrower,
		isRealtor,
		isAdmin,
		isLoanAdmin,
		isSettlementAgent,
		canUseTasks,
		adminRoles,
	}
}

export default useUser
