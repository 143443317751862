import React from 'react'
import { createSignalRContext } from 'react-signalr/signalr'
import { host } from '../../../../services/client'
import { useAppContext } from '../../../../components/AppContext'

export const LoanSignalRContext = createSignalRContext()

export const LoanSignalRContextProvider = ({ children }: any) => {
	const { state } = useAppContext()
	const { authToken } = state
	return (
		<LoanSignalRContext.Provider
			connectEnabled={!!authToken}
			accessTokenFactory={() => authToken || ''}
			dependencies={[authToken]}
			url={`${host}/hubs/loans`}
		>
			{children}
		</LoanSignalRContext.Provider>
	)
}

export const useLoanSignalRContext = () => LoanSignalRContext
