import React, { forwardRef } from 'react'
import { animated } from '@react-spring/web'
import { Alert as AlertBar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const severityGradient = {
	success: 'linear-gradient(130deg, #edf7ed, #2e7d32)',
	warning: 'linear-gradient(130deg, #fff4e5, #ed6c02)',
	info: 'linear-gradient(130deg, #e5f6fd, #0288d1)',
	error: 'linear-gradient(130deg, #fdeded, #d32f2f)',
}

export const Alert = forwardRef(
	({ severity, text, remove, life }, ref) => {
		return (
			<div className="pb-2" ref={ref}>
				<div className="relative">
					<AlertBar
						severity={severity}
						sx={{ width: '100%' }}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={remove}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						{text}
					</AlertBar>
					<animated.div
						style={{
							right: life,
							position: 'absolute',
							bottom: 0,
							left: 1,
							width: 'auto',
							backgroundImage: severityGradient[severity],
							height: '2px',
						}}
					/>
				</div>
			</div>
		)
	}
)
