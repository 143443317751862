import React, { useMemo } from 'react'
import AuthPage from './AuthPage'
import withAuth from '../../components/withAuth'
import MobilePhoneVerification from '../../components/MobilePhoneVerification'
import useUser from '../../hooks/useUser'
import { Roles } from '../../services/client'
import { useAppContext } from '../../components/AppContext'
import { useNavigate } from 'react-router-dom'

const SMSVerification = () => {
	const { state } = useAppContext()
	const navigate = useNavigate()
	const { siteConfig } = state
	const { user, getSignInRedirectURL } = useUser()

	const skipsRemaining = useMemo(() => {
		return (
			siteConfig.allowedLoginsWithoutMFA - user.loginsWithoutMFACount
		)
	}, [siteConfig.allowedLoginsWithoutMFA, user.loginsWithoutMFACount])

	const canSkip = useMemo(() => {
		return (
			siteConfig.mfaPreference !== 'Required' ||
			(user.role === Roles.borrower && skipsRemaining > 0)
		)
	}, [user])

	const onComplete = () => {
		const redirectURL = getSignInRedirectURL()
		navigate(redirectURL)
	}

	return (
		<AuthPage title="Setup Multi-factor Authentication">
			<p className="text-lg sm:text-2xl font-bold mb-4 dark:text-white">
				Setup Multi-factor Authentication (MFA)
			</p>
			<MobilePhoneVerification
				onComplete={onComplete}
				canSkip={canSkip}
				skipsRemaining={skipsRemaining}
				homeButtonEnabled={true}
			/>
		</AuthPage>
	)
}

export default withAuth(SMSVerification)
