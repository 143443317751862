import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import { searchTasks } from '../../../services/client'
import {
	Autocomplete,
	CircularProgress,
	TextField,
} from '@mui/material'

const TaskAutocomplete = ({
	onChange,
	error,
	helperText,
	required,
	label = 'Task',
	excludedIds = [],
	...rest
}) => {
	const [task, setTask] = useState(null)

	const {
		isFetching,
		isRefetching,
		isError,
		data: tasksResult,
	} = useQuery({
		queryKey: [queryKeys.tasks],
		queryFn: () => searchTasks({ isGlobal: true, excludedIds }),
	})

	const labelTxt = useMemo(
		() => (required ? `${label} *` : label),
		[required, label]
	)

	const handleChange = (event, value) => {
		setTask(value)
		if (onChange && task !== value) {
			onChange(value)
		}
	}

	const getOptionLabel = (option) => {
		return option.name
	}

	const loading = useMemo(
		() => isFetching || isRefetching,
		[isFetching, isRefetching]
	)

	return (
		<Autocomplete
			{...rest}
			id="task-autocomplete"
			value={task}
			options={tasksResult?.rows || []}
			loading={loading}
			getOptionLabel={getOptionLabel}
			onChange={handleChange}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						{getOptionLabel(option)}
					</li>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={labelTxt}
					error={isError || error}
					helperText={isError ? 'Error loading tasks' : helperText}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress color="inherit" size={18} />
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	)
}

export default TaskAutocomplete
