import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import {
	FormControl,
	FormHelperText,
	RadioGroup,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import useFormField from '../../hooks/useFormField'

const RadioControl = ({
	variant = 'standard',
	name,
	label,
	children,
	errors,
	control,
	defaultValue,
	radioGroupProps,
	className,
	sx,
	disabled,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)

	return (
		<FormControl
			variant={variant}
			className={className}
			sx={sx}
			error={!!error}
			disabled={disabled}
		>
			<FormLabel>{label}</FormLabel>
			{control && (
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field }) => (
						<RadioGroup {...radioGroupProps} {...field} row>
							{children}
						</RadioGroup>
					)}
				/>
			)}
			{!control && (
				<RadioGroup
					name={name}
					defaultValue={defaultValue}
					row
					{...rest}
				>
					{children}
				</RadioGroup>
			)}
			{error && <FormHelperText>{error.message}</FormHelperText>}
		</FormControl>
	)
}

export default RadioControl
