import { debounce } from 'lodash'
import { getTheme } from '../config'
import { useEffect, useState } from 'react'

const theme = getTheme()

const useSearchText = ({ onFetch = undefined }) => {
	const [searchText, setSearchText] = useState('')
	const { debounce: debounceWait } = theme.search

	const onChange = (e) => {
		setSearchText(e.target.value)
	}

	const onClear = () => {
		setSearchText('')
	}

	const searchRefetch = debounce(() => {
		if (onFetch) {
			onFetch()
		}
	}, debounceWait)

	useEffect(() => {
		searchRefetch()
	}, [searchText, onFetch])

	return {
		searchText,
		onChange,
		onClear,
	}
}

export default useSearchText
