import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles'
import { Tabs, Tab, Card, CardContent } from '@mui/material'

import Page from '../../../components/Page'
import { LoadingBtn } from '../../../components/Button'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { Loading } from '../../../components/Loading'
import { ProfileView } from './Profile'
import { Apps } from './Apps'
import { RemoteInfo } from './RemoteInfo'

import { siteConfigurationModel } from '../../../services/utils'
import { getDevice, updateDevice } from '../../../services/client'
import { a11yProps, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'
import { useWindowSize, useAlert } from '../../../hooks'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function AdminDeviceEdit({ navigation }) {
	const { id: deviceId } = useParams()
	const { alert } = useAlert()
	const [width] = useWindowSize()
	const themeMUI = useTheme()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const [tab, setTab] = useState(0)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [initialState, setInitialState] = useState({
		...siteConfigurationModel,
		entityType: 1,
	})
	const [formData, setFormData] = useState({
		...siteConfigurationModel,
		entityType: 1,
	})

	const { error, data: deviceResult } = useQuery({
		queryKey: [queryKeys.kiosk, [deviceId]],
		queryFn: () => getDevice(deviceId),
		enabled: !!deviceId,
	})

	useEffect(() => {
		if (error) {
			alert('There was a problem loading the devices', {
				severity: 'error',
			})
		}
	}, [error])

	useEffect(() => {
		if (deviceId && deviceResult) {
			setFormData(deviceResult)
			setInitialState(deviceResult)
		} else navigate(`/${navigationLinking.AdminDevice}`)
	}, [deviceId, deviceResult])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const updateDeviceMutation = useMutation({
		mutationFn: (data) => updateDevice(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				queryKeys.kiosks,
				queryKeys.kiosk,
			])
			alert(`Device "${formData.name}" successfully updated`)
		},
	})

	const saveDevice = async () => {
		try {
			if (formData) await updateDeviceMutation.mutateAsync(formData)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	return (
		<Page isFullWidth={true} title={`Edit Device`}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					Edit Device
				</p>
				{deviceId && !initialState.id ? (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						<Loading size="small" />
					</div>
				) : (
					<>
						<div className="flex flex-wrap justify-end my-5">
							<LoadingBtn
								id={`AdminDeviceEditSaveButton`}
								loading={updateDeviceMutation.isPending}
								text={`Update Device`}
								onClick={saveDevice}
								fullWidth={false}
							/>
						</div>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={tab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="Device Profile" {...a11yProps(0)} />
							<Tab label="Apps" {...a11yProps(1)} />
							<Tab label="Remote" {...a11yProps(2)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={tab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={tab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<ProfileView
											formData={formData}
											setFormData={setFormData}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Apps
											formData={formData}
											setFormData={setFormData}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={2}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<RemoteInfo formData={formData} width={width} />
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				)}
			</div>
		</Page>
	)
}
